<template>
    <div id="input-checkbox__form">
        <div class="row d-flex">
            <div class="col-12 d-flex align-items-center">
                <label class="container-checkbox ml-2 w-100">{{ this.label }}
                    <input :disabled="disabled" type="checkbox" class="mr-2" v-model="model">
                    <span class="checkmark-checkbox"></span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InputCheckbox",
    components: {},
    props: ['label', 'placeholder', 'modelValue', 'errors', 'disabled'],
    data() {
        return {
            model: this.modelValue,
        }
    },
    watch: {
        model(newValue){
            this.$emit('update:modelValue', newValue);
        },
        modelValue(newValue){
            this.model = newValue;
        }
    }
}
</script>

<style scoped>
@import "../../assets/scss/Form/inputCheckbox.scss";
</style>
