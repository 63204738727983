import { Module } from 'vuex';
import http from '@/services/http';
import { Networks } from '@/services/Networks';
interface performanceState {
    dataMainFactorForAnalysisProduct: [];
    dataTopPartnerForAnalysisProduct: [];
    dataTopProductForAnalysisProduct: [];
    last_update: string | null
    // error: string | null;
}

const state: performanceState = {
    dataMainFactorForAnalysisProduct: [],
    dataTopPartnerForAnalysisProduct: [],
    dataTopProductForAnalysisProduct: [],
    last_update: null
    // error: null,
};

const marketingChannels: Module<performanceState, any> = {
    namespaced: true,
    state,
    mutations: {
        setDataMainFactorForAnalysisProduct(state, dataMainFactorForAnalysisProduct: []) {
            state.dataMainFactorForAnalysisProduct = dataMainFactorForAnalysisProduct;
        },
        setDataTopPartnerForAnalysisProduct(state, dataTopPartnerForAnalysisProduct: []) {
            state.dataTopPartnerForAnalysisProduct = dataTopPartnerForAnalysisProduct;
        },
        setDataTopProductForAnalysisProduct(state, dataTopProductForAnalysisProduct: []) {
            state.dataTopProductForAnalysisProduct = dataTopProductForAnalysisProduct;
        },
        // setError(state, error: string | null) {
        //     state.error = error;
        // },
        setLastUpdate(state, date) {
            state.last_update = date
        }
    },
    actions: {
        async getDataForChannelMainAnalysis({ commit }, params) {
            try {                
                const response:any = await http.get(Networks.performance.get_data_for_channel_main_analysis, {params: params});
                if(response?.result){
                    return response.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },
        async getDataBarChartForChannel({ commit }, params) {
            try {
                const response:any = await http.get(Networks.performance.get_data_bar_chart_for_channel, {params: params});
                if(response?.result){
                    return response.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },

        async getDataRoundChartForChannel({ commit }, params) {
            try {
                const response:any = await http.get(Networks.performance.get_data_round_chart_for_Channel, {params: params});
                if(response?.result){
                    return response.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },

        async getTopProductsForChannel({ commit }, params) {
            try {
                const response:any = await http.get(Networks.performance.get_top_product_for_Channel, {params: params});
                if(response?.result){
                    return response.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },

        async getTopPartners({ commit }, params) {
            try {
                const response:any = await http.get(Networks.performance.get_top_partner, {params: params});
                if(response?.result){
                    return response.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },

        async getPerformanceForOpenPrdId({ commit }, params) {
            try {
                const response:any = await http.get(Networks.performance.get_performance_for_open_prd_id, {params: params});
                if(response?.result){
                    return response.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },

        async getDataRoundProductsOpenCampaign({ commit }, params) {
            try {
                const response:any = await http.get(Networks.performance.get_performance_round_for_open_prd_code, {params: params});
                if(response?.result){
                    return response.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        }, 

        async getTopPartnersForOpenProductCode({ commit }, params) {
            try {
                const response:any = await http.get(Networks.performance.get_performance_top_partner_open_prd_code, {params: params});
                if(response?.result){
                    return response.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },

        async getTopContributorForOpenProductCode({ commit }, params) {
            try {
                const response:any = await http.get(Networks.performance.get_performance_top_contributor_open_prd_code, {params: params});
                if(response?.result){
                    return response.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },
        async getRevenueDashboard({ commit }, params) {
            try {
                const response:any = await http.get(Networks.dashboard.get_revenue_dashboard, {params: params});
                if(response?.result){
                    return response.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },
        async getDataForAnalysisProduct({ commit }, params) {
            try {
                const response:any = await http.get(Networks.performance.get_data_for_analysis_product, {params: params});
                if(response?.result){
                    return response.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },

        async getDataMainFactorForAnalysisProduct({ commit }, params) {
            try {
                const response:any = await http.get(Networks.performance.get_data_main_factor_for_analysis_product, {params: params});
                if(response?.result){
                    commit('setDataMainFactorForAnalysisProduct', response?.result);
                    return response.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },

        async getDataMarketingChannelForAnalysisProduct({ commit }, params) {
            try {
                const response:any = await http.get(Networks.performance.get_data_marketing_channel_for_analysis_product, {params: params});
                if(response?.result){
                    return response.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },

        async getDataTopPartnerForAnalysisProduct({ commit }, params) {
            try {
                const response:any = await http.get(Networks.performance.get_data_top_partner_for_analysis_product, {params: params});
                if(response?.result?.data){
                    commit('setDataTopPartnerForAnalysisProduct', response?.result?.data);
                    return response.result.data;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },

        async getDataTopProductForAnalysisProduct({ commit }, params) {
            try {
                const response:any = await http.get(Networks.performance.get_data_top_product_for_analysis_product, {params: params});
                if(response?.result?.data){
                    commit('setDataTopProductForAnalysisProduct', response?.result?.data);
                    return response.result.data;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },
        async exportTopPartnerForAnalysisProduct({ commit }, params) {
            try {
                const response:any = await http.get(Networks.performance.export_top_partner_for_analysis_product, {params: params});
                if(response.result){
                    return response.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },
        async campaignAnalysis ({ commit }, params)
        {
            try {
                const response:any = await http.get(Networks.performance.get_data_campaign, {params: params});
                if(response?.result){
                    return response.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },
        async campaignsGetOpenAndTarget ({ commit }, params)
        {
            try {
                const response:any = await http.get(Networks.performance.campaign_get_open_and_target, {params: params});
                if(response?.result){
                    return response.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },
        async statistical ({commit}, params)
        {
            try {
                const response:any = await http.get(Networks.dashboard.statistical, {params: params});
                if(response?.result){
                    return response.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },
        async getDataBarChartContributor({ commit }, params) {
            try {
                const response:any = await http.get(Networks.dashboard.get_data_bar_chart_contributor, {params: params});
                if(response?.result){
                    return response.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },
        async getTopProductsForContributor({ commit }, params) {
            try {
                const response:any = await http.get(Networks.dashboard.get_top_product_contributor, {params: params});
                if(response?.result){
                    return response.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },
        async getTopContributorDashboard({ commit }, params) {
            try {
                const response:any = await http.get(Networks.dashboard.get_top_contributor_dashboard, {params: params});
                if(response?.result){
                    return response.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },
        async getPartnerForAnalysisChannel({ commit }, params) {
            try {
                const response:any = await http.get(Networks.performance.get_partner_analysis_channel, {params: params});
                if(response.result){
                    return response.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },  
        async exportTopProductForAnalysisProduct({ commit }, params) {
            try {
                const response:any = await http.get(Networks.performance.export_top_product_for_analysis_product, {params: params});
                if(response.result){
                    return response.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },
        loadDataLastUpdate({ commit }, date) {
            commit('setLastUpdate', date);
        },
        async exportAnalysisCampaign({ commit }, params) {
            try {
                const response:any = await http.get(Networks.performance.export_analysis_campagin, {params: params});
                if(response.result){
                    return response.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },
        async exportTopProductMainAnalysis({ commit }, params) {
            try {
                const response:any = await http.get(Networks.performance.export_top_product_main_analysis, {params: params});
                if(response.result){
                    return response.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },

        async exportTopPartnerMainAnalysis({ commit }, params) {
            try {
                const response:any = await http.get(Networks.performance.export_top_partner_main_analysis, {params: params});
                if(response.result){
                    return response.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },
    },
    getters: {
        getDataMainFactorForAnalysisProduct(state): [] {
            return state.dataMainFactorForAnalysisProduct;
        },
        getDataTopPartnerForAnalysisProduct(state): [] {
            return state.dataTopPartnerForAnalysisProduct;
        },
        getDataTopProductForAnalysisProduct(state): [] {
            return state.dataTopProductForAnalysisProduct;
        },
        // hasError(state): string | null {
        //     return state.error;
        // },
        getLastUpdate(state){
            return state.last_update
        }
    },
};

export default marketingChannels;
