<template>
    <div id="create-collaborator-page">
        <div class='container-fluid p-3'>
            <div class='row my-3'>
                <div class="row mb-3">
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class="form-group row">
                            <label class="col-sm-2 control-label col-form-label">
                                {{ $t('collaboratorName') }}:<span class="text-danger">*</span>
                            </label>
                            <div class="col-sm-10">
                                <InputText :placeholder="$t('enterCollaboratorName')" v-model="entry.name" :errors="errors?.name ?? []"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class="form-group row">
                            <label class="col-sm-2 control-label col-form-label">
                                {{ $t('birthday') }}:
                            </label>
                            <div class="col-sm-10">
                                <InputDate :placeholder="$t('enterBirthdayContributor')" v-model="entry.birthday" :max="today" :errors="errors?.birthday ?? []"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class="form-group row">
                            <label class="col-sm-2 control-label col-form-label">
                                {{ $t('email') }}:<span class="text-danger">*</span>
                            </label>
                            <div class="col-sm-10">
                                <InputText :placeholder="entry?.email ? entry?.email : $t('enterCollaboratorEmail')" :errors="errors?.email ?? []" :disabled="true" v-if="entry.id"/>
                                <InputText :placeholder="$t('enterCollaboratorEmail')" v-model="entry.email" :errors="errors?.email ?? []" v-else/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class="form-group row">
                            <label class="col-sm-2 control-label col-form-label">
                                {{ $t('phone') }}:
                            </label>
                            <div class="col-sm-10">
                                <InputText :placeholder="$t('enterCollaboratorPhone')" v-model="entry.phone" :errors="errors?.phone ?? []"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class="form-group row">
                            <label class="col-sm-2 control-label col-form-label">
                                {{ $t('taxCode') }}:
                            </label>
                            <div class="col-sm-10">
                                <InputText :placeholder="$t('enterCollaboratorTaxCode')" v-model="entry.tax_code" :errors="errors?.tax_code ?? []"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class="form-group row">
                            <label class="col-sm-2 control-label col-form-label">
                                {{ $t('address') }}:
                            </label>
                            <div class="col-sm-10">
                                <InputText :placeholder="$t('enterCollaboratorAddress')" v-model="entry.address" :errors="errors?.address ?? []"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class="form-group row">
                            <label class="col-sm-2 control-label col-form-label">
                                {{ $t('identification') }}:
                            </label>
                            <div class="col-sm-10">
                                <InputText :placeholder="$t('enterIdentificationContributor')" v-model="entry.identification_code" :errors="errors?.identification_code ?? []"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class="form-group row">
                            <label class="col-sm-2 control-label col-form-label">
                                {{ $t('identificationFrontImage') }}:
                            </label>
                            <div class="col-sm-10">
                                <InputFile :image="true" :modelValue="entry?.identification_card_front" @update:modalValue="changeIdentificationCardFront"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class="form-group row">
                            <label class="col-sm-2 control-label col-form-label">
                                {{ $t('identificationBackImage') }}:
                            </label>
                            <div class="col-sm-10">
                                <InputFile :image="true" :modelValue="entry?.identification_card_back" @update:modalValue="changeIdentificationCardBack"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-xl-12 col-md-12 col-sm-12">
                        <label class="title">
                            {{ $t('paymentInfor') }}
                        </label>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class="form-group row">
                            <label class="col-sm-2 control-label col-form-label">
                                {{ $t('bank') }}:
                            </label>
                            <div class="col-sm-10">
                                <InputText :placeholder="$t('enterBankInfor')" v-model="entry.bank_name" :errors="errors?.bank_name ?? []"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class="form-group row">
                            <label class="col-sm-2 control-label col-form-label">
                                {{ $t('branch') }}:
                            </label>
                            <div class="col-sm-10">
                                <InputText :placeholder="$t('enterBranchName')" v-model="entry.bank_branch" :errors="errors?.bank_branch ?? []"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class="form-group row">
                            <label class="col-sm-2 control-label col-form-label">
                                {{ $t('accountNumber') }}:
                            </label>
                            <div class="col-sm-10">
                                <InputText :placeholder="$t('enterAccountNumber')" v-model="entry.bank_account_number" :errors="errors?.bank_account_number ?? []"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-12 col-sm-12">
                        <div class="form-group row">
                            <label class="col-sm-2 control-label col-form-label">
                                {{ $t('accountOwner') }}:
                            </label>
                            <div class="col-sm-10">
                                <InputText :placeholder="$t('enterAccountOwner')" v-model="entry.bank_owner_name" :errors="errors?.bank_owner_name ?? []"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-xl-12 col-md-12 col-sm-12 d-flex justify-content-center">
                        <button class="btn btn-secondary mx-3" @click="cancel()">
                            <span>
                                {{ $t('cancel') }}
                            </span>
                        </button>
                        <button class="btn btn-primary mx-3" @click="save()">
                            <i class="fa fa-save mr-2"></i>
                            <span class="mx-2">
                                {{ $t('save') }}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalConfirm 
        :title="$t('confirm')"
        :content="$t('confirmCancelChangeData')"
        ref="ModalConfirm"
        @confirm="goBack"
    />
</template>

<script>
import { numberFormat, clone, isChangeData } from '@/utils/utils';
import InputText from '@/components/Form/InputText.vue';
import { mapGetters } from 'vuex';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
import toastr from 'toastr';
import InputFile from '@/components/Form/InputFile.vue';
import moment from 'moment';
import InputDate from '@/components/Form/InputDate.vue'

export default {
    name: 'CreateCollaborator',
    setup() {
        return { numberFormat, clone, isChangeData };
    },
    components: {
        InputText, ModalConfirm, InputFile, InputDate
    },
    computed: {
        ...mapGetters({
            detail: 'collaborators/getDetail'
        })
    },
    data() {
        return {
            errors: {},
            normalEntry: {},
            entry: {},
            today: moment().subtract(1, 'days').format('YYYY-MM-DD')
        }
    },
    mounted(){
        const data = this.detail && Object.keys(this.detail).length != 0 ? this.detail : {
            name: '',
            email: '',
            phone: '',
            tax_code: '',
            address: '',
            bank_name: '',
            bank_branch: '',
            bank_account_number: '',
            bank_owner_name: '',
            identification_card_front: '',
            identification_card_back: '',
            identification_code: '',
            birthday: ''
        };
        if(data.birthday){
            data.birthday = moment(data.birthday, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
        }
        this.entry = clone(data);
        this.normalEntry = clone(data);
    },
    methods: {
        goBack(){
            this.$store.dispatch('collaborators/setDetail', {});
            this.$router.push({name: this.$constant.ROUTER.collaboratorsName});
        },
        cancel(){
            if(isChangeData(this.entry, this.normalEntry)){
                this.$refs.ModalConfirm.show();
                return;
            }
            this.goBack();
        },
        async save(){
            let data = JSON.parse(JSON.stringify(this.entry));
            data.birthday = data.birthday ? moment(data.birthday, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss') : null;
            let res;
            if(data?.id){
                res = await this.$store.dispatch('collaborators/updateCollaborator', data);
            }else{
                res = await this.$store.dispatch('collaborators/createCollaborator', data);
            }
            if(res?.error){
                if(res?.messages){
                    this.errors = res?.messages ?? {};
                }else{
                    toastr.error(res.data);
                }
                return;
            }else{
                toastr.success(data?.id ? this.$t('updateCollaboratorSuccess') : this.$t('createCollaboratorSuccess'));
                this.goBack();
            }
        },
        changeIdentificationCardFront(value){
            this.entry.identification_card_front = value;
        },
        changeIdentificationCardBack(value){
            this.entry.identification_card_back = value;
        }
    },
};
</script>

<style lang="scss" scoped>
// @import "../../assets/scss/createPartner.scss";
</style>