<template>
    <DoughnutChart :chart-data="chartData" :plugins="plugins" :options="options"/>
</template>

<script>
import { DoughnutChart } from 'vue-chart-3';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { numberFormat } from '@/utils/utils'
export default {
    components: { DoughnutChart },
    props: ['title', 'labelValues', 'datasetValues'],
    data() {
        let chartData = {
            labels: this.labelValues ?? [],
            datasets: this.datasetValues ?? [],
        };

        let plugins = [ChartDataLabels]

        let options = {
            plugins: {
                datalabels : {
                    color: '#ffffff',
                    font: {
                        size: 16,
                        weight: 'bold'
                    },
                    formatter: (value, ctx) => {
                        if(!value){
                            return null;
                        }
                        const total = ctx?.dataset?.data.reduce((accumulator, currentValue) => (+accumulator) + (+currentValue), 0);
                        return (total ? numberFormat((+value / +total) * 100, 2) : 0) + '%';
                    },
                },
            }
        }

        return { chartData, plugins, options };
    },
    watch: {
        labelValues: {
            handler: function(newValue) {                  
                this.chartData.labels = newValue;                            
            },
            deep: true
        },
        datasetValues: {
            handler: function(newValue) {  
                this.chartData.datasets = newValue;   
            },
            deep: true
        },
    },
};
</script>