<template>
    <div id="affiliate-page">
        
    </div>
</template>

<script>
import { useRoute } from 'vue-router';
export default {
    name: 'Affiliate',
    components: {},
    data() {
        return {
        }
    },
    created() {
        const route = useRoute();
        if(route.query){
            this.updateQuantityClicked(route.query);
        }
    },
    methods: {
        async updateQuantityClicked(query){
            if(!query){
                return;
            }
            const params = {
                'slug': query?.slug,
                'param': {
                    'contributor': query?.contributor,
                    'channel': query?.channel,
                    'campaign': query?.campaign,
                    'referrer': document ? document.referrer: null,
                }
            };            
            const res = await this.$store.dispatch('collaborators/updateQuantityClicked', params);
            if(res?.code === 200 && res?.data?.redirectUrl){
                window.location.href = res?.data?.redirectUrl;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
</style>