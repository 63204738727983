<template>
    <div id="create-target-campaign-page">
        <div class='container-fluid p-3'>
            <div class='row p-3'>
                <div class='col-lg-12'>
                    <div class="card">
                        <div class="d-flex justify-content-between">
                            <h4 class='card-title mb-2'>{{ $t('selectCollaborator') }}</h4>
                            <button class="btn btn-primary" role='button' data-bs-toggle='modal' data-bs-target='#ModalSelectCollaborator'>
                                <i class="fa fa-plus mx-2"></i>
                                <span>
                                    {{ $t('createCollaborator') }}
                                </span>
                            </button>
                        </div>
                        <h6 class='card-title mb-2'>1 {{ $t('collaborator') }}</h6>
                        <div class="table-responsive" v-if="partners?.length">
                            <table class="table table-index table-bordered" >
                                <thead class="thead-light border">
                                    <tr>
                                        <th>
                                            <input type="checkbox"/>
                                        </th>
                                        <th scope="col">{{ $t('collaboratorName') }}</th>
                                        <th scope="col">{{ $t('numberOfClicked') }}</th>
                                        <th scope="col">{{ $t('numberOfOrders') }}</th>
                                        <th scope="col">{{ $t('action') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="customtable">
                                    <template v-for="(partner, index) in partners" :key="index">
                                        <tr>
                                            <th>
                                                <input type="checkbox"/>
                                            </th>
                                            <td>
                                                <p>{{ partner.name }}</p>
                                            </td>
                                            <td>{{ partner.numberClicked }}</td>
                                            <td>{{ partner.totalOrders }}</td>
                                            <td>
                                                <span>
                                                    <i class="fa fa-trash-alt"></i>
                                                </span>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive d-flex justify-content-center align-items-center border" v-else>
                            <button class="btn btn-outline-secondary" role='button' data-bs-toggle='modal' data-bs-target='#ModalChoosePartner'>
                                <i class="fa fa-plus mx-2"></i>
                                <span>{{ $t('createCollaborator') }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row p-3'>
                <div class='col-lg-12'>
                    <div class="card">
                        <div class="d-flex justify-content-between">
                            <h4 class='card-title mb-2'>{{ $t('setupCommission') }}</h4>
                            <div>
                                <!-- <button class="btn btn-primary mx-2" role='button' data-bs-toggle='modal' data-bs-target='#ModalChooseProduct'>
                                    <i class="fa fa-plus mx-2"></i>
                                    <span>
                                        {{ $t('createProduct') }}
                                    </span>
                                </button> -->
                                <button class="btn btn-outline-secondary" role='button' data-bs-toggle='modal' data-bs-target='#ModalCreateQuicklyCommission'>
                                    <i class="fa fa-plus mx-2"></i>
                                    <span>
                                        {{ $t('createQuicklyCommission') }}
                                    </span>
                                </button>
                            </div>
                        </div>
                        <h6 class='card-title mb-2'>2 {{ $t('product') }}</h6>
                        <div class="table-responsive table-products" v-if="products?.length">
                            <table class="table table-index table-bordered">
                                <thead class="thead-light border">
                                    <tr>
                                        <th>
                                            <input type="checkbox"/>
                                        </th>
                                        <th scope="col">{{ $t('product') }}</th>
                                        <th scope="col">{{ $t('price') }}</th>
                                        <th scope="col">{{ $t('Tỉ lệ hoa hồng NPP') }}</th>
                                        <th scope="col">{{ $t('Tỉ lệ hoa hồng CTV') }}</th>
                                        <th scope="col">{{ $t('action') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="customtable">
                                    <template v-for="(product, index) in products" :key="index">
                                        <tr>
                                            <th>
                                                <input type="checkbox"/>
                                            </th>
                                            <td>
                                                <div class="info-products d-flex">
                                                    <div class="image">
                                                        <img src="https://phanphoi.omicare.vn/files/product/2023-06/mat-na-nang-co-giup-lam-san-chac-da-ex-clear-turn-omicare-1.jpg"/>
                                                    </div>
                                                    <div class="product mx-1">
                                                        <p>{{ product.name }}</p>
                                                        <p>
                                                            {{ $t('skuIdCode') }}: {{ product.sku }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{{ product.price }}</td>
                                            <td></td>
                                            <td>
                                                <InputTextAppend v-model="product.commission"/>
                                            </td>
                                            <td>
                                                <span>
                                                    <i class="fa fa-trash-alt"></i>
                                                </span>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive d-flex justify-content-center align-items-center border" v-else>
                            <button class="btn btn-outline-secondary" role='button' data-bs-toggle='modal' data-bs-target='#ModalChooseProduct'>
                                <i class="fa fa-plus mx-2"></i>
                                <span>{{ $t('createProduct') }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row p-3'>
                <div class='col-lg-12'>
                    <div class="card">
                        <h4 class='card-title mb-2'>{{ $t('sendInvitations') }}</h4>
                        <div class="form-send-infor mx-0 p-3">
                            <div class="form-group row">
                                <label class="col-sm-2 control-label col-form-label">
                                    {{ $t('campaignName') }}:<span class="text-danger">*</span>
                                </label>
                                <div class="col-sm-10">
                                    <InputText :placeholder="$t('enterCampaignName')" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 control-label col-form-label">
                                    {{ $t('campaignTime') }}:<span class="text-danger">*</span>
                                </label>
                                <div class="col-sm-10">
                                    <div class='row'>
                                        <div class='col-lg-6 col-md-12 col-sm-12'>
                                            <div class="d-flex">
                                                <Daterangepicker 
                                                    :placeholder="$t('startTime')" 
                                                    :class="!setEndTime ? 'w-100' : ''"/>
                                                <Daterangepicker 
                                                    :placeholder="$t('endTime')" 
                                                    v-if="setEndTime"
                                                    class="mx-2"/>
                                            </div>
                                        </div>
                                        <div class='col-lg-6 col-md-12 col-sm-12 d-flex align-items-end'>
                                            <InputCheckbox :label="$t('setEndTime')" @update:modelValue="setEndTime" v-model="setEndTime"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 control-label col-form-label">
                                    {{ $t('message') }}:
                                </label>
                                <div class="col-sm-10">
                                    <textarea class="form-control" :placeholder="$t('descriptionOfCampaign')" style="height: 100px;"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row p-3'>
                <div class='col-lg-12 d-flex justify-content-end'>
                    <button class="btn btn-outline-secondary mx-2">
                        <span>
                            {{$t('Cancel')}}
                        </span>
                    </button>
                    <button class="btn btn-outline-warning mx-2">
                        <span>
                            {{$t('saveDraft')}}
                        </span>
                    </button>
                    <button class="btn btn-warning">
                        <span>
                            {{$t('save')}}
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <Loading :loading="isLoading" />
    </div>
    <ModalChooseProduct/>
    <ModalCreateQuicklyCommission/>
    <ModalSelectCollaborator/>
</template>

<script>
import { numberFormat } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import InputText from '@/components/Form/InputText.vue';
import InputSelect from '@/components/Form/InputSelect.vue';
import Daterangepicker from '@/components/Daterangepicker.vue';
import InputCheckbox from '@/components/Form/InputCheckbox.vue';
import InputTextAppend from '@/components/Form/InputTextAppend.vue';
import ModalChooseProduct from '@/components/Modal/TargetCampaign/ModalChooseProduct.vue';
import ModalCreateQuicklyCommission from '@/components/Modal/TargetCampaign/ModalCreateQuicklyCommission.vue';
import ModalSelectCollaborator from '@/components/Modal/TargetCampaign/ModalSelectCollaborator.vue';

export default {
    name: 'CreateTargetCampaign',
    setup() {
        return { numberFormat };
    },
    components: {
        Loading,
        InputText,
        InputSelect,
        Daterangepicker,
        InputCheckbox,
        InputTextAppend,
        ModalChooseProduct,
        ModalCreateQuicklyCommission,
        ModalSelectCollaborator
    },
    data() {
        return {
            isLoading: false,
            record: 100,
            paginate: {
                totalRecord: 10000,
                currentPage: 1,
                lastPage: 100
            },
            filters: {
                tab: 1,
            },
            pID: null,
            partners: [
                {id: 1, name: 'Công ty Nhật Minh', numberCollaborator: 5 , numberClicked: 19, totalOrders: 200 },
                {id: 2, name: 'Công ty Nhật Minh', numberCollaborator: 5 , numberClicked: 19, totalOrders: 200 },
                {id: 3, name: 'Công ty Nhật Minh', numberCollaborator: 5 , numberClicked: 19, totalOrders: 200 },
            ],
            products: [
                {id: 1, name: 'Dầu tẩy trang làm sạch nhanh', sku: 1545785785, price: 50000 , commission: 5, totalOrders: 200 },
                {id: 2, name: 'Xịt lạnh quần áo làm mát cơ thể', sku: 36547857527, price: 50000 , commission: 5, totalOrders: 200 },
            ],
            entry: {},
            setEndTime: false
        }
    },
    methods: {
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/createTargetCampaign.scss";
</style>