<template>
    <div>
        <span v-text="this.title"></span>
        <LineChart :chart-data="chartData" :options="options" />
    </div>
</template>

<script>
import { LineChart } from 'vue-chart-3';
export default {
    components: {
        LineChart
    },
    props: ['title', 'labelValues', 'datasetValues'],
    data() {
        let chartData = {
            labels: this.labelValues ?? [],
            datasets: this.datasetValues ?? []
        };

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                y: {
                    beginAtZero: true
                }
            }
        };

        return {
            chartData,
            options
        };
    },
    watch: {
        labelValues: {
            handler: function(newValue) {  
                this.chartData.labels = newValue;                            
            },
            deep: true
        },
        datasetValues: {
            handler: function(newValue) {  
                this.chartData.datasets = newValue;            
            },
            deep: true
        },
    },
};
</script>

<style scoped>
</style>