<template>
    <div id="switch-button">
        <div class="form-group">
            <label v-if="label" v-text="label"></label>
            <div class="d-flex">
                <label class="switch-button">
                    <input ref="input" v-model="isChecked" @change="toggle()" type="checkbox" :disabled="readonly">
                    <span class="slider round"></span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SwitchButton",
    data: function () {
        const v = typeof this.modelValue === 'boolean' ? this.modelValue : parseInt(this.modelValue);
        return {
            isChecked: !!v
        }
    },
    mounted: function () {
    },
    props: ['modelValue', 'change', 'label', 'readonly'],
    watch: {
        modelValue: function (v) {
            this.isChecked = !!v;

        }
    },
    methods: {
        toggle: function () {
            const modelValue = this.$refs.input.checked ? 1 : 0;
            this.$emit('update:modelValue', modelValue);
            this.$nextTick(() => {
                this.$emit('change', modelValue);
                this.$emit('input', modelValue);
            })

        }
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/Button/switchButton.scss";
</style>
