import { Module } from 'vuex';
import http from '@/services/http';
import { Networks } from '@/services/Networks';

interface OrderState {
    listOrder: [];
    orderItems: [];
}

const state: OrderState = {
    listOrder: [],
    orderItems: [],
};

const orderStore: Module<OrderState, any> = {
    namespaced: true,
    state,
    mutations: {
        setListOrder(state, listOrder: []) {
            state.listOrder = listOrder;
        },
        setOrderItems(state, orderItems: []) {
            state.orderItems = orderItems;
        },
    },
    actions: {
        async fetchOrderLink({ commit }, params)  {
            try {
                const response:any = await http.post(Networks.order.get_list_order_link, params);
                if(response?.result?.data?.data){
                    commit('setListOrder', response?.result?.data?.data);
                }
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },
        async exportOrder({ commit }, params)  {
            try {
                const response:any = await http.post(Networks.order.export, params);   
                if(response.result){
                    return response.result;
                }else{
                    return null;
                }
            } catch (error: any) {
                return {};
            }
        },
        async fetchOrderLinkItems({ commit }, params)  {
            try {
                const response:any = await http.post(Networks.order.get_order_link_items, params);
                if(response?.result){
                    commit('setOrderItems', response?.result);
                }
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },
    },
    getters: {
        listOrder(state): [] {
            return state.listOrder;
        },
        orderItems(state): [] {
            return state.orderItems;
        },
    },
};

export default orderStore;