<template>
    <div id="marketing-channel-page">
        <div class='container-fluid p-3'>
            <div class='row mb-3'>
                <div class='row'>
                    <div class="col-xl-2 col-md-3 col-sm-12">
                        <InputText :placeholder="$t('sourceName')" v-model="filters.keyword"/>
                    </div>
                    <div class="col-xl-2 col-md-3 col-sm-12">
                        <InputSelect :placeholder="$t('status')" v-model="filters.status" :options="this.$constant.STATUS_LIST_MARKETING_CHANNELS"/>
                    </div>
                    <div class="col-xl-2 col-md-3 col-sm-6">
                        <button type='submit' class='btn btn-success' @click="search">
                            <span>
                                {{ $t('search') }}
                            </span>
                        </button>
                        <button type='submit' class='btn btn-secondary mx-2' @click="clearFilter">
                            <span>
                                {{ $t('clearFilter') }}
                            </span>
                        </button>
                    </div>

                    <div class="col-xl-6 col-md-3 col-sm-6 d-flex justify-content-end">
                        <button type='submit' class='btn btn-primary' @click="showModalCreateAffiliateSources()">
                            <i class="fa fa-plus mx-2"></i>
                            <span>
                                {{ $t('addAffiliate') }}
                            </span>
                        </button>
                    </div>

                </div>
            </div>
            <div class='row'>
                <div class='col-lg-12'>
                    <div class="card">
                        <div class="table-responsive">
                            <table class="table">
                                <thead class="thead-light border">
                                    <tr>
                                        <th scope="col">{{ $t('sourceName') }}</th>
                                        <th scope="col">{{ $t('URL') }}</th>
                                        <th scope="col">
                                            <TableSortField :current="sortData.field" @onsort="sort" :name="$t('clicks')" :field="'quantity_click'"/>
                                        </th>
                                        <th scope="col">
                                            <TableSortField :current="sortData.field" @onsort="sort" :name="$t('order')" :field="'quantity_order'"/>
                                        </th>
                                        <th scope="col">
                                            <TableSortField :current="sortData.field" @onsort="sort" :name="$t('conversionRate')" :field="'conversion_rate'"/>
                                        </th>
                                        <th scope="col">
                                            <TableSortField :current="sortData.field" @onsort="sort" :name="$t('revenue')" :field="'revenue'"/>
                                        </th>
                                        <th scope="col">{{ $t('status') }}</th>
                                        <th scope="col">{{ $t('operation') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="customtable" v-if="this.listMarketingChannels?.length > 0">
                                    <template v-for="(entry, index) in this.listMarketingChannels" :key="index">
                                        <tr>
                                            <td class="column-name" v-text="entry.name"></td>
                                            <td v-text="entry.url"></td>
                                            <td>{{ numberFormat(entry.quantity_click) }}</td>
                                            <td>{{ numberFormat(entry?.quantity_order) }}</td>
                                            <td>{{ numberFormat(entry.conversion_rate) }}%</td>
                                            <td>{{ numberFormat(entry?.revenue) }}</td>
                                            <td>{{ $filters.formatMarketingChannelStatus(entry.status) }}</td>
                                            <td>
                                                <span class="" role='button' @click="showModalCreateAffiliateSources(entry)">
                                                    <span class="icon_tooltip" :titleCustom="$t('edit')"><i class="fas fa-edit text-active mx-2"></i></span>
                                                </span>
    
                                                <span class="" role='button' @click="showModalConfirmRemove(entry.id)">
                                                    <span class="icon_tooltip" :titleCustom="$t('delete')"><i class="fas fa-trash text-active mx-2"></i></span>
                                                </span>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                            <div id="noData" v-if="this.listMarketingChannels?.length == 0">
                                <span>{{ $t('noData') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Paginate :value="paginate" :record="filters.record" @pageChange="pageChange" v-if="this.listMarketingChannels?.length > 0"/>
        </div>
        <ModalCreateAffiliateSources @reload="loadData" ref="ModalCreateAffiliateSource"/>
        <ModalConfirm ref="ModalConfirm"
            :title="$t('removeSourceAffiliate')" 
            :content="$t('contentRemoveSourceAffiliate')"
            :button="$t('confirm')"
            @confirm="remove"
        />
    </div>
</template>

<script>
import Daterangepicker from '@/components/Daterangepicker.vue'
import { numberFormat } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import InputText from '@/components/Form/InputText.vue';
import Paginate from '@/components/Paginate.vue';
import InputSelect from '@/components/Form/InputSelect.vue';
import ModalCreateAffiliateSources from '@/components/Modal/AffiliateMarketingSources/ModalCreateAffiliateSources.vue';
import { mapGetters } from 'vuex';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
import toastr from 'toastr';
import TableSortField from '@/components/TableSortField.vue';
export default {
    name: 'AffiliateMarketingSources',
    setup() {
        return { numberFormat };
    },
    components: {
        Daterangepicker,
        Loading,
        InputText,
        Paginate,
        InputSelect,
        ModalCreateAffiliateSources,
        ModalConfirm,
        TableSortField
    },
    data() {
        return {
            paginate: {
                totalRecord: 1,
                currentPage: 1,
                lastPage: 1
            },
            filters: {
                keyword: null,
                time: null,
                status: null,
                record: 50,
                page: 1,
            },
            action: 1,
            removeID: null,
            sortData: {
                field: 'id',
                direction: 'desc',
            }
        }
    },
    computed: {
        ...mapGetters('marketingChannels', ['listMarketingChannels']),
    },
    mounted(){
        this.loadData();
    },
    methods: {
        async loadData(){
            const res = await this.$store.dispatch('marketingChannels/fetchMarketingChannels', {...this.filters, ...this.sortData});
            this.paginate.totalRecord = res?.result?.data?.total;
            this.paginate.currentPage = res?.result?.data?.current_page;
            this.paginate.lastPage = res?.result?.data?.last_page;
        },
        pageChange(page) {
            this.filters.page = page;
            this.loadData();
        },
        showModalCreateAffiliateSources(entry = null){
            this.$refs.ModalCreateAffiliateSource.showModal(entry)
        },
        search(){
            this.filters.page = 1;
            this.loadData();
        },
        clearFilter(){
            this.filters = {
                keyword: null,
                time: null,
                status: null,
                record: 50,
                page: 1,
            }
            this.sortData = {
                field: 'id',
                direction: 'desc',
            }
            this.loadData();
        },
        showModalConfirmRemove(id){
            if(!id){
                return;
            }
            this.removeID = id;
            this.$refs.ModalConfirm.show();
        },
        async remove(){
            if(!this.removeID){
                return;
            }
            const res = await this.$store.dispatch('marketingChannels/removeMarketingChannels', {id: this.removeID});
            if(res?.result?.code === 200){
                toastr.success(this.$t('actionSuccess'));
                this.loadData();
            }else{
                if(res?.result?.code === 500 && res?.result?.message){
                    toastr.error(res?.result?.message);
                    return;
                }
                toastr.error(this.$t('actionError'));
            }
        },
        sort(field, direction){
            this.sortData.field = field;
            this.sortData.direction = direction;
            this.loadData();
        }
    },
};
</script>

<style lang="scss" scoped>
#marketing-channel-page{
    .table {
        .customtable {
            .column-name{
                max-width: 100px;
            }
        }
    }
}
// @import "../../assets/scss/partner.scss";
</style>