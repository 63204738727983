<template>
    <div id="input-text-area__form">
        <textarea :readonly="!!readonly" :disabled="disabled" @input="onInput(true)"
            v-model.trim="model" 
            :class="[(this.errors?.message && this.errors?.message.length ? 'error-input' : '')]"
            :placeholder="placeholder || label" 
            class="form-control" 
            autocomplete="off"
            style="height: 100px;">
        </textarea>
        <ul class="error-label" v-if="this.errors?.message && this.errors?.message.length">
            <li v-for="error in this.errors?.message" :key="error" v-text="error"></li>
        </ul>
        <ul class="error-label" v-if="errors && errors.length">
            <li v-for="error in errors" :key="error" v-text="error"></li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "InputTextArea",
    props: ['label', 'placeholder', 'modelValue', 'errors', 'readonly', 'max', 'id', 'type', 'disabled', 'append'],
    data() {
        return {
            model: this.modelValue,
        }
    },
    watch: {
        modelValue: function (newValue) {
            this.model = newValue;
        }
    },
    methods: {
        onInput() {
            this.$emit('update:modelValue', this.model);
            this.$nextTick(() => {
                this.$emit('input', this.model);
            })

        },
    }
}
</script>

<style scoped>
@import "../../assets//scss/Form/inputTextArea.scss";
</style>
