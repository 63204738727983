import axios from 'axios';
import store from '@/store';
import CONSTANT from '@/common/constant';
import i18n from '@/locales/i18nHelper'

const BASE_URL = process.env.VUE_APP_V1_API_URL;
const language = localStorage.getItem('language') ?? 'vi';
const token = localStorage.getItem('tokenAffiliate') ?? '';
const deviceId = localStorage.getItem('deviceIdAffiliate') ?? '';
const http = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
});

http.interceptors.request.use(
    (config) => {
        store.dispatch('setLoading', true)
        if(token){
            config.headers.Authorization = 'Bearer ' + token;
        }
        if(language){
            config.headers.Language = language;
        }
        if(deviceId){
            config.headers.Deviceid = deviceId;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

http.interceptors.response.use(
    (response) => {
        store.dispatch('setLoading', false)
        if(response?.data){
            return response?.data;
        }
        return response;
    },
    (error) => {
        store.dispatch('setLoading', false)
        if (error && error.status === 422) {
            return error.response;
        }
        if (error && error.status === 401) {
            store.dispatch('auth/setToken', '');
            store.dispatch('auth/setAuth', []);
            store.dispatch('auth/setUserRole', '');
            if(confirm(i18n.global.t('expireLogin'))){
                location.replace(CONSTANT.ROUTER.login);
            }
        }
        return error.response;
        // return Promise.reject(error);
    }
);

export default http;
