import { Module } from 'vuex';
import http from '@/services/http';
import { Networks } from '@/services/Networks';

// interface MarketingChannel {
//     id: number;
//     name: string;
//     url: string;
//     quantity_click: number;
//     quantity_order: number;
//     conversion_rate: number;
//     revenue: number;
//     status: number;
// }

interface CampaignState {
    product_codes: [];
    commission_rate_partner: number | null;
    commission_rate_contributor: number | null;
    status_load_index: boolean;
    get_detail: object;
    commission_rate: number | null;
}

const state: CampaignState = {
    product_codes: [],
    commission_rate_partner: 0,
    commission_rate_contributor: 0,
    status_load_index: false,
    get_detail: {},
    commission_rate: 0,
};

const productStore: Module<CampaignState, any> = {
    namespaced: true,
    state,
    mutations: {
        setProductCodes(state, product_codes: []) {
            state.product_codes = product_codes;
        },

        setCommissionRatePartner(state, rate) {
            state.commission_rate_partner = rate;
        },

        setCommissionRateContributor(state, rate) {
            state.commission_rate_contributor = rate;
        },
        setLoadDataIndex(state, status) {
            state.status_load_index = status;
        },
        setDetail(state, campaign) {
            state.get_detail = campaign;
        },
        setRateQuick(state, rate) {            
            state.commission_rate = rate;
        }
    },
    actions: {
        async removeProductOpenCampaign({ commit }, params)  {
            try {
                const response:any = await http.post(Networks.campaign.remove_product_open_campaign, params);
                if(response && response.result){
                    return response.result;
                }
                return {};
            } catch (error: any) {
                return {};
            }
        },
        async stopCampaignTarget({ commit }, params)  {
            try {
                const response:any = await http.post(Networks.campaign.stop_campaign_target, params);
                if(response && response.result){
                    return response.result;
                }
                return {};
            } catch (error: any) {
                return {};
            }
        },

        async stopCampaignTargetPartner({ commit }, params)  {
            try {
                const response:any = await http.post(Networks.campaign.stop_campaign_target_partner, params);
                if(response && response.result){
                    return response.result;
                }
                return {};
            } catch (error: any) {
                return {};
            }
        },

        async getDetailCampaign({ commit }, {id, partner = null})  {
            try {
                let url = Networks.campaign.get_detail_campaign_target.replace('{id}', id);
                const response:any = await http.get(url);
                if(response && response.result){
                    return response.result;
                }
                return {};
            } catch (error: any) {
                return {};
            }
        },

        addProductCode({ commit }, product_codes) {
            commit('setProductCodes', product_codes);
        },
        async addProductOpenCampaign({ commit }, params)  {
            try {
                const response:any = await http.post(Networks.campaign.add_product_open_campaign, params);                
                if (response && response.status === 422) {
                    return {
                        status: response.status,
                        response: response.data.errors,
                    }
                } else if (response && response.result) {
                    return {
                        status: 200,
                        result: response.result,
                    }
                } else {
                    return {
                        status: 500,
                    };
                }
            } catch (error: any) {
                return {};
            }
        },
        async createCampaignTarget({ commit }, params)  {
            try {
                const response:any = await http.post(Networks.campaign.create_campaign_target, params);                
                if (response && response.status === 422) {
                    return {
                        status: response.status,
                        response: response.data.errors,
                    }
                } else if (response && response.result) {
                    return {
                        status: 200,
                        result: response.result,
                    }
                } else {
                    return {
                        status: 500,
                    };
                }
            } catch (error: any) {
                return {};
            }
        },
        async updateCampaignTarget ({ commit }, params)  {
            try {
                const response:any = await http.post(Networks.campaign.update_campaign_target, params);                
                if (response && response.status === 422) {
                    return {
                        status: response.status,
                        response: response.data.errors,
                    }
                } else if (response && response.result) {
                    return {
                        status: 200,
                        result: response.result,
                    }
                } else {
                    return {
                        status: 500,
                    };
                }
            } catch (error: any) {
                return {};
            }
        },
        loadDataIndex({ commit }, status) {
            commit('setLoadDataIndex', status);
        },
        setRateQuick({ commit }, rate) {            
            commit('setRateQuick', rate);
        },
        async getCampaignsTarget({ commit }, params) {
            try {
                const response:any = await http.get(Networks.campaign.get_campaigns_target, {params: params});
                if(response && response.result){
                    return response.result;
                }
                return {};
            } catch (error: any) {
                return {};
            }
        },
        async getCampaignsTargetPartner({ commit }, params) {
            try {
                const response:any = await http.get(Networks.campaign.get_campaigns_target_partner, {params: params});
                if(response && response.result){
                    return response.result;
                }
                return {};
            } catch (error: any) {
                return {};
            }
        },
        async getDataCampaignForContributor({ commit }, params) {
            try {
                const response:any = await http.get(Networks.campaign.get_campaign_for_contributor, {params: params});
                if(response && response.result){
                    return response.result;
                }
                return {};
            } catch (error: any) {
                return {};
            }
        },
        async getCampaignsTargetContributor({ commit }, params) {
            try {
                const response:any = await http.get(Networks.campaign.get_campaigns_target_contributor, {params: params});
                if(response && response.result){
                    return response.result;
                }
                return {};
            } catch (error: any) {
                return {};
            }
        },
        async createCampaignForContributor({ commit }, params) {
            try {
                const response:any = await http.post(Networks.campaign.create_campaign_for_contributor, params);
                
                if (response && response.status === 422) {
                    return {
                        status: response.status,
                        response: response.data.errors,
                    }
                } else if (response && response.result) {
                    return {
                        status: 200,
                        result: response.result,
                    }
                } else {
                    return {
                        status: 500,
                    };
                }
            } catch (error: any) {
                return {};
            }
        },
        async updateRateProductOpenCampaign({ commit }, params) {
            try {
                const response:any = await http.post(Networks.campaign.update_rate_product_open_campaign, params);
                
                if (response && response.status === 422) {
                    return {
                        status: response.status,
                        response: response.data.errors,
                    }
                } else if (response && response.result) {
                    return {
                        status: 200,
                        result: response.result,
                    }
                } else {
                    return {
                        status: 500,
                    };
                }
            } catch (error: any) {
                return {};
            }
        },
        setDetail({ commit }, detail) {
            commit('setDetail', detail);
        },
        async exportOpenCampaign({ commit }, params)  {
            try {
                const response:any = await http.post(Networks.campaign.export_open_campaign, params);                
                if(response.result){
                    return response.result;
                }else{
                    return null;
                }
            } catch (error: any) {
                return {};
            }
        },
        async exportTargetCampaign({ commit }, params)  {
            try {
                const response:any = await http.post(Networks.campaign.export_target_campaign, params);   
                if(response.result){
                    return response.result;
                }else{
                    return null;
                }
            } catch (error: any) {
                return {};
            }
        },
        async exportOpenCampaignContributor({ commit }, params)  {
            try {
                const response:any = await http.post(Networks.campaign.export_open_campaign_contributor, params);                
                if(response.result){
                    return response.result;
                }else{
                    return null;
                }
            } catch (error: any) {
                return {};
            }
        },
        async exportTargetCampaignContributor({ commit }, params)  {
            try {
                const response:any = await http.post(Networks.campaign.export_target_campaign_contributor, params);                
                if(response.result){
                    return response.result;
                }else{
                    return null;
                }
            } catch (error: any) {
                return {};
            }
        },
    },
    getters: {
        product_codes(state): [] {
            return state.product_codes;
        },
        commission_rate_partner(state): number | null {
            return state.commission_rate_partner;
        },
        commission_rate_contributor(state): number | null {
            return state.commission_rate_contributor;
        },
        load_data_index(state) {
            return state.status_load_index;
        },
        get_detail(state) {
            return state.get_detail
        },
        commission_rate(state) {
            return state.commission_rate;
        },
    },
};

export default productStore;