<template>
    <div class='modal fade' ref="ModalChoosePartner" id='ModalChoosePartner' aria-labelledby='ModalChoosePartner'
        aria-hidden='true' data-bs-backdrop='false' tabindex="-1">
        <div class='modal-dialog modal-xl'>
            <div class='modal-content'>
                <div class='modal-header'>
                    <h1 class='modal-title fs-5'>{{ $t('addCTV') }}</h1>
                    <button type='button' class='btn-close' @click="closeModal()"></button>
                </div>
                <div class='modal-body'>
                    <div class='row mb-3'>
                        <div class="col-xl-8 col-md-8 col-sm-12">
                            <div class="row">
                                <div class="col-xl-4 col-md-6 col-sm-12 mb-2">
                                    <InputText :placeholder="$t('searchCollaboratorName')" v-model="contributorNameLocal" />
                                </div>
                                <div class="col-xl-4 col-md-6 col-sm-12 mb-2">
                                    <InputSelect :placeholder="$t('numberOfOrders')" :options="optionsNumberOfOrders"
                                        v-model="numberOfOrderLocal" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-4 col-sm-12">
                            <button @click="searchPartner()" class="btn btn-info mx-1">
                                <span>{{ $t('search') }}</span>
                            </button>
                            <button class="btn btn-outline-secondary" @click="clearSearch()">
                                <span>{{ $t('clearFilter') }}</span>
                            </button>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-12'>
                            <div class="card">
                                <h5 class='card-title mb-2'>{{ $t('collaboratorLists') }}</h5>
                                <div class="table-responsive">
                                    <table class="table table-index table-bordered">
                                        <thead class="thead-light border">
                                            <tr>
                                                <th>
                                                    <input @click=onCheckAll(!statusCheckAll) :checked="statusCheckAll"
                                                        type="checkbox" />
                                                </th>
                                                <th scope="col">{{ $t('collaboratorName') }}</th>

                                                <th scope="col">
                                                    <TableSortField @onsort="sort" :name="this.$t('numberOfClicked')"
                                                        class="description" />
                                                </th>
                                                <th scope="col">{{ $t('numberOfOrders') }}</th>
                                                <th scope="col">{{ $t('estCommission') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody class="customtable" v-if="this.contributors?.length > 0">
                                            <template v-for="(contributor, index) in contributors" :key="index">
                                                <tr>
                                                    <th>
                                                        <input type="checkbox" v-model="contributorsActive"
                                                            :value="contributor" v-bind:id="contributor.id" />
                                                    </th>
                                                    <td>
                                                        <p>{{ contributor.name }}</p>
                                                    </td>
                                                    <td>{{ numberFormat(contributor.quantity_click) }}</td>
                                                    <td>{{ numberFormat(contributor.quantity_order) }}</td>
                                                    <td>{{ numberFormat(contributor.est_commission) }}</td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                    <div id="noData" v-if="this.contributors?.length == 0">
                                        <span>{{ $t('noData') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='row'>
                        <Paginate :value="paginate" :record="record" />
                    </div>
                    <div class='row'>
                        <div class='d-flex justify-content-end align-items-center mt-3'>
                            <div>
                                <span class="text-warning">{{ numberFormat(contributorsActive.length) }}</span>
                                <span v-html="$t('maxNumberContributorSelect')"></span>
                            </div>
                            <button class='btn btn-outline-secondary mx-2' @click="closeModal()">
                                {{ $t('cancel') }}
                            </button>
                            <button class='btn btn-warning mx-2' @click="confirmAddPartners()">
                                <span>
                                    {{ $t('confirm') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { numberFormat } from '@/utils/utils';
import InputText from '@/components/Form/InputText.vue';
import InputCheckbox from '@/components/Form/InputCheckbox.vue';
import InputSelect from '@/components/Form/InputSelect.vue';
import store from "@/store";
import Paginate from '@/components/Paginate.vue';
import TableSortField from '../../TableSortField.vue'
import toastr from 'toastr';

export default {
    name: "ModalChoosePartner",
    setup() {
        return { numberFormat };
    },
    components: {
        InputText,
        InputCheckbox,
        InputSelect,
        Paginate,
        TableSortField
    },
    data() {
        return {
            contributors: [],
            contributorsActive: [],
            statusCheckAll: false,
            record: this.$constant.PAGE.record,
            paginate: {
                totalRecord: 0,
                currentPage: 1,
                lastPage: 1,
            },
            optionsNumberOfOrders: this.$constant.PAGE.optionsNumberOfOrders,

            contributorNameLocal: null,
            numberOfOrderLocal: null,
            numberClickLocal: null,

            search: {
                contributorName: null,
                numberOfOrder: null,
                numberClick: null
            },
            sortData: {
                field: null,
                direction: null,
            },
            contributorIds: []
        }
    },
    watch: {
        contributorsActive: {
            handler: function (newData, oldData) {
                this.checkAddPartner();
            },
            deep: true
        },
        'paginate.currentPage': function () {
            this.getDataAndFormat();
        },
        contributorsActive(newVal) {
            this.contributors.forEach(contributor => {
                if (newVal.includes(contributor)) {
                    // Nếu contributor được chọn, thêm thuộc tính action = true
                    contributor.action = true;
                } else {
                    // Nếu contributor bị bỏ chọn, xóa thuộc tính action
                    delete contributor.action;
                }
            });
            this.checkAddPartner();
        }
    },
    computed: {
    },
    methods: {
        closeModal() {
            this.clearDataSearch();
            window.jQuery(this.$refs.ModalChoosePartner).modal('hide');
        },
        clearSearch() {
            this.clearDataSearch();
            this.getPartnersOrContributor();
        },
        clearDataSearch() {
            this.search.contributorName = null;
            this.search.numberOfOrder = null;
            this.search.numberClick = null;
            this.contributorNameLocal = null;
            this.numberOfOrderLocal = null;
            this.numberClickLocal = null;
            this.paginate.currentPage = 1;
        },
        async searchPartner() {
            this.search.contributorName = this.contributorNameLocal;
            this.search.numberOfOrder = this.numberOfOrderLocal;
            this.search.numberClick = this.numberClickLocal;
            this.getPartnersOrContributor();
        },
        async getDataAndFormat() {
            await this.getPartnersOrContributor();
            this.checkAddPartner();
        },
        checkAddPartner() {
            if (!this.contributors.length) {
                this.statusCheckAll = false;
                return
            }
            for (let index = 0; index < this.contributors.length; index++) {
                const partner = this.contributors[index];
                let indexPar = this.contributorsActive.findIndex(partnerActive =>
                    partnerActive.id == partner.id
                );

                if (indexPar === -1) {
                    this.statusCheckAll = false;
                    return;
                }
            }
            this.statusCheckAll = true;
        },
        async sort(field, direction) {
            this.sortData.field = field;
            this.sortData.direction = direction;
            this.getPartnersOrContributor(this.sortData.field, this.sortData.direction);
        },
        async show(ids, contributors = []) {
            this.contributorIds = ids;
            this.contributors = contributors;
            
            window.jQuery(this.$refs.ModalChoosePartner).modal('show');
            await this.getPartnersOrContributor();
        },
        async getPartnersOrContributor(type = this.$constant.PAGE.typeGetContributePartner) {
            const res = await store.dispatch('userStore/getPartners', {
                type: type,
                currentPage: this.paginate.currentPage,
                record: this.record,
                ids: this.contributorIds,
                contributorName: this.search.contributorName,
                numberOfOrder: this.search.numberOfOrder,
                numberClick: this.search.numberClick,
                sort: this.sortData.direction,
            });
            if (res && res.data) {
                this.contributors = res.data;
                this.paginate.currentPage = res.current_page;
                this.paginate.lastPage = res.last_page;
                this.paginate.totalRecord = res.total;
            }
        },
        confirmAddPartners() {
            const newPartners = this.contributorsActive.filter(contributor =>
                !this.contributorIds.includes(contributor.id) // Kiểm tra nếu contributor chưa có trong danh sách đã chọn
            );
            if(newPartners?.length > 200){
                toastr.error(this.$t('max200'));
                return;
            }
            if (newPartners?.length == 0){
                toastr.error(this.$t('noChooseContributor'));
                return;
            }
            this.clearDataSearch();
            store.dispatch('userStore/addPartners', newPartners);
            this.contributorsActive = [];
            window.jQuery(this.$refs.ModalChoosePartner).modal('hide');
        },
        onCheckAll(statusCheckAll) {
            this.statusCheckAll = statusCheckAll;
            if (statusCheckAll) {
                this.checkAllPage();
                this.statusCheckAll = true;
            } else {
                this.checkPartPage();
                this.statusCheckAll = false;
            }
        },
        checkAllPage() {
            if (!this.contributorsActive.length) {
                this.contributorsActive = [...this.contributors];
            } else {
                let partnersActiveCurrent = [...this.contributorsActive]
                for (let index = 0; index < this.contributors.length; index++) {
                    const partner = this.contributors[index];
                    let indexPar = partnersActiveCurrent.findIndex(partnerActive => partnerActive.id == partner.id);
                    if (indexPar === -1) {
                        partnersActiveCurrent.push(this.contributors[index]);
                    }
                }
                this.contributorsActive = [...partnersActiveCurrent];
            }
        },
        checkPartPage() {
            let partnersActiveCurrent = [...this.contributorsActive]
            for (let index = 0; index < this.contributors.length; index++) {
                const partner = this.contributors[index];
                let indexPar = partnersActiveCurrent.findIndex(partnerActive =>
                    partnerActive.id == partner.id
                );
                if (indexPar !== false) {
                    partnersActiveCurrent.splice(indexPar, 1);
                }
            }
            this.contributorsActive = [...partnersActiveCurrent];
        }
    },
    beforeUnmount(){
        this.closeModal();
    }

}
</script>

<style scoped lang="scss"></style>
