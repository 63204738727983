<template>
    <div class='modal fade' id='ModalSelectCollaborator' aria-labelledby='ModalSelectCollaborator' aria-hidden='true'
        data-bs-backdrop='false' tabindex="-1">
        <div class='modal-dialog modal-xl'>
            <div class='modal-content'>
                <div class='modal-header'>
                    <h1 class='modal-title fs-5'>{{ $t('selectCollaborator') }}</h1>
                    <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                </div>
                <div class='modal-body'>
                    <div class='row mb-3'>
                        <div class="col-xl-3 col-md-3 col-sm-12">
                            <InputText :placeholder="$t('searchCollaboratorName')" />
                        </div>
                        <div class="col-xl-2 col-md-2 col-sm-12">
                            <InputSelect :placeholder="$t('numberOfClicked')"/>
                        </div>
                        <div class="col-xl-3 col-md-3 col-sm-12">
                            <InputSelect :placeholder="$t('numberOfOrders')"/>
                        </div>
                        <div class="col-xl-2 col-md-2 col-sm-12">
                            <button class="btn btn-info mx-2">
                                <span>{{ $t('search') }}</span>
                            </button>
                            <button class="btn btn-outline-secondary">
                                <span>{{ $t('clearFilter') }}</span>
                            </button>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-12'>
                            <div class="card">
                                <h5 class='card-title mb-2'>{{ $t('collaboratorLists') }}</h5>
                                <div class="table-responsive">
                                    <table class="table table-index table-bordered" >
                                        <thead class="thead-light border">
                                            <tr>
                                                <th>
                                                    <input type="checkbox"/>
                                                </th>
                                                <th scope="col">{{ $t('collaboratorName') }}</th>
                                                <th scope="col">{{ $t('numberOfClicked') }}</th>
                                                <th scope="col">{{ $t('numberOfOrders') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody class="customtable">
                                            <template v-for="(partner, index) in partners" :key="index">
                                                <tr>
                                                    <th>
                                                        <input type="checkbox"/>
                                                    </th>
                                                    <td>
                                                        <p>{{ partner.name }}</p>
                                                    </td>
                                                    <td>{{ partner.numberClicked }}</td>
                                                    <td>{{ partner.totalOrders }}</td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='row'>
                        <div class='d-flex justify-content-end align-items-center mt-3'>
                            <div>
                                <span class="text-warning">{{ numberFormat(0) }}</span>
                                CTV được chọn (Tối đa <span class="text-warning">{{ numberFormat(200) }}</span>)
                            </div>
                            <button class='btn btn-outline-secondary mx-2' data-bs-dismiss='modal' aria-label='Close'>
                                {{ $t('cancel') }}
                            </button>
                            <button class='btn btn-warning mx-2' data-bs-dismiss='modal' aria-label='Close'>
                                <span>
                                    {{ $t('confirm') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { numberFormat } from '@/utils/utils';
import InputText from '@/components/Form/InputText.vue';
import InputCheckbox from '@/components/Form/InputCheckbox.vue';
import InputSelect from '@/components/Form/InputSelect.vue';
export default {
    name: "ModalSelectCollaborator",
    setup() {
        return { numberFormat };
    },
    components: {
        InputText,
        InputCheckbox,
        InputSelect
    },
    data(){
        return {
            partners: [
                {id: 1, name: 'Công ty Nhật Minh', numberCollaborator: 5 , numberClicked: 19, totalOrders: 200 },
                {id: 2, name: 'Công ty Nhật Minh', numberCollaborator: 5 , numberClicked: 19, totalOrders: 200 },
                {id: 3, name: 'Công ty Nhật Minh', numberCollaborator: 5 , numberClicked: 19, totalOrders: 200 },
            ],
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
</style>
