<template>
    <div id="collaborator-page">
        <div class='container-fluid p-3'>
            <div class='row'>
                <div class='form-group row'>
                    <div class="col-xl-7 col-md-9 col-sm-12">
                        <div class="row">
                            <div class="col-xl-4 col-md-4 col-sm-12 mb-2">
                                <InputText :placeholder="$t('searchContributor')" v-model="filters.keyword"/>
                            </div>
                            <div class="col-xl-3 col-md-3 col-sm-12 mb-2">
                                <InputSelect :placeholder="$t('status')" :options="this.$constant.CONTRIBUTOR.statusListFilter" v-model="filters.status"/>
                            </div>
                            <div class="col-xl-5 col-md-5 col-sm-12">
                                <Daterangepicker :placeholder="$t('createdAt')" v-model="filters.created"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-5 col-md-3 col-sm-12">
                        <div class="row">
                            <div class="col-xl-12 col-md-12 col-sm-12 mb-2">
                                <button type='submit' class='btn btn-success' @click="search">
                                    <span>
                                        {{ $t('search') }}
                                    </span>
                                </button>
                                <button class='btn btn-secondary mx-2' @click="clearFilter">
                                    <span>
                                        {{ $t('clearFilter') }}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='form-group row'>
                    <div class="col-xl-12 col-md-12 col-sm-12 mb-2 d-flex justify-content-end">
                        <button class='btn btn-outline-secondary mx-2' @click="exportData">
                            <span>
                                {{ $t('exportData') }}
                            </span>
                        </button>
                        <button type='submit' class='btn btn-primary'>
                            <router-link :to="this.$constant.ROUTER.createCollaborators" class="text-white">
                                <i class="fa fa-plus"></i>
                                <span class="mx-2">
                                    {{ $t('createCollaborator') }}
                                </span>
                            </router-link>
                        </button>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div class='col-lg-12'>
                    <div class="card">
                        <div class="table-responsive">
                            <table class="table">
                                <thead class="thead-light border">
                                    <tr>
                                        <th scope="col">{{ $t('id') }}</th>
                                        <th scope="col">{{ $t('collaborator') }}</th>
                                        <th scope="col">{{ $t('email') }}</th>
                                        <th scope="col">{{ $t('phone') }}</th>
                                        <th scope="col">{{ $t('joiningDate') }}</th>
                                        <th scope="col">{{ $t('numberOfCampaigns') }}</th>
                                        <th scope="col">{{ $t('status') }}</th>
                                        <th scope="col">{{ $t('action') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="customtable" v-if="this.entries?.length > 0">
                                    <template v-for="entry in entries" :key="entry">
                                        <tr>
                                            <td v-text="entry.id"></td>
                                            <td v-text="entry.name"></td>
                                            <td v-text="entry.email"></td>
                                            <td v-text="entry.phone"></td>
                                            <td v-text="entry.active ? formatDateCTV(entry.active, 'DD/MM/YYYY') : null"></td>
                                            <td v-text="numberFormat(entry?.number_campaign)"></td>
                                            <td>
                                                <span v-if="entry.status == this.$constant.CONTRIBUTOR.status.send_invitation">{{ $t('sendInvitations') }}</span>
                                                <SwitchButton v-model="entry.status" @update:modelValue="changeStatusContributor(entry)" v-else/>
                                            </td>
                                            <td class="cursor-pointer">
                                                <!-- <span @click="showModalDetail(entry)">
                                                    <i class="fas fa-eye text-active mx-2"></i>
                                                </span>
                                                <span @click="update(entry)">
                                                    <i class="far fa-edit text-active mx-2"></i>
                                                </span>
                                                <span @click="showModalRemove(entry)">
                                                    <i class="fas fa-trash-alt text-active mx-2"></i>
                                                </span> -->

                                                <span class="pe-auto" @click="showModalDetail(entry)">
                                                    <span class="icon_tooltip" :titleCustom="$t('showDetail')"><i class="fas fa-eye text-active mx-2"></i></span>
                                                </span>
                                                <span class="" @click="update(entry)">
                                                    <span class="icon_tooltip" :titleCustom="$t('edit')"><i class="far fa-edit text-active mx-2"></i></span>
                                                </span>
                                                <span class="" @click="showModalRemove(entry)" v-if="entry.status == this.$constant.CONTRIBUTOR.status.send_invitation">
                                                    <span class="icon_tooltip" :titleCustom="$t('delete')"><i class="fas fa-trash-alt text-active mx-2"></i></span>
                                                </span>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                            <div id="noData" v-if="this.entries?.length == 0">
                                <span>{{ $t('noData') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Paginate :value="paginate" :record="filters.record" @pageChange="pageChange" v-if="this.entries?.length > 0"/>
        </div>
        <ModalCollaboratorDetail ref="ModalCollaboratorDetail"/>
        <ModalRemoveCollaborator ref="ModalRemoveCollaborator"/>
    </div>
</template>

<script>
import Daterangepicker from '@/components/Daterangepicker.vue'
import { numberFormat, formatDateCTV } from '@/utils/utils';
import InputText from '@/components/Form/InputText.vue';
import Paginate from '@/components/Paginate.vue';
import SwitchButton from '@/components/Button/SwitchButton.vue';
import ModalCollaboratorDetail from '@/components/Modal/Collaborator/ModalCollaboratorDetail.vue';
import ModalRemoveCollaborator from '@/components/Modal/Collaborator/ModalRemoveCollaborator.vue';
import { mapGetters } from 'vuex';
import InputSelect from '@/components/Form/InputSelect.vue';
import toastr from "toastr";

export default {
    name: 'Collaborator',
    setup() {
        return { numberFormat, formatDateCTV };
    },
    components: {
        Daterangepicker,
        InputText,
        Paginate,
        SwitchButton,
        ModalCollaboratorDetail,
        ModalRemoveCollaborator,
        InputSelect
    },
    computed: {
        ...mapGetters({
            entries: 'collaborators/listCollaborators',
            reload: 'collaborators/getReload',
        })
    },
    data() {
        return {
            paginate: {
                totalRecord: 1,
                currentPage: 1,
                lastPage: 1
            },
            filters: {
                record: this.$constant.PAGE.record
            }
        }
    },
    mounted(){
        if (this.$route.query && this.$route.query.name) {
            this.filters.keyword = this.$route.query.name;
        }
        this.loadData()
    },
    methods: {
        async loadData(){
            const res = await this.$store.dispatch('collaborators/fetchCollaborators', this.filters);
            this.paginate.totalRecord = res?.data?.data?.total
            this.paginate.currentPage = res?.data?.data?.current_page
            this.paginate.lastPage = res?.data?.data?.last_page
        },
        showModalDetail(entry){
            this.$store.dispatch('collaborators/setDetail', entry);
            this.$refs.ModalCollaboratorDetail.show();
        },
        update(entry){
            this.$store.dispatch('collaborators/setDetail', entry);
            this.$router.push({name: this.$constant.ROUTER.editCollaboratorsName});
        },
        search(){
            this.filters.page = 1;
            this.loadData();
        },
        showModalRemove(entry){
            this.$store.dispatch('collaborators/setDetail', entry);
            this.$refs.ModalRemoveCollaborator.show();
        },
        async exportData(){
            const res = await this.$store.dispatch('collaborators/export', this.filters);
            if(res){
                this.$store.dispatch('setShowModalExport', true)
            }
        },
        clearFilter(){
            this.filters = {
                record: this.$constant.PAGE.record,
                status: null,
            }
            this.loadData();
        },
        pageChange(page) {
            this.filters.page = page
            this.loadData()
        },
        async changeStatusContributor(entry){
            if(!entry){
                return;
            }
            const res = await this.$store.dispatch('partners/updateStatus', {
                'id': entry.id,
                'status': entry.status
            })
            if(res.error){
                toastr.error(this.$t('actionError'));
                return;
            }
            toastr.success(this.$t('actionSuccess'));
        }
    },
    watch: {
        'reload': function (value){
            if(value){
                this.loadData()
                this.$store.dispatch('collaborators/setReload', false);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
</style>