import axios from 'axios';
import store from '@/store';

const BASE_URL = process.env.VUE_APP_V1_API_URL;
const language = localStorage.getItem('language') ?? 'vi';
const token = localStorage.getItem('tokenAffiliate') ?? '';
const download = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
});

download.interceptors.request.use(
    (config) => {
        store.dispatch('setLoading', true)
        if(token){
            config.headers.Authorization = 'Bearer ' + token;
        }
        if(language){
            config.headers.Language = language;
        }
        config.responseType = 'blob';
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

download.interceptors.response.use(
    (response) => {
        store.dispatch('setLoading', false)
        if(response?.data){
            return response?.data;
        }
        return response;
    },
    (error) => {
        store.dispatch('setLoading', false)
        return Promise.reject(error);
    }
);

export default download;
