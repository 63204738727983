<template>
    <div class='modal fade' id='ModalChooseProduct' ref="ModalChooseProduct"
        data-bs-backdrop='false' tabindex="-1">
        <div class='modal-dialog modal-xl'>
            <div class='modal-content'>
                <div class='modal-header'>
                    <h1 class='modal-title fs-5'>{{ $t('chooseProduct') }}</h1>
                    <button type='button' class='btn-close' @click="closeModal()"></button>
                </div>
                <div class='modal-body'>
                    <div class='row mb-3'>
                        <div class="col-xl-8 col-md-8 col-sm-12">
                            <div class="row">
                                <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                                    <InputText :placeholder="$t('skuIdCode')" v-model="skuLocal"/>
                                </div>
                                <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                                    <InputText :placeholder="$t('searchProductName')" v-model="productNameLocal"/>
                                </div>
                                <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                                    <InputSelect :placeholder="$t('selectCategory')" v-model="categoryIdLocal" :options="categories"/>
                                </div>
                                <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                                    <InputSelect :placeholder="$t('selectBrand')" v-model="brandIdLocal" :options="brands"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-4 col-sm-12">
                            <button @click="searchProducts()" class="btn btn-info mx-2">
                                <span>{{ $t('search') }}</span>
                            </button>
                            <button @click="clearDataSearchAndGet()" class="btn btn-outline-secondary">
                                <span>{{ $t('clearFilter') }}</span>
                            </button>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-12'>
                            <div class="card">
                                <div class="table-responsive">
                                    <table class="table table-index table-bordered">
                                        <thead class="thead-light border">
                                            <tr>
                                                <th>
                                                    <input @click=onCheckAll(!statusCheckAll) :checked="statusCheckAll" type="checkbox"/>
                                                </th>
                                                <th scope="col">{{ $t('product') }}</th>
                                                <th scope="col">{{ $t('numberOfSales') }}</th>
                                                <th scope="col">{{ $t('price') }}</th>
                                                <th scope="col">{{ $t('inventory') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody class="customtable" v-if="this.products?.length > 0">
                                            <template v-for="(product, index) in products" :key="index">
                                                <tr>
                                                    <th>
                                                        <input v-model="productsActive" :value="product" 
                                                        :disabled="productsOrigin.find((origin) => origin.id == product.id)"
                                                        v-bind:id="product.id" type="checkbox"/>
                                                    </th>
                                                    <td>
                                                        <div class="info-products d-flex">
                                                            <div class="image">
                                                                <img :src="product.main_image ?? image_default"/>
                                                            </div>
                                                            <div class="product mx-1">
                                                                <p>{{ product.name }}</p>
                                                                <p>
                                                                    {{ $t('skuIdCode') }}: {{ product?.product_variant_max_price?.sku }}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{{ numberFormat(product.sales) }}</td>
                                                    <td>
                                                        <span v-if="product.product_variant_max_price">{{ $filters.formatPrice(product.product_variant_max_price.price) }}</span>
                                                        <span v-else>0</span>
                                                    </td>
                                                    <td>{{ numberFormat(product.inventory) }}</td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                    <div id="noData" v-if="this.products?.length == 0">
                                        <span>{{ $t('noData') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='row'>
                        <Paginate :value="paginate" :record="record"/>
                    </div>
                    <div class='row'>
                        <div class='d-flex justify-content-end align-items-center mt-3'>
                            <div>
                                <span class="text-warning">{{ numberFormat(this.productsActive?.length) }}&nbsp;</span>
                                <span v-html="$t('maxNumberProductSelect')"></span>
                            </div>
                            <button class='btn btn-outline-secondary mx-2' @click="closeModal()">
                                {{ $t('cancel') }}
                            </button>
                            <button class='btn btn-warning mx-2' @click="confirmAddProducts()">
                                <span>
                                    {{ $t('confirm') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { numberFormat } from '@/utils/utils';
import InputText from '@/components/Form/InputText.vue';
import InputCheckbox from '@/components/Form/InputCheckbox.vue';
import InputSelect from '@/components/Form/InputSelect.vue';
import store from "@/store";
import Paginate from '@/components/Paginate.vue';
import image_default from '../../../../public/images/default-image.jpg';
import toastr from 'toastr';

export default {
    name: "ModalChooseProduct",
    setup() {
        return { numberFormat };
    },
    components: {
        InputText,
        InputCheckbox,
        InputSelect,
        Paginate,
    },
    data(){
        return {
            products: [],
            record: this.$constant.PAGE.record,
            paginate: {
                totalRecord: 0,
                currentPage: 1,
                lastPage: 1,
            },
            statusCheckAll: false,
            productsActive: [],
            productNameLocal: null,
            skuLocal: null,
            categoryIdLocal: null,
            brandIdLocal: null,
            search: {
                productName: null,
                skuLocal: null,
                categoryId: null,
                brandId: null,
            },
            image_default: image_default,
            productsOrigin: [],
            pIDnoSearch: [],
            categories: [],
            brands: [],
        }
    },
    watch: {
        'paginate.currentPage': function () {       
            this.getDataAndFormat();         
        },
        productsActive: {
            handler: function(newv, ol) {  
                this.checkAddProduct();            
            },
            deep: true
        },
    },
    methods: {
        async getListCategory() {
            const res = await store.dispatch('productStore/getListCategory');
            this.categories = res;
        },
        async getListBrands() {
            const res = await store.dispatch('productStore/getListBrands');
            this.brands = res;
        },
        closeModal() {
            this.clearDataSearch();
            window.jQuery(this.$refs.ModalChooseProduct).modal('hide');
        },
        confirmAddProducts() {
            if(this.productsActive?.length > 200){
                toastr.error(this.$t('max200'));
                return;
            }
            this.clearDataSearch();
            store.dispatch('productStore/addProductCampaignTarget', this.productsActive);
            window.jQuery(this.$refs.ModalChooseProduct).modal('hide');
        },
        async clearDataSearchAndGet() {
            this.clearDataSearch();
            await this.getListProductsForAddCampaignTarge();
        },
        async clearDataSearch() {
            this.search.productName = null;
            this.productNameLocal = null;
            this.search.sku = null;
            this.skuLocal = null;
            this.paginate.currentPage = 1;
            this.search.categoryId = null;
            this.search.brandId = null;
            this.categoryIdLocal = null;
            this.brandIdLocal = null;
        },
        async searchProducts() {
            this.search.productName = this.productNameLocal;
            this.search.sku = this.skuLocal;
            this.search.brandId = this.brandIdLocal;
            this.search.categoryId = this.categoryIdLocal;
            await this.getListProductsForAddCampaignTarge();
        },
        async getDataAndFormat() {
            await this.getListProductsForAddCampaignTarge();
            this.checkAddProduct();
        },
        checkAddProduct() {             
            if (!this.products.length) {
                this.statusCheckAll = false;
                return;
            }    
            for (let index = 0; index < this.products.length; index++) {
                const product = this.products[index];                
                let indexPar = this.productsActive.findIndex(productActive => 
                    productActive.id == product.id                    
                );                
                if (indexPar === -1) {
                    this.statusCheckAll = false;
                    return;
                }
            }        
            this.statusCheckAll = true;
        },
        onCheckAll(statusCheckAll) {     
            this.statusCheckAll = statusCheckAll;
            if (statusCheckAll) {
                this.checkAllPage();
                this.statusCheckAll = true;
            } else {   
                this.checkPartPage();
                this.statusCheckAll = false;
            }
        },
        checkAllPage() {
            if (!this.productsActive.length) {
                this.productsActive = [...this.products];
            } else {
                let productsActiveCurrent = [...this.productsActive]     
                for (let index = 0; index < this.products.length; index++) {
                    const partner = this.products[index];
                    let indexProduct = productsActiveCurrent.findIndex(partnerActive => partnerActive.id == partner.id);
                    if (indexProduct === -1) {
                        productsActiveCurrent.push(this.products[index]);
                    }
                }        
                this.productsActive = [...productsActiveCurrent];
            }
        },
        checkPartPage() {
            let productsActiveCurrent = [...this.productsActive]     
            for (let index = 0; index < this.products.length; index++) {
                const partner = this.products[index];                
                let indexPrd = productsActiveCurrent.findIndex(partnerActive => 
                    partnerActive.id == partner.id                    
                );
                let indexPrdOrigin = this.productsOrigin.findIndex(origin => 
                    origin.id == partner.id                    
                );
                if (indexPrd !== -1 && indexPrdOrigin === -1) {
                    productsActiveCurrent.splice(indexPrd, 1);
                }
            }        
            this.productsActive = [...productsActiveCurrent];
        },
        async show(productsActive = [], productsOrigin = []) {
            this.pIDnoSearch = productsActive.map(item => item.id);
            await this.getListProductsForAddCampaignTarge();
            this.getListCategory();
            this.getListBrands();
            window.jQuery(this.$refs.ModalChooseProduct).modal('show');
            this.productsActive = this.formatProductsActive([...productsActive]);
            this.productsOrigin = productsOrigin;            
        },
        formatProductsActive(productsActive = []) {
            const clonedProducts = productsActive.map(v => {
                let clone = { ...v };
                if (clone.hasOwnProperty('commission_rate')) {
                    delete clone.commission_rate;
                }
                return clone;
            });
            return clonedProducts;
        },
        async getListProductsForAddCampaignTarge() {
            const res = await store.dispatch('productStore/getListProductsForAddCampaignTarge', {
                record: this.record,
                page: this.paginate.currentPage,
                productName: this.search.productName,
                sku: this.search.sku,
                pIDnoSearch: this.pIDnoSearch,
                categoryId: this.search.categoryId,
                brandId: this.search.brandId,
            });
            if (res && res.data) {
                this.products = res.data;
                this.paginate.currentPage = res.current_page;
                this.paginate.lastPage = res.last_page;
                this.paginate.totalRecord = res.total;
            }
        }
    },
    beforeUnmount(){
        this.closeModal();
    }
}
</script>

<style scoped lang="scss">
#ModalChooseProduct{
    .table-responsive{
        .customtable {
            .info-products{
                .image{
                    width: 60px;
                }
                .image img{
                    width: 100%;
                    transform: scale(1);
                }
                p{
                    margin-bottom: 0px;
                }
            }
        }
    }
}
</style>
