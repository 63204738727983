<template>
    <div class='modal fade' ref="ModalRejectPartner" id='ModalRejectPartner' aria-labelledby='ModalRejectPartner' aria-hidden='true'
        data-bs-backdrop='false' tabindex="-1">
        <div class='modal-dialog modal-md'>
            <div class='modal-content'>
                <div class='modal-header border-bottom-0'>
                    <h1 class='modal-title fs-5'>{{ $t('confirm') }}</h1>
                    <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                </div>
                <div class='modal-body pt-0'>
                    <div class='row mb-3'>
                        <div class='col-lg-12 col-md-12 col-sm-12 text-center'>
                            <span>{{ $t('contentRejectPartner') }}</span>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-12 col-md-12 col-sm-12'>
                            <InputText :placeholder="$t('enterRejectReason')" v-model="this.reason" :errors="this.errors.reason ?? []"/>
                        </div>
                    </div>
                    <div class='row mt-3'>
                        <div class='d-flex justify-content-center mt-3'>
                            <button class='btn btn-secondary mx-2' data-bs-dismiss='modal' aria-label='Close'>
                                {{ $t('cancel') }}
                            </button>
                            <button class='btn btn-primary mx-2' @click="confirm">
                                <span>
                                    {{ $t('confirm') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputText from '@/components/Form/InputText.vue';
import toastr from 'toastr';
export default {
    name: "ModalRejectPartner",
    emits: ['reload'],
    props: [],
    components: {
        InputText
    },
    data(){
        return {
            id: null,
            reason: null,
            errors: [],
        }
    },
    methods: {
        async confirm(){
            const res = await this.$store.dispatch('partners/rejectPartner', {id: this.id, reason: this.reason})
            if(res.error && res.messages){
                this.errors = res.messages;
                return;
            }
            if(res?.code === 200 && !res?.error){
                toastr.success(this.$t('rejectPartnerSuccess'))
                this.hide();
                return;
            }
            toastr.error(this.$t('rejectPartnerFail'))
        },
        show(id){
            if(!id){
                return;
            }
            this.id = id;
            window.jQuery(this.$refs.ModalRejectPartner).modal('show');
        },
        hide(){
            this.$emit('reload')
            window.jQuery(this.$refs.ModalRejectPartner).modal('hide');
        }
    }

}
</script>

<style scoped lang="scss">
// @import "../../assets/scss/Modal/ModalConfirm.scss";
</style>
