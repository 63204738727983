import { Module } from 'vuex';
import upload from '@/services/upload';
import { Networks } from '@/services/Networks';
interface UploadFileState {
    url: [];
}

const state: UploadFileState = {
    url: [],
};

const uploadFile: Module<UploadFileState, any> = {
    namespaced: true,
    state,
    mutations: {
        setImageUrl(state, upload: []) {
            state.url = upload;
        },
    },
    actions: {
        async upload({ commit }, file) {
            try {
                const formData = new FormData()
                formData.append('files', file)
                formData.append('p_name', 'admin');
                const response:any = await upload.post(Networks.upload.upload_files, formData);
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

    },
    getters: {
        getImageUrl(state): [] {
            return state.url;
        },
    },
};

export default uploadFile;
