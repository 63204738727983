<template>
    <div class='modal fade' id='ModalSetCommission' aria-labelledby='ModalSetCommission' aria-hidden='true'
        data-bs-backdrop='false' tabindex="-1">
        <div class='modal-dialog modal-lg'>
            <div class='modal-content'>
                <div class='modal-header'>
                    <h1 class='modal-title fs-5'>{{ $t('setCommissionCollaborator') }}</h1>
                    <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                </div>
                <div class='modal-body border'>
                    <div class='row mb-3'>
                        <div class='col-lg-12 col-md-12 col-sm-12'>
                            <label>{{$t('percentCommission')}}<span class="text-danger">*</span></label>
                            <InputTextAppend v-model="entry.commission" :iconAppend="'%'"/>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-6 col-md-12 col-sm-12'>
                            <label>{{$t('promotionPeriod')}}<span class="text-danger">*</span></label>
                            <div class="d-flex">
                                <SingleDaterangepicker 
                                    :placeholder="$t('startTime')" 
                                    v-model="entry.startTime" :class="!setEndTime ? 'w-100' : ''"/>
                                <SingleDaterangepicker 
                                    :placeholder="$t('endTime')" 
                                    v-model="entry.endTime" 
                                    v-if="setEndTime"
                                    class="mx-2"/>
                            </div>
                        </div>
                        <div class='col-lg-6 col-md-12 col-sm-12 d-flex align-items-end'>
                            <InputCheckbox :label="$t('setEndTime')" @update:modelValue="setEndTime" v-model="setEndTime"/>
                        </div>
                    </div>
                    <div class='row'>
                        <div class='d-flex justify-content-center mt-3'>
                            <button class='btn btn-secondary mx-2' data-bs-dismiss='modal' aria-label='Close'>
                                {{ $t('cancel') }}
                            </button>
                            <button class='btn btn-primary mx-2' data-bs-dismiss='modal' aria-label='Close'>
                                <span>
                                    {{ $t('add') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputTextAppend from '@/components/Form/InputTextAppend.vue';
import SingleDaterangepicker from '@/components/SingleDaterangepicker.vue';
import InputCheckbox from '@/components/Form/InputCheckbox.vue';
export default {
    name: "ModalSetCommission",
    components: {
        InputTextAppend,
        InputCheckbox,
        SingleDaterangepicker
    },
    data(){
        return {
            entry: {
                commission: '',
                startTime: '',
                endTime: '',
            },
            setEndTime: false,
        }
    },
    watch: {
        setEndTime(newValue){
            console.log(newValue);
        }
    }
}
</script>

<style scoped lang="scss">
</style>
