import { Module } from 'vuex';
import http from '@/services/http';
import { Networks } from '@/services/Networks';
interface PartnerState {
    collaborator: [];
    detail: {};
    error: string | null;
    reload: boolean
}

const state: PartnerState = {
    collaborator: [],
    detail: {},
    error: null,
    reload: false
};

const collaborators: Module<PartnerState, any> = {
    namespaced: true,
    state,
    mutations: {
        setCollaborators(state, collaborators: []) {
            state.collaborator = collaborators;
        },
        setDetail(state, detail: {}) {
            state.detail = detail;
        },
        setError(state, error: string | null) {
            state.error = error;
        },
        setReload(state, reload: boolean) {
            state.reload = reload;
        },
    },
    actions: {
        async fetchCollaborators({ commit }, params) {
            try {
                const response:any = await http.post(Networks.collaborator.getCollaborators, params);
                if(response?.data?.data?.data){
                    commit('setCollaborators', response?.data?.data?.data);
                }
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        setDetail({ commit }, detail) {
            commit('setDetail', detail);
        },

        async createCollaborator({ commit }, params) {
            try {
                const response:any = await http.post(Networks.collaborator.createCollaborator, params);
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        async updateCollaborator({ commit }, params) {
            try {
                const response:any = await http.post(Networks.collaborator.updateCollaborator, params);
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        async removeCollaborator({ commit }, params) {
            try {
                const response:any = await http.post(Networks.collaborator.removeCollaborator, params);
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        async updateQuantityClicked({ commit }, params) {
            try {
                const response:any = await http.post(Networks.collaborator.updateQuantityClicked, params);
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        setReload({ commit }, reload) {
            commit('setReload', reload);
        },

        async export({ commit }, params)  {
            try {
                const response:any = await http.post(Networks.collaborator.export, params);                
                if(response.result){
                    return response.result;
                }else{
                    return null;
                }
            } catch (error: any) {
                return {};
            }
        },
    },
    getters: {
        listCollaborators(state): [] {
            return state.collaborator;
        },
        getDetail(state): {} {
            return state.detail;
        },
        hasError(state): string | null {
            return state.error;
        },
        getReload(state): boolean {
            return state.reload;
        },
    },
};

export default collaborators;
