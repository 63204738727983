import { createI18n } from 'vue-i18n';
import vi from './vi.json'

const languageStorage = localStorage.getItem('language') ?? 'vi';

const i18n = createI18n({
    locale: languageStorage,
    fallbackLocale: languageStorage,
    messages: {
      vi
    }
});

export default i18n;
