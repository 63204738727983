<template>
    <div class='modal fade' ref="ModalEditCommission" id='ModalEditCommission' aria-labelledby='ModalEditCommission' aria-hidden='true'
        data-bs-backdrop='false' tabindex="-1">
        <div class='modal-dialog modal-lg'>
            <div class='modal-content'>
                <div class='modal-header'>
                    <h1 class='modal-title fs-5'>{{ $t('percentCommission') }}</h1>
                    <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                </div>
                <div class='modal-body border'>
                    <div class='row'>
                        <div class='col-lg-12 col-md-12 col-sm-12'>
                            <label>{{$t('percentCommission')}}<span class="text-danger">*</span></label>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-6 col-md-6 col-sm-12'>
                            <div class="form-group row">
                                <label class="col-sm-4 control-label col-form-label">
                                    {{ $t('partner') }}:
                                </label>
                                <div class="col-sm-8">
                                    <InputTextAppend v-model="entry.commission_rate_partner" :iconAppend="'%'" :errors="errors?.commission_rate_partner ?? []"/>
                                </div>
                            </div>
                        </div>
                        <div class='col-lg-6 col-md-6 col-sm-12'>
                            <div class="form-group row">
                                <label class="col-sm-4 control-label col-form-label">
                                    {{ $t('collaborator') }}:
                                </label>
                                <div class="col-sm-8">
                                    <InputTextAppend v-model="entry.commission_rate_contributor" :iconAppend="'%'" :errors="errors?.commission_rate_contributor ?? []"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='row'>
                        <div class='d-flex justify-content-center mt-3'>
                            <button @click="clearDataAndHideModal()" class='btn btn-secondary mx-2'>
                                {{ $t('cancel') }}
                            </button>
                            <button @click="updateRateProductOpenCampaign()" class='btn btn-primary mx-2'>
                                <span>
                                    {{ $t('save') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputText from '@/components/Form/InputText.vue';
import SingleDaterangepicker from '@/components/SingleDaterangepicker.vue';
import InputCheckbox from '@/components/Form/InputCheckbox.vue';
import InputTextAppend from '@/components/Form/InputTextAppend.vue';
import store from "@/store";
import { mapGetters } from 'vuex';
import toastr from 'toastr';


export default {
    name: "ModalEditCommission",
    components: {
        InputText,
        InputCheckbox,
        SingleDaterangepicker,
        InputTextAppend
    },
    data(){
        return {
            entry: {
                commission_rate_partner: 0,
                commission_rate_contributor: 0,
                product_codes: null,
            },
            errors: {},
        }
    },
    computed: {
        // ...mapGetters({
        //     product_codes: 'campaignStore/product_codes',
        // }),
    },
    methods: {
        async show(productCodes, ratePartner, rateContributor) {      
            this.entry.product_codes = productCodes;
            this.entry.commission_rate_partner = ratePartner;
            this.entry.commission_rate_contributor = rateContributor;  
            window.jQuery(this.$refs.ModalEditCommission).modal('show');
        },
        async updateRateProductOpenCampaign() {  
            this.errors = {};          
            const res = await store.dispatch('campaignStore/updateRateProductOpenCampaign', {
                product_codes: this.entry.product_codes,
                commission_rate_partner: this.entry.commission_rate_partner,
                commission_rate_contributor: this.entry.commission_rate_contributor,
            });
            if (!res || res.status === 500) {
                toastr.error('error server');
            } else if (res.status === 422) {
                this.errors = res.response;
            } else if (res.status === 200) {
                toastr.success(res.result.message);
                this.clearDataAndHideModal();
                store.dispatch('campaignStore/loadDataIndex', true);                
            }
        },
        clearDataAndHideModal() {
            this.entry.commission_rate_partner = 0;
            this.entry.commission_rate_contributor = 0;
            this.entry.product_codes = null;
            this.errors = {};
            window.jQuery(this.$refs.ModalEditCommission).modal('hide');
        },
    },
}
</script>

<style scoped lang="scss">
</style>
