import { Module } from 'vuex';
import http from '@/services/http';
import { Networks } from '@/services/Networks';

interface ProductState {
    product_ids: [];
    commission_rate_partner: number | null;
    commission_rate_contributor: number | null;
    products_add_campaign_target: [];
    products_have_order: [];
}

const state: ProductState = {
    product_ids: [],
    commission_rate_partner: 0,
    commission_rate_contributor: 0,
    products_add_campaign_target: [],
    products_have_order: [],
};

const productStore: Module<ProductState, any> = {
    namespaced: true,
    state,
    mutations: {
        setProductIds(state, product_ids: []) {
            state.product_ids = product_ids;
        },

        setCommissionRatePartner(state, rate: number | null) {
            state.commission_rate_partner = rate;
        },

        setCommissionRateContributor(state, rate: number | null) {
            state.commission_rate_contributor = rate;
        },
        setProductsAddCampaignTarge(state, productsAdd: []) {
            state.products_add_campaign_target = productsAdd;
        },
        setProductsHaveOrder(state, products_have_order: []) {
            state.products_have_order = products_have_order;
        },
    },
    actions: {
        async getListProductNotActiveOpenCampaign ({ commit }, params)  {
            try {
                const response:any = await http.get(Networks.campaign.get_list_product_not_active_open_campaign, {params: params});
                if(response && response.result){
                    return response.result;
                }
                return {};
            } catch (error: any) {
                return {};
            }
        },
        async getProductsOpenCampaignLimit ({ commit }, params)  {            
            try {
                const response:any = await http.get(Networks.campaign.get_list_product_active_open_campaign_limit, {params: params});
                if(response && response.result){
                    return response.result;
                }
                return {};
            } catch (error: any) {
                return {};
            }
        },
        async getListProductActiveOpenCampaign({ commit }, params)  {
            try {
                const response:any = await http.get(Networks.campaign.get_list_product_active_open_campaign, {params: params});
                if(response && response.result){
                    return response.result;
                }
                return {};
            } catch (error: any) {
                return {};
            }
        },
        async getListCategory({ commit }, params)  {
            try {
                const response:any = await http.get(Networks.campaign.get_list_categories, {params: params});
                if(response && response.result){
                    return response.result;
                }
                return {};
            } catch (error: any) {
                return {};
            }
        },
        async getListBrands({ commit }, params)  {
            try {
                const response:any = await http.get(Networks.campaign.get_list_brands, {params: params});
                if(response && response.result){
                    return response.result;
                }
                return {};
            } catch (error: any) {
                return {};
            }
        },

        async getListCategoryFilter({ commit }, params)  {
            try {
                const response:any = await http.get(Networks.campaign.get_list_categories_filter, {params: params});
                if(response && response.result){
                    return response.result;
                }
                return {};
            } catch (error: any) {
                return {};
            }
        },
        async getListBrandsFilter({ commit }, params)  {
            try {
                const response:any = await http.get(Networks.campaign.get_list_brands_filter, {params: params});
                if(response && response.result){
                    return response.result;
                }
                return {};
            } catch (error: any) {
                return {};
            }
        },
        async getListProductsForAddCampaignTarge ({ commit }, params)  {
            try {
                const response:any = await http.get(Networks.campaign.get_list_products_for_add_campaign_targe, {params: params});
                if(response && response.result){
                    return response.result;
                }
                return {};
            } catch (error: any) {
                return {};
            }
        },
        addProductCampaignTarget ({ commit }, productsAdd) {
            commit('setProductsAddCampaignTarge', productsAdd);
        },
        async getListProductHaveOrder ({ commit }, params)  {
            try {
                const response:any = await http.get(Networks.product.get_list_product_have_order, {params: params});
                if(response && response.result){
                    commit('setProductsHaveOrder', response.result);
                    return response.result;
                }
                return {};
            } catch (error: any) {
                return {};
            }
        },
    },
    getters: {
        product_ids(state): [] {
            return state.product_ids;
        },
        commission_rate_partner(state): number | null {
            return state.commission_rate_partner;
        },
        commission_rate_contributor(state): number | null {
            return state.commission_rate_contributor;
        },
        products_add_campaign_target(state): any | [] {
            return state.products_add_campaign_target;
        },
        products_have_order(state): any | [] {
            return state.products_have_order;
        },
    },
};

export default productStore;