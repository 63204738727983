import { Module } from "vuex";
import http from "@/services/http";
import { Networks } from "@/services/Networks";
interface ProductLinkState {
    productLink: [];
    error: string | null;
    status_load_index: boolean,
}

const state: ProductLinkState = {
    productLink: [],
    error: null,
    status_load_index: false,
};

const productLinks: Module<ProductLinkState, any> = {
    namespaced: true,
    state,
    mutations: {
        setProductLinks(state, marketingChannels: []) {
            state.productLink = marketingChannels;
        },
        setError(state, error: string | null) {
            state.error = error;
        },
        setLoadDataIndex(state, status) {            
            state.status_load_index = status;
        },
    },
    actions: {
        async fetchProductLinks({ commit }, params) {
            try {
                const response: any = await http.get(
                    Networks.product_links.getProductLinks,
                    { params: params }
                );
                if (response && response.result && response.status.code == 200) {
                    return response.result.data;
                } else {
                    return 'Server errors'
                }
            } catch (error: any) {
                commit("setError", error?.message || "");
            }
        },
        async createProductLinkChannel({ commit }, params) {
            try {
                const response: any = await http.post(
                    Networks.product_links.createProductLinkChannel,
                    params
                );
                return response;
            } catch (error: any) {
                commit("setError", error?.message || "");
            }
        },
        async setCommission({ commit }, params) {
            try {
                const response: any = await http.post(
                    Networks.product_links.setCommission,
                    params
                );
                if (response && response.status === 422) {
                    return {
                        status: response.status,
                        response: response.data.errors,
                    }
                } else if (response && response.result) {
                    return {
                        status: 200,
                        result: response.result,
                    }
                } else {
                    return {
                        status: 500,
                    };
                }
            } catch (error: any) {
                commit("setError", error?.message || "");
            }
        },
        async updateCommission({ commit }, params) {
            try {
                const response: any = await http.post(
                    Networks.product_links.updateCommission,
                    params
                );
                if (response && response.status === 422) {
                    return {
                        status: response.status,
                        response: response.data.errors,
                    }
                } else if (response && response.result) {
                    return {
                        status: 200,
                        result: response.result,
                    }
                } else {
                    return {
                        status: 500,
                    };
                }
            } catch (error: any) {
                commit("setError", error?.message || "");
            }
        },
        async setCommissionMultiple({ commit }, params) {
            try {
                const response: any = await http.post(
                    Networks.product_links.setCommissionMultiple,
                    params
                );
                if (response && response.status === 422) {
                    return {
                        status: response.status,
                        response: response.data.errors,
                    }
                } else if (response && response.result) {
                    return {
                        status: 200,
                        result: response.result,
                    }
                } else {
                    return {
                        status: 500,
                    };
                }
            } catch (error: any) {
                commit("setError", error?.message || "");
            }
        },
        loadDataIndex({ commit }, status) {
            commit('setLoadDataIndex', status);
        }
    },
    getters: {
        listProductLinks(state): [] {
            return state.productLink;
        },
        hasError(state): string | null {
            return state.error;
        },
        load_data_index(state) {
            return state.status_load_index;
        },
    },
};

export default productLinks;
