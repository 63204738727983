<template>
    <div class='modal-dialog modal-md' :class="isShow ? 'd-block' : 'd-none'" ref="ModalExport" id='ModalExport'>
        <div class='modal-content'>
            <div class='modal-header border-bottom-0'>
                <h1 class='modal-title fs-5'>{{ $t('exportData') }}</h1>
                <button type='button' class='btn-close' @click="hide"></button>
            </div>
            <div class='modal-body pt-0'>
                <div class='row mb-3'>
                    <div class='col-lg-12 col-md-12 col-sm-12' v-if="!this.fileNameExport">
                        <i class="fas fa-spinner fa-spin"></i>
                        {{ $t('creatingFile') }}
                    </div>
                    <div class='col-lg-12 col-md-12 col-sm-12' v-else>
                        <a href="javascript:void(0)" @click="download">
                            <font-awesome-icon :icon="['fas', 'download']"/>
                            {{ this.fileNameExport }}
                        </a>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-lg-12 col-md-12 col-sm-12'>
                        <ul class="error-label" v-if="errors && errors.length">
                            <li v-for="error in errors" :key="error" v-text="error"></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: "ModalExport",
    props: ['title', 'content', 'button', 'errors'],
    data(){
        return {
        }
    },
    methods: {
        hide(){
            if(!this.fileNameExport){
                return;
            }
            this.$store.dispatch('setFileNameExport', null)
            this.$store.dispatch('setShowModalExport', false)
        },
        show(){
            this.$store.dispatch('setShowModalExport', true)
        },
        async download(){
            if(this.fileNameExport){
                await this.$store.dispatch('exportStore/downloadFile', this.fileNameExport); 
                this.hide();
            }
        }
    },
    computed: {
        ...mapGetters({
            isShow: 'isShowModalExport',
            fileNameExport: 'fileNameExport'
        })
    },
    watch: {
        fileNameExport: {
            handler: function(newData) {                
                if(newData){
                    this.$store.dispatch('setShowModalExport', true)
                }
            },
            deep: true
        },

    },
    beforeUnmount(){
        this.isShow = false;
    },
    unmounted(){
        this.isShow = false;
    },

}
</script>

<style scoped lang="scss">
#ModalExport{
    position: fixed;
    bottom: 0px;
    right: 15px;
    min-width: 400px;
    z-index: 99;

    .error-label {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        color: red;
    }
    
    .error-input,
    .error-input:hover,
    .error-input:focus,
    .error-input:active {
        border: 1px solid red !important;
        outline: 0;
        box-shadow: unset;
    }
}
</style>
