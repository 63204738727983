import { Module } from 'vuex';
import http from '@/services/http';
import { Networks } from '@/services/Networks';
interface PartnerState {
    partner: [];
    detail: {};
    error: string | null;
    partner_have_order: [];
}

const state: PartnerState = {
    partner: [],
    detail: {},
    error: null,
    partner_have_order: [],
};

const partners: Module<PartnerState, any> = {
    namespaced: true,
    state,
    mutations: {
        setPartners(state, partners: []) {
            state.partner = partners;
        },
        setDetail(state, detail: {}) {
            state.detail = detail;
        },
        setError(state, error: string | null) {
            state.error = error;
        },
        setPartnerHasOrder(state, partner_have_order: []) {
            state.partner_have_order = partner_have_order;
        },
    },
    actions: {
        async fetchPartners({ commit }, params) {
            try {
                const response:any = await http.post(Networks.partners.getPartners, params);
                if(response?.data?.data?.data){
                    commit('setPartners', response?.data?.data?.data);
                }
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        async createPartner({ commit }, params) {
            try {
                const response:any = await http.post(Networks.partners.createPartner, params);
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        async updatePartner({ commit }, params) {
            try {
                const response:any = await http.post(Networks.partners.updatePartner, params);
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        async confirmPartner({ commit }, params) {
            try {
                const response:any = await http.post(Networks.partners.confirmPartner, params);
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        async rejectPartner({ commit }, params) {
            try {
                const response:any = await http.post(Networks.partners.rejectPartner, params);
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        async removePartner({ commit }, params) {
            try {
                const response:any = await http.post(Networks.partners.removePartner, params);
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        setDetail({ commit }, detail) {
            commit('setDetail', detail);
        },

        async updateStatus({ commit }, params) {
            try {
                const response:any = await http.post(Networks.partners.updateStatus, params);
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },
        async getPartnerHasOrder ({ commit }, params)  {
            try {
                const response:any = await http.get(Networks.performance.get_partners_has_order, {params: params});
                if(response && response.result){
                    commit('setPartnerHasOrder', response.result);
                    return response.result;
                }
                return {};
            } catch (error: any) {
                return {};
            }
        },
    },
    getters: {
        listPartners(state): [] {
            return state.partner;
        },
        getDetail(state): {} {
            return state.detail;
        },
        hasError(state): string | null {
            return state.error;
        },
        partner_have_order(state): any | [] {
            return state.partner_have_order;
        },
    },
};

export default partners;
