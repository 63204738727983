<template>
    <div id="daterangepicker">
        <div class="position-relative">
            <label v-if="label" v-text="label"></label>
            <input ref="input" :placeholder="placeholder" :class="[(errors && errors.length ? 'error-input' : '')]"
                autocomplete="off" class="form-control input-date-picker"
                :disabled="disabled"
            >
            <i class="far fa-calendar fa-md icon-calendar" aria-hidden="true" @click="showCalendar()">
            </i>
            <ul class="error-label" v-if="errors && errors.length">
                <li v-for="error in errors" :key="error" v-text="error"></li>
            </ul>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import {DATE_LOCALES} from '../common/constant';

function parseModelValue(modelValue, dDate) {
    const tmp = modelValue ? modelValue.split('_') : [];
    const startDate = tmp[0] ? moment(tmp[0]) : moment(dDate);
    const endDate = tmp[1] ? moment(tmp[1]) : moment();
    return [
        startDate, endDate
    ];
}
export default {
    name: "SingleDaterangepicker",
    props: ['modelValue', 'placeholder', 'label', 'errors', 'defaultDate', 'disabled'],
    watch: {
        modelValue: function () {
            if (!this.modelValue) {
                this.$refs.input.value = '';
                this.getData();
            } else {
                const dDate = moment().format('YYYY-MM-DD HH:mm:ss');
                const [startDate, endDate] = parseModelValue(this.modelValue, dDate);
                this.$refs.input.value = startDate.format('DD/MM/YYYY HH:mm');

            }
        },
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            const format = 'DD/MM/YYYY HH:mm';
            const input = this.$refs.input;
            const dDate = moment().format('YYYY-MM-DD HH:mm:ss');

            const [startDate, endDate] = parseModelValue(this.modelValue, dDate);
            const $input = window.jQuery(input);
            $input.daterangepicker({
                autoUpdateInput: false,
                startDate: startDate,
                endDate: endDate,
                locale: DATE_LOCALES,
                timePicker: true,
                singleDatePicker: true,
                timePicker24Hour: true,
                autoApply: true,
            });
            const changeHandler = (ev, picker) => {
                const start = $input.data('daterangepicker').startDate;
                input.value = start.format(format)
                const modelValue = start.format('YYYY-MM-DD HH:mm:ss');
                this.$emit('update:modelValue', modelValue);
                this.$nextTick(() => {
                    this.$emit('input', modelValue);
                });
            };
            $input.on('apply.daterangepicker', changeHandler);
            $input.on('change', changeHandler);
            if (this.modelValue) {
                input.value = startDate.format(format);
            }
        },
        showCalendar() {
            window.jQuery(this.$refs.input).focus()
        }
    }
}
</script>

<style scoped>
@import "../assets/scss/daterangepicker.scss";
</style>
