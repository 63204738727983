<template>
    <div id="input-text__form">
        <label v-if="label" v-text="label"></label>
        <input v-model.trim="model" @change="onChange(true)" 
            :readonly="!!readonly"
            :class="[(errors && errors.length ? 'error-input' : ''), (append ? 'pr-30' : '')]"
            :placeholder="placeholder || label" 
            :max="this.max ? this.max : ''" 
            :min="this.min ? this.min : ''" 
            :id="this.id"
            :type="type ? type : 'date'"
            :disabled="disabled"
            class="form-control">
        <ul class="error-label" v-if="errors && errors.length">
            <li v-for="error in errors" :key="error" v-text="error"></li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "InputDate",
    props: ['label', 'placeholder', 'modelValue', 'errors', 'readonly', 'min', 'max', 'id', 'type', 'disabled', 'append'],
    data() {
        return {
            model: this.modelValue,
        }
    },
    watch: {
        modelValue: function (newValue) {
            this.model = newValue;
        }
    },
    methods: {
        onChange() {
            this.$emit('update:modelValue', this.model);
            this.$nextTick(() => {
                this.$emit('change', this.model);
            })
        },
    }
}
</script>

<style scoped>
@import "../../assets//scss/Form/inputText.scss";
</style>
