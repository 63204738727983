import { Module } from 'vuex';
import http from '@/services/http';
import { Networks } from '@/services/Networks';
interface MarketingChannelState {
    marketingChannel: [];
    error: string | null;
}

const state: MarketingChannelState = {
    marketingChannel: [],
    error: null,
};

const marketingChannels: Module<MarketingChannelState, any> = {
    namespaced: true,
    state,
    mutations: {
        setMarketingChannels(state, marketingChannels: []) {
            state.marketingChannel = marketingChannels;
        },
        setError(state, error: string | null) {
            state.error = error;
        },
    },
    actions: {
        async fetchMarketingChannels({ commit }, params) {
            try {
                const response:any = await http.get(Networks.marketing_channels.get_marketing_channels, {params: params});
                if(response?.result?.data?.data){
                    commit('setMarketingChannels', response?.result?.data?.data);
                }
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        async createMarketingChannels({ commit }, params) {
            try {
                const response:any = await http.post(Networks.marketing_channels.create_marketing_channels, params);
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        async updateMarketingChannels({ commit }, params) {
            try {
                const response:any = await http.post(Networks.marketing_channels.update_marketing_channels, params);
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        async removeMarketingChannels({ commit }, params) {
            try {
                const response:any = await http.post(Networks.marketing_channels.remove_marketing_channels, params);
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        async getListMarketingChannels({ commit }, params) {
            try {
                const response:any = await http.get(Networks.marketing_channels.get_list_marketing_channels, {params: params});
                if(response?.result){
                    return response?.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },

        async getListMarketingChannelHasRevenue({ commit }, params) {
            try {
                const response:any = await http.get(Networks.marketing_channels.get_list_marketing_channels_has_revenue, {params: params});
                if(response?.result){
                    return response?.result;
                }
                return null;
            } catch (error: any) {
                return null;
            }
        },
    },
    getters: {
        listMarketingChannels(state): [] {
            return state.marketingChannel;
        },
        hasError(state): string | null {
            return state.error;
        },
    },
};

export default marketingChannels;
