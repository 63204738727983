import { Module } from 'vuex';
import http from '@/services/http';
import { Networks } from '@/services/Networks';
interface NoticeState {
    notices: [];
    number_notices: any;
    error: []
}

const state: NoticeState = {
    notices: [],
    number_notices: null,
    error: []
};

const notice: Module<NoticeState, any> = {
    namespaced: true,
    state,
    mutations: {
        setNotices(state, notices: []) {
            state.notices = notices;
        },
        setNumberNotice(state, number_notices: any) {
            state.number_notices = number_notices;
        },
        setError(state, error: []) {
            state.error = error;
        },
    },
    actions: {
        async getListNotices({ commit }) {
            try {
                const response:any = await http.get(Networks.notices.get_list_notices);
                if(response?.result){
                    commit('setNotices', response?.result)
                    return response?.result;
                }
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        async getNumberNoticeInActive({ commit }) {
            try {
                const response:any = await http.get(Networks.notices.get_number_notices_inactive);
                if(response?.result !== false){
                    commit('setNumberNotice', response?.result)
                    return response?.result;
                }
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        async updateStatusNotice({ commit }, params) {
            try {
                const response:any = await http.post(Networks.notices.update_status_notice, params);
                if(response?.result){
                    return response?.result;
                }
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        setNumberNotice({ commit }, number) {
            commit('setNumberNotice', number);
        },
        
    },
    getters: {
        getNotices(state): [] {
            return state.notices;
        },
        getNumberNotices(state): [] {
            return state.number_notices;
        },
    },
};

export default notice;
