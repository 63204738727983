<template>
    <div id="order-detail-page">
        <div class='container-fluid p-3'>
            <div class='row'>
                <div class='col-lg-12'>
                    <div class="card">
                        <div class="table-responsive">
                            <table class="table">
                                <thead class="thead-light border">
                                    <tr>
                                        <th scope="col">{{ $t('orderDetail') }}</th>
                                        <th scope="col">{{ $t('productInformation') }}</th>
                                        <th scope="col" v-if="role == this.$constant.PERMISSIONS.ROLE_ADMIN">{{ $t('partner') }}</th>
                                        <th scope="col" v-if="role == this.$constant.PERMISSIONS.ROLE_PARTNER">{{ $t('collaborator') }}</th>
                                        <th scope="col">{{ $t('campaignType') }}</th>
                                        <th scope="col">{{ $t('generalInformation') }}</th>
                                        <th scope="col">{{ $t('amountOfProduct') }}</th>
                                        <th scope="col">% {{ $t('commission') }}</th>
                                        <th scope="col">{{ $t('paymentInfor') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="customtable">
                                    <template v-if="entries && entries?.details && entries?.details?.length">
                                        <tr v-for="(entry, index) in entries?.details" :key="entry">
                                            <td class="border border-top-0" :class="(index + 1) != entries?.details?.length ? 'border-bottom-0' : ''">
                                                <div class='info mt-1 d-flex align-items-center' v-if="index === 0">
                                                    <div class='left'>{{ $t('orderCode') }}:</div>
                                                    <div class='right' v-text="entries?.code"></div>
                                                </div>
                                                <div class='info mt-1 d-flex align-items-center' v-if="index === 0">
                                                    <div class='left'>{{ $t('orderDate') }}:</div>
                                                    <div class='right' v-text="entries?.order_date ? formatDateCTV(entries?.order_date, 'DD/MM/YYYY') : null"></div>
                                                </div>
                                                <div class='info mt-1 d-flex align-items-center' v-if="index === 0">
                                                    <div class='left'>{{ $t('orderStatus') }}:</div>
                                                    <div class='right' v-text="entries?.statusName"></div>
                                                </div>
                                                <div class='info mt-1 d-flex align-items-center' v-if="index === 0">
                                                    <div class='left'>{{ $t('reasonCancel') }}:</div>
                                                    <div class='right' v-text="entries?.cancel_reason"></div>
                                                </div>
                                            </td>
                                            <td class="border">
                                                <div class="info-products d-flex justify-content-between">
                                                    <div class="d-flex">
                                                        <div class="image">
                                                            <img v-if="entry?.product_image" :src="entry?.product_image"/>
                                                        </div>
                                                        <div class="product mx-1">
                                                            <p>
                                                                <a class="sidebar-link has-arrow waves-effect waves-dark" target="_blank" :href="urlPharmaProduct(entry?.product_slug)" aria-expanded="false">
                                                                    {{ entry?.product_name }}
                                                                </a>
                                                            </p>
                                                            <p>
                                                                {{ $t('skuId') }}: {{ entry?.product_sku }}
                                                            </p>
    
                                                        </div>
                                                    </div>
                                                    <div class="price">
                                                        <p>{{ numberFormat(entry?.price) }}</p>
                                                        <p>x{{ numberFormat(entry?.quantity) }}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="border border-top-0" :class="(index + 1) != entries?.details?.length ? 'border-bottom-0' : ''" v-if="role == this.$constant.PERMISSIONS.ROLE_ADMIN">
                                                <span v-if="index === 0">
                                                    {{ entries?.partner_name }}
                                                </span>
                                            </td>
                                            <td class="border border-top-0" :class="(index + 1) != entries?.details?.length ? 'border-bottom-0' : ''" v-if="role == this.$constant.PERMISSIONS.ROLE_PARTNER">
                                                <span v-if="index === 0">
                                                    {{ entries?.partner_name }}
                                                </span>
                                            </td>
                                            <td class="border">
                                                <div class="info-products d-flex">
                                                    <span v-text="this.getTypeCampaign(entry.commission_rate_contributor, entry.commission_rate_partner, entries?.campaign_id)"></span>
                                                </div>
                                            </td>
                                            <td class="border border-top-0" :class="(index + 1) != entries?.details?.length ? 'border-bottom-0' : ''">
                                                <div class='info mt-1' v-if="index === 0">
                                                    <div class=''>{{ $t('marketingChannels') }}:</div>
                                                    <div class='right' v-text="entries?.marketing_channel?.name"></div>
                                                </div>
                                                <div class='info mt-1' v-if="index === 0">
                                                    <div class=''>{{ $t('orderType') }}:</div>
                                                    <div class='right' v-text="entries?.typeName"></div>
                                                </div>
                                            </td>
                                            <td class="border">
                                                <div class="info-products d-flex">
                                                    <span>{{ numberFormat(entry?.amount) }}</span>
                                                </div>
                                            </td>
                                            <td class="border">
                                                <div class="info-products d-flex">
                                                    <span v-text="numberFormat(entry?.commission_rate) + '%'"></span>
                                                </div>
                                            </td>
                                            <td class="border border-top-0" :class="(index + 1) != entries?.details?.length ? 'border-bottom-0' : ''">
                                                <div class='info mt-1 d-flex align-items-center' v-if="index === 0">
                                                    <div class='left'>{{ $t('paymentStatus') }}:</div>
                                                    <div class='right' v-text="entries?.status_payment"></div>
                                                </div>
                                                <div class='info mt-1 d-flex align-items-center' v-if="index === 0">
                                                    <div class='left'>{{ $t('paymentDate') }}:</div>
                                                    <div class='right' v-text="entries?.payment?.payment_date ? formatDateCTV(entries?.payment?.payment_date, 'DD/MM/YYYY') : '---'"></div>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                            <div id="noData" v-if="!entries || !entries?.details">
                                <span>{{ $t('noData') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div class='col-lg-12 d-flex justify-content-end'>
                    <div v-if="entries && entries?.details">
                        <div class='info-order mt-1 d-flex align-items-center' v-if="role == this.$constant.PERMISSIONS.ROLE_ADMIN">
                            <div class='left'>{{ $t('orderValues') }}:</div>
                            <div class='right' v-text="numberFormat(entries?.amount)"></div>
                        </div>
                        <div class='info-order mt-1 d-flex align-items-center'>
                            <div class='left'>{{ $t('commissionValue') }}:</div>
                            <div class='right'>
                                <span v-text="numberFormat(entries?.commission_value)"></span>
                            </div>
                        </div>
                        <div class="divider" v-if="role == this.$constant.PERMISSIONS.ROLE_ADMIN"></div>
                        <div class='info-order mt-1 d-flex align-items-center' v-if="role == this.$constant.PERMISSIONS.ROLE_ADMIN">
                            <div class='left'>{{ $t('orderValueAfterDiscount') }}:</div>
                            <div class='right' v-text="numberFormat(entries?.amount - entries?.commission_value)"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Loading :loading="isLoading" />
    </div>
</template>

<script>
import { numberFormat, formatDateCTV, getTypeCampaignName } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import { useRoute } from 'vue-router';
import { mapGetters } from 'vuex';

export default {
    name: 'OrderDetail',
    setup() {
        return { numberFormat, formatDateCTV, getTypeCampaignName };
    },
    components: {
        Loading,
    },
    data() {
        return {
            isLoading: false,
            order_id: null,
        }
    },
    mounted(){
        const route = useRoute();
        this.order_id = route?.params?.id ?? null;
    },
    computed: {
        ...mapGetters({
            entries: 'orderStore/orderItems',
            role: 'auth/userRole',
        })
    },
    watch: {
        order_id: {
            handler: function(newData) {                
                if(newData){
                    this.loadDataOrderItems();
                }
            },
            deep: true
        },
    },
    methods: {
        async loadDataOrderItems(){
            await this.$store.dispatch('orderStore/fetchOrderLinkItems', {order_id: this.order_id})
        },
        getTypeCampaign(commission_contributor, commission_partner, campaign_id){
            if((commission_contributor || commission_partner) && (commission_partner + commission_contributor > 0)){
                return getTypeCampaignName(campaign_id);
            }
            return null;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/orderDetail.scss";
</style>