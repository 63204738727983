<template>
    <div class='loading-overlay' v-if="this.isLoading">
        <i class='fas fa-spinner fa-spin'></i>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: "Loading",
    props: ['loading'],
    computed: {
        ...mapGetters(['isLoading'])
    }
}
</script>

<style scoped>
@import "../assets/scss/loading.scss";
</style>
