<template>
    <div id="input-select-search__form" v-click-outside="hideDropDown">
        <label v-if="label" v-text="label"></label>
        <input v-model.trim="model" @input="onSearch()" 
            :readonly="!!readonly"
            :class="[(errors && errors.length ? 'error-input' : ''), (append ? 'pr-30' : '')]"
            :placeholder="placeholder || label" 
            :maxlength="this.max ? this.max : ''" 
            :id="this.id"
            :type="type ? type : 'text'"
            :disabled="disabled"
            class="form-control form-select shadow-none"
            @click="showDropDown"
        >
        <template v-if="active">
            <div class="option-list" v-if="this.options?.length > 0">
                <template v-for="option in options" :key="option[optValue]">
                    <div class="option-item" role="button" :value="option[optValue]" @click="choseOption(option[optValue])">{{ $t(option[optLabel]) }}</div>
                </template>
            </div>
            <div class="option-list" id="noData" v-else>
                <span>{{ $t('noData') }}</span>
            </div>
        </template>
        <ul class="error-label" v-if="errors && errors.length">
            <li v-for="error in errors" :key="error" v-text="error"></li>
        </ul>
    </div>
</template>

<script>
import { debounce } from '@/utils/utils';
let app;

export default {
    name: "InputSelectSearch",
    props: ['label', 
        'placeholder', 
        'modelValue', 
        'errors', 
        'name', 
        'options', 
        'readonly', 
        'optionValue', 
        'optionLabel', 
        'defaultOption', 
        'defaultValue'
    ],
    methods: {
        onInput() {         
            this.$emit('searchData', this.model);
        },
        onSearch: debounce(async () => {
            app.onInput();
        }),
        hideDropDown() {            
            this.active = false;
        },
        showDropDown() {
            this.active = true;
        },
        choseOption(value) {            
            this.$emit('update:modelValue', value);
            let findEntry = this.options.find(item => item[this.optValue] == value);
            this.model = findEntry.name;
            this.active = false;
        },
        setModelValue(newValue){
            if (newValue) {
                let findEntry = this.options.find(item => item[this.optValue] == newValue);
                this.model = findEntry.name ?? null;
            }
        }
    },
    watch: {
        modelValue: function (newValue) {
            this.setModelValue(newValue);
        },
        defaultOption: {
            handler: function(newData) {                
                if(newData){
                    this.model = newData[this.model];
                    this.modelValue = newData[this.optValue];
                }
            },
            deep: true
        },
        defaultValue: function (newValue) {
            if (!newValue) {
                this.model = null;
            }else{
                this.setModelValue(newValue);
            }
        },
    },
    data() {
        app = this;
        const optValue = this.optionValue || 'id';
        const optLabel = this.optionLabel || 'name';
        let model = this.defaultOption ? this.defaultOption[optLabel] : null;
        let modelValue = this.defaultOption ? this.defaultOption[optValue] : null;
        return {
            model: model,
            modelValue: modelValue,
            optValue,
            optLabel,
            active: false,
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/Form/inputSelectSearch.scss";
</style>
