<template>
    <div id="input-month__form">
        <label v-if="label" v-text="label"></label>
        <div class="input-group">
            <input :value="formattedValue"
                :readonly="!!readonly"
                :class="[(errors && errors.length ? 'error-input' : ''), (append ? 'pr-30' : '')]"
                :placeholder="placeholder || label" 
                :id="this.id"
                :disabled="disabled"
                type="text"
                class="form-control input-text" >
            <input type="month" class="form-control input-month" :readonly="!!readonly" :disabled="disabled" v-model="model" id="icon-calendar" @change="onChange(true)"/>
        </div>
        <ul class="error-label" v-if="errors && errors.length">
            <li v-for="error in errors" :key="error" v-text="error"></li>
        </ul>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    name: "InputMonth",
    props: ['label', 'placeholder', 'modelValue', 'errors', 'readonly', 'id', 'type', 'disabled', 'append'],
    data() {
        return {
            model: this.modelValue,
            formattedValue: this.modelValue ? moment(this.modelValue).format('MM/YYYY') : null
        }
    },
    watch: {
        modelValue: function (newValue) {
            this.model = newValue;
            this.formattedValue = newValue ? moment(newValue).format('MM/YYYY') : null;
        }
    },
    methods: {
        onChange() {
            this.formattedValue = this.model ? moment(this.model).format('MM/YYYY') : null;
            this.$emit('update:modelValue', this.model);
            this.$nextTick(() => {
                this.$emit('change', this.model);
            })
        },
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/Form/inputMonth.scss";
</style>
