<template>
    <div class='modal fade' ref="ModalChoosePartner" id='ModalChoosePartner' aria-labelledby='ModalChoosePartner' aria-hidden='true'
        data-bs-backdrop='false' tabindex="-1">
        <div class='modal-dialog modal-xl'>
            <div class='modal-content'>
                <div class='modal-header'>
                    <h1 class='modal-title fs-5'>{{ $t('choosePartner') }}</h1>
                    <button type='button' class='btn-close' @click="closeModal()"></button>
                </div>
                <div class='modal-body'>
                    <div class='row mb-3'>
                        <div class="col-xl-9 col-md-8 col-sm-12">
                            <div class="row">
                                <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                                    <InputText :placeholder="$t('searchParnerName')" v-model="parentNameLocal"/>
                                </div>
                                <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                                    <InputSelect v-model="typeAccountLocal" :options="optionsTpeAcc" :placeholder="$t('accountType')"/>
                                </div>
                                <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                                    <InputNumber :placeholder="$t('numberOfCollaborators')" v-model="numberOfCollaboratorLocal"/>
                                </div>
                                <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                                    <InputSelect :placeholder="$t('numberOfOrders')" :options="optionsNumberOfOrders" v-model="numberOfOrderLocal"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-4 col-sm-12">
                            <button @click="searchPartner()" class="btn btn-info mx-1">
                                <span>{{ $t('search') }}</span>
                            </button>
                            <button class="btn btn-outline-secondary" @click="clearSearch()">
                                <span>{{ $t('clearFilter') }}</span>
                            </button>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-12'>
                            <div class="card">
                                <h5 class='card-title mb-2'>{{ $t('partnerLists') }}</h5>
                                <div class="table-responsive">
                                    <table class="table table-index table-bordered" >
                                        <thead class="thead-light border">
                                            <tr>
                                                <th>
                                                    <input @click=onCheckAll(!statusCheckAll) :checked="statusCheckAll" type="checkbox"/>
                                                </th>
                                                <th scope="col">{{ $t('partnerName') }}</th>
                                                <th scope="col">{{ $t('numberOfCollaborators') }}</th>
                                                <th scope="col">{{ $t('numberOfClicked') }}</th>
                                                <th scope="col">{{ $t('numberOfOrders') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody class="customtable" v-if="this.partners?.length > 0">
                                            <template v-for="(partner, index) in partners" :key="index">
                                                <tr>
                                                    <th>
                                                        <input type="checkbox" v-model="partnersActive" 
                                                            :value="partner" 
                                                            v-bind:id="partner.id"
                                                            :disabled="partnersOrigin.find((origin) => origin.id == partner.id)"
                                                            @click="partnersOrigin.find((origin) => origin.id == partner.id) ? '': changePartnersActive($event, partner)"
                                                        />
                                                    </th>
                                                    <td>
                                                        <p>{{ partner.name }}</p>
                                                    </td>
                                                    <td>{{ partner.affiliate_partners_count }}</td>
                                                    <td>{{ partner.quantity_click ?? 0 }}</td>
                                                    <td>{{ partner.quantity_order ?? 0 }}</td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                    <div id="noData" v-if="this.partners?.length == 0">
                                        <span>{{ $t('noData') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='row'>
                        <Paginate :value="paginate" :record="record"/>
                    </div>
                    <div class='row'>
                        <div class='d-flex justify-content-end align-items-center mt-3'>
                            <div>
                                <span class="text-warning">{{ numberFormat(this.partnersActive?.length) }}&nbsp;</span>
                                <span v-html="$t('maxNumberPartnerSelect')"></span>
                            </div>
                            <button class='btn btn-outline-secondary mx-2' @click="closeModal()">
                                {{ $t('cancel') }}
                            </button>
                            <button class='btn btn-warning mx-2' @click="confirmAddPartners()">
                                <span>
                                    {{ $t('confirm') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { numberFormat } from '@/utils/utils';
import InputText from '@/components/Form/InputText.vue';
import InputCheckbox from '@/components/Form/InputCheckbox.vue';
import InputSelect from '@/components/Form/InputSelect.vue';
import store from "@/store";
import Paginate from '@/components/Paginate.vue';
import toastr from 'toastr';
import InputNumber from '@/components/Form/InputNumber.vue'


export default {
    name: "ModalChoosePartner",
    setup() {
        return { numberFormat };
    },
    components: {
        InputText,
        InputCheckbox,
        InputSelect,
        Paginate,
        InputNumber
    },
    data(){
        return {
            partners: [],
            partnersActive: [],
            statusCheckAll: false,
            record: this.$constant.PAGE.record,
            paginate: {
                totalRecord: 0,
                currentPage: 1,
                lastPage: 1,
            },
            optionsTpeAcc: this.$constant.PAGE.typeAcc,
            optionsNumberOfOrders: this.$constant.PAGE.optionsNumberOfOrders,

            parentNameLocal: null,
            typeAccountLocal: null,
            numberOfCollaboratorLocal: null,
            numberOfOrderLocal: null,

            search: {
                parentName: null,
                typeAccount: null,
                numberOfCollaborator: null,
                numberOfOrder: null,
            },
            partnersOrigin: [],
            partnerIDnoSearch: []
        }
    },
    watch: {
        partnersActive: {
            handler: function(newData, oldData) {  
                this.checkAddPartner();        
            },
            deep: true
        },
        'paginate.currentPage': function () {                
            this.getDataAndFormat();
        },
    },
    computed: {
    },
    methods: {
        changePartnersActive(event, partner) {
            if (!this.partnersOrigin || !this.partnersOrigin.length) {
                let indexActive = this.partnersActive.findIndex((active) => active.id == partner.id);
                if(indexActive != -1){
                    this.partnersActive.splice(indexActive, 1);
                }else{
                    this.partnersActive.push(partner);
                }
                return;
            }
            let indexActive = this.partnersActive.findIndex((active) => active.id == partner.id);
            if (indexActive == -1) {
                this.partnersActive.push(partner);
            } else {
                let indexOrigin = this.partnersOrigin.findIndex((origin) => origin.id == partner.id);
                if (indexOrigin == -1) {
                    this.partnersActive.splice(indexActive, 1);
                }
            }
            return true;
        },
        closeModal() {
            this.clearDataSearch();
            window.jQuery(this.$refs.ModalChoosePartner).modal('hide');
        },
        clearSearch() {
            this.onCheckAll(false);
            this.clearDataSearch();
            this.getPartners();
        },
        clearDataSearch() {
            this.search.parentName = null;
            this.search.typeAccount = null;
            this.search.numberOfCollaborator = null;
            this.search.numberOfOrder = null;
            this.parentNameLocal = null;
            this.typeAccountLocal = null;
            this.numberOfCollaboratorLocal = null;
            this.numberOfOrderLocal = null;
            this.paginate.currentPage = 1;
        },
        async searchPartner() {
            this.search.parentName = this.parentNameLocal;
            this.search.typeAccount = this.typeAccountLocal;
            this.search.numberOfCollaborator = this.numberOfCollaboratorLocal;
            this.search.numberOfOrder = this.numberOfOrderLocal;
            this.getPartners();
        },
        async getDataAndFormat() {
            await this.getPartners();
            this.checkAddPartner();
        },
        checkAddPartner() {             
            if (!this.partners.length) {
                this.statusCheckAll = false;
                return 
            }    
            for (let index = 0; index < this.partners.length; index++) {
                const partner = this.partners[index];                
                let indexPar = this.partnersActive.findIndex(partnerActive => 
                    partnerActive.id == partner.id                    
                );                
                if (indexPar === -1) {
                    this.statusCheckAll = false;
                    return ;
                }
            }        
            this.statusCheckAll = true;
        },
        async show(partnersActive = [], partnersOrigin = []) {
            this.partnerIDnoSearch = partnersActive.map(item => item.id);
            await this.getPartners();
            window.jQuery(this.$refs.ModalChoosePartner).modal('show');            
            this.partnersActive = partnersActive;
            this.partnersOrigin = partnersOrigin;
        },
        async getPartners(type = this.$constant.PAGE.typeGetPartnerAdmin) {            
            const res = await store.dispatch('userStore/getPartners', {
                type: type,
                currentPage: this.paginate.currentPage,
                record: this.record,
                parentName: this.search.parentName,
                typeAccount: this.search.typeAccount,
                numberOfCollaborator: this.search.numberOfCollaborator,
                numberOfOrder: this.search.numberOfOrder,
                partnerIDnoSearch: this.partnerIDnoSearch
            });            
            if (res && res.data) {
                this.partners = res.data;
                this.paginate.currentPage = res.current_page;
                this.paginate.lastPage = res.last_page;
                this.paginate.totalRecord = res.total;
            }
        },
        confirmAddPartners() {            
            if(this.partnersActive?.length > 200){
                toastr.error(this.$t('max200'));
                return;
            }
            this.clearDataSearch();
            store.dispatch('userStore/addPartners', this.partnersActive);
            window.jQuery(this.$refs.ModalChoosePartner).modal('hide');
        },
        onCheckAll(statusCheckAll) {     
            this.statusCheckAll = statusCheckAll;
            if (statusCheckAll) {
                this.checkAllPage();
                this.statusCheckAll = true;
            } else {   
                this.checkPartPage();
                this.statusCheckAll = false;
            }
        },
        checkAllPage() {
            if (!this.partnersActive.length) {
                this.partnersActive = [...this.partners];
            } else {
                let partnersActiveCurrent = [...this.partnersActive]     
                for (let index = 0; index < this.partners.length; index++) {
                    const partner = this.partners[index];
                    let indexPar = partnersActiveCurrent.findIndex(partnerActive => partnerActive.id == partner.id);
                    if (indexPar === -1) {
                        partnersActiveCurrent.push(this.partners[index]);
                    }
                }        
                this.partnersActive = [...partnersActiveCurrent];
            }
        },
        checkPartPage() {
            let partnersActiveCurrent = [...this.partnersActive]     
            for (let index = 0; index < this.partners.length; index++) {
                const partner = this.partners[index];                
                let indexPar = partnersActiveCurrent.findIndex(partnerActive => 
                    partnerActive.id == partner.id                    
                );
                let indexParOrigin = this.partnersOrigin.findIndex(origin => 
                    origin.id == partner.id                    
                );
                if (indexPar !== -1 && indexParOrigin === -1 ) {
                    partnersActiveCurrent.splice(indexPar, 1);
                }
            }        
            this.partnersActive = [...partnersActiveCurrent];
        }
    },
    beforeUnmount(){
        this.closeModal();
    }
}
</script>

<style scoped lang="scss">
</style>
