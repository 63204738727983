<template>
    <div id="input-text-append__form">
        <div class="input-group">
            <input 
                type="text" 
                class="form-control" 
                autocomplete="off"
                :placeholder="placeholder"
                :readonly="!!readonly"
                :class="[(errors && errors.length ? 'error-input' : '')]"
                :maxlength="this.max ? this.max : ''" 
                :id="this.id"
                v-model.trim="model" @input="onInput(true)"
            >
            <div class="input-group-append">
            <span class="input-group-text" id="basic-addon2">%</span>
            </div>
        </div>
        <ul class="error-label" v-if="errors && errors.length">
            <li v-for="error in errors" :key="error" v-text="error"></li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "InputTextAppend",
    props: ['label', 'placeholder', 'modelValue', 'errors', 'readonly', 'max', 'id', 'iconAppend'],
    data() {
        return {
            model: this.modelValue,
        }
    },
    watch: {
        modelValue: function (newValue) {
            this.model = newValue;
        }
    },
    methods: {
        onInput() {
            this.$emit('update:modelValue', this.model);
            this.$nextTick(() => {
                this.$emit('input', this.model);
            })

        },
    }
}
</script>

<style scoped>
@import "../../assets//scss/Form/inputTextAppend.scss";
</style>
