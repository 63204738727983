<template>
    <div id="paginate" v-if="this.totalRecord > 0">
        <span class="d-flex align-items-center" v-if="this.record && this.totalRecord">
            {{ getCurrentRecord() }}
            / 
            {{ numberFormat(this.totalRecord) }} 
        </span>
        <ul class="pagination mb-0">
            <li class="page-item" @click="changePage(1)">
                <a href="javascript:;" class="page-link">‹‹</a>
            </li>
            <li class="page-item" @click="prevPage()">
                <a href="javascript:;" class="page-link">‹</a>
            </li>
            <li v-for="page in getPages()" :key="page.key" 
                @click="changePage(page.key)" 
                :class="page.class">
                <a class="page-link" href="javascript:;" v-text="page.key"></a>
            </li>
            <li class="page-item" @click="nextPage()">
                <a class="page-link" href="javascript:;">›</a>
            </li>
            <li class="page-item" @click="changePage(value.lastPage)">
                <a class="page-link" href="javascript:;">››</a>
            </li>
            <span class="d-flex align-items-center mx-3">
                {{ $t('goTo') }}
                <input type="number" class="input-page page-link" v-model="nextToPageValue" @keyup.enter="nextToPage()">
            </span>
        </ul>
    </div>
</template>

<script>
import { numberFormat } from '@/utils/utils';
import toastr from 'toastr'
export default {
    props: ['value', 'pagechange', 'record'],
    name: "Paginate",
    emits: ['pageChange'],
    setup() {
        return { numberFormat };
    },
    data() {
        return {
            model: this.value,
            totalRecord: this.value?.totalRecord,
            currentPage: this.value?.currentPage,
            lastPage: this.value?.lastPage,
            nextToPageValue: null,
        }
    },
    watch: {
        value: {
            handler(newValue) {
                this.model = newValue;
                this.totalRecord = newValue?.totalRecord;
                this.currentPage = newValue?.currentPage;
                this.lastPage = newValue?.lastPage;
                this.nextToPageValue = null;
            },
            deep: true,
        }
    },
    methods: {
        created: function () {
            this.pages = this.getPages();
        },
        getPages: function () {
            const $lastPage = this.value.lastPage;
            const $linkLimit = 7;
            const $currentPage = this.value.currentPage;

            const $halfTotalLinks = Math.floor($linkLimit / 2);
            let $from = $currentPage - $halfTotalLinks;
            let $to = $currentPage + $halfTotalLinks;
            if ($currentPage < $halfTotalLinks) {
                $to += $halfTotalLinks - $currentPage;
            }
            if ($lastPage - $currentPage < $halfTotalLinks) {
                $from -= $halfTotalLinks - ($lastPage - $currentPage) - 1;
            }

            const pages = [];

            for (let $i = 1; $i <= $lastPage; $i++) {
                if ($from < $i && $i < $to) {
                    let item = {
                        key: $i,
                        class: 'page-item'
                    };
                    if($i === $currentPage){
                        item = {
                            key: $i,
                            class: 'page-item active'
                        };
                    }
                    pages.push(item);
                }
            }

            return pages;
        },
        prevPage: function () {
            if (this.value.currentPage > 1) {
                this.model.currentPage--;
            }

            this.$emit('pageChange', this.value.currentPage);
        },
        nextPage: function () {

            if (this.value.currentPage < this.value.lastPage) {
                this.model.currentPage++;
            }

            this.$emit('pageChange', this.value.currentPage);
        },
        changePage: function (page) {
            this.model.currentPage = page;
            this.$emit('pageChange', this.value.currentPage);
        },
        getCurrentRecord(){
            let firstRecord = 0;
            if(this.currentPage === 1){
                firstRecord = 1;
            }else{
                firstRecord = Number(this.currentPage -1) * Number(this.record) + 1;
            }
            let lastRecord = 0;
            if(this.record > this.totalRecord){
                lastRecord = this.totalRecord;
            }else{
                lastRecord = this.record * this.currentPage > this.totalRecord ? this.totalRecord : this.record * this.currentPage;
            }
            return numberFormat(firstRecord) + ' - ' + numberFormat(lastRecord);
        },
        nextToPage(){
            if(this.nextToPageValue > this.value.lastPage){
                toastr.error(this.$t('notFoundPage'));
                return;
            }
            this.model.currentPage = +this.nextToPageValue;
            this.$emit('pageChange', +this.nextToPageValue);
        }
    }
}
</script>

<style scoped>
@import "../assets//scss/paginate.scss";
</style>
