<template>
    <div class='modal fade' ref="ModalCreateQuicklyCommission"  aria-hidden='true'
        data-bs-backdrop='false' tabindex="-1">
        <div class='modal-dialog modal-lg'>
            <div class='modal-content'>
                <div class='modal-header'>
                    <h1 class='modal-title fs-5'>{{ $t('createQuicklyCommission') }}</h1>
                    <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                </div>
                <div class='modal-body border'>
                    <div class='row mb-3'>
                        <div class='col-lg-12 col-md-12 col-sm-12'>
                            <label>{{$t('percentCommission')}}<span class="text-danger">*</span></label>
                            <InputTextAppend v-model="entry.commission" :errors="error ?? []" :iconAppend="'%'"/>
                        </div>
                    </div>
                    <div class='row'>
                        <div class='d-flex justify-content-center mt-3'>
                            <button class='btn btn-outline-secondary mx-2' @click="cancel()">
                                {{ $t('cancel') }}
                            </button>
                            <button class='btn btn-warning mx-2' @click="setRate()">
                                <span>
                                    {{ $t('confirm') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalConfirm 
        :title="$t('confirm')"
        :content="$t('confirmCancelChangeData')"
        ref="ModalConfirm"
        @confirm="hide"
    />
</template>

<script>
import InputTextAppend from '@/components/Form/InputTextAppend.vue';
import store from "@/store";
import ModalConfirm from '../ModalConfirm.vue';
export default {
    name: "ModalCreateQuicklyCommission",
    components: {
        InputTextAppend,
        ModalConfirm
    },
    data(){
        return {
            entry: {
                commission: '',
            },
            error: []
        }
    },
    watch: {
    },
    methods: {
        cancel() {
            if(this.entry.commission){
                this.$refs.ModalConfirm.show();
                return;
            }
            this.hide();
        },
        hide(){
            this.entry.commission = null;
            window.jQuery(this.$refs.ModalCreateQuicklyCommission).modal('hide');
        },
        show(){
            this.error = [];
            this.entry.commission = null;
            window.jQuery(this.$refs.ModalCreateQuicklyCommission).modal('show');
        },
        setRate() {
            if(!this.entry.commission){
                this.error = [this.$t('required')];
                return;
            }
            store.dispatch('campaignStore/setRateQuick', this.entry.commission);
            window.jQuery(this.$refs.ModalCreateQuicklyCommission).modal('hide');
        }
    }
}
</script>

<style scoped lang="scss">
</style>
