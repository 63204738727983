<template>
    <div id="input-select__form">
        <label v-if="label" v-text="label"></label>
        <select v-model="model" 
            @change="onInput" 
            :class="[(errors && errors.length ? 'error-input' : '')]"
            :disabled="readonly" class="form-control form-select shadow-none">
            <option v-if="placeholder" :value="null">{{ placeholder }}</option>
            <option v-if="defaultOption" v-text="$t(defaultOption[optLabel])" :value="defaultOption[optValue]"></option>
            <template v-for="option in options" :key="option[optValue]">
                <option :value="option[optValue]">{{ $t(option[optLabel]) }}</option>
            </template>
        </select>
        <ul class="error-label" v-if="errors && errors.length">
            <li v-for="error in errors" :key="error" v-text="error"></li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "InputSelect",
    props: ['label', 'placeholder', 'modelValue', 'errors', 'name', 'options', 'readonly', 'optionValue', 'optionLabel', 'defaultOption'
    ],
    methods: {
        onInput() {
            this.$emit('update:modelValue', this.model);
            let findEntry = this.options.find(item => item.id == this.model);
            this.$emit('update:defaultValue', findEntry);
            this.$nextTick(() => {
                this.$emit('input', this.model);
            })
        }
    },
    watch: {
        modelValue: function (newValue) {
            this.model = newValue;
        }
    },
    mounted() {
        if (this.defaultOption && this.modelValue === null) {
            this.$emit('update:modelValue', this.model);
            this.$nextTick(() => {
                this.$emit('input', this.model);
            })
        }
    },
    data() {
        const optValue = this.optionValue || 'id';
        const optLabel = this.optionLabel || 'name';
        const model = this.modelValue === undefined ? (this.defaultOption ? this.defaultOption[optValue] : null) : this.modelValue;
        return {
            model: model,
            optValue,
            optLabel,
        }
    }
}
</script>

<style scoped>
@import "../../assets/scss/Form/inputSelect.scss";
</style>
