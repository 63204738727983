<template>
    <div class='modal fade' ref="ModalCreateOpenCampaign" id='ModalCreateOpenCampaign' aria-labelledby='ModalCreateOpenCampaign' aria-hidden='true'
        data-bs-backdrop='false' tabindex="-1">
        <div class='modal-dialog modal-lg'>
            <div class='modal-content'>
                <div class='modal-header'>
                    <h1 class='modal-title fs-5'>{{ $t('addInCampaign') }}</h1>
                    <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                </div>
                <div class='modal-body border'>
                    <div class='row'>
                        <div class='col-lg-12 col-md-12 col-sm-12'>
                            <label>{{$t('percentCommission')}}<span class="text-danger">*</span></label>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-6 col-md-6 col-sm-12'>
                            <div class="form-group row">
                                <label class="col-sm-4 control-label col-form-label">
                                    {{ $t('partner') }}:
                                </label>
                                <div class="col-sm-8">
                                    <InputTextAppend :errors="this.errors.commission_rate_partner ?? []" v-model="entry.commission_rate_partner" :iconAppend="'%'"/>
                                </div>
                            </div>
                        </div>
                        <div class='col-lg-6 col-md-6 col-sm-12'>
                            <div class="form-group row">
                                <label class="col-sm-4 control-label col-form-label">
                                    {{ $t('collaborator') }}:
                                </label>
                                <div class="col-sm-8">
                                    <InputTextAppend :errors="this.errors.commission_rate_contributor ?? []" v-model="entry.commission_rate_contributor" :iconAppend="'%'"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-6 col-md-12 col-sm-12'>
                            <label>{{$t('promotionPeriod')}}<span class="text-danger">*</span></label>
                            <div class="d-flex">
                                <SingleDaterangepicker 
                                    :placeholder="$t('startTime')" 
                                    v-model="entry.start_time" :class="!setEndTime ? 'w-100' : ''"
                                    :errors="this.errors.start_time ?? []"
                                />
                                <SingleDaterangepicker 
                                    :placeholder="$t('endTime')" 
                                    v-model="entry.end_time" 
                                    v-if="setEndTime"
                                    :errors="this.errors.end_time ?? []"
                                    class="mx-2"/>
                            </div>
                        </div>
                        <div class='col-lg-6 col-md-12 col-sm-12 d-flex align-items-end'>
                            <InputCheckbox :label="$t('setEndTime')" @update:modelValue="setEndTime" v-model="setEndTime"/>
                        </div>
                    </div>
                    <div class='row'>
                        <div class='d-flex justify-content-center mt-3'>
                            <button @click="clearDataAndHideModal()" class='btn btn-secondary mx-2'>
                                {{ $t('cancel') }}
                            </button>
                            <button @click="addProductOpenCampaign()" class='btn btn-primary mx-2'>
                                <span>
                                    {{ $t('add') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputText from '@/components/Form/InputText.vue';
import SingleDaterangepicker from '@/components/SingleDaterangepicker.vue';
import InputCheckbox from '@/components/Form/InputCheckbox.vue';
import InputTextAppend from '@/components/Form/InputTextAppend.vue';
import store from "@/store";
import { mapGetters } from 'vuex';
import toastr from 'toastr';
import moment from 'moment';


export default {
    name: "ModalCreate",
    components: {
        InputText,
        InputCheckbox,
        SingleDaterangepicker,
        InputTextAppend
    },
    data(){
        const now = moment().format('YYYY-MM-DD HH:mm:ss')
        return {
            entry: {
                commission_rate_partner: 0,
                commission_rate_contributor: 0,
                start_time: now,
                end_time: null,
            },
            setEndTime: false,
            errors: {},
        }
    },
    watch: {
        setEndTime(newValue){
            if (!newValue) {
                this.entry.end_time = null;
            }
        }
    },
    computed: {
        ...mapGetters({
            product_codes: 'campaignStore/product_codes',
        }),
    },
    methods: {
        async show() {          
            this.errors = {};  
            this.entry.start_time = moment().format('YYYY-MM-DD HH:mm:ss');
            window.jQuery(this.$refs.ModalCreateOpenCampaign).modal('show');
        },
        async addProductOpenCampaign() {        
            this.errors = {};    
            const afterToday = moment(this.entry.start_time, 'YYYY-MM-DD HH:mm:ss').isAfter(moment().format('YYYY-MM-DD 23:59:59'));
            const start_time = afterToday ? this.entry.start_time : moment(this.entry.start_time).add(10, 'minutes').format('YYYY-MM-DD HH:mm:ss');
            const res = await store.dispatch('campaignStore/addProductOpenCampaign', {
                commission_rate_partner: this.entry.commission_rate_partner,
                commission_rate_contributor: this.entry.commission_rate_contributor,
                product_codes: this.product_codes,
                start_time: start_time,
                end_time: this.entry.end_time,
            });
            if (!res || res.status === 500) {
                toastr.error('error server');
            } else if (res.status === 422) {
                this.errors = res.response;
            } else if (res.status === 200) {
                toastr.success(res.result.message);
                this.clearDataAndHideModal();
            }
        },
        clearDataAndHideModal() {
            this.entry.commission_rate_partner = 0;
            this.entry.commission_rate_contributor = 0;
            this.entry.start_time = null;
            this.entry.end_time = null;
            this.setEndTime = false,
            store.dispatch('campaignStore/addProductCode', []);
            window.jQuery(this.$refs.ModalCreateOpenCampaign).modal('hide');
            store.dispatch('campaignStore/loadDataIndex', true);
        }
    },
}
</script>

<style scoped lang="scss">
</style>
