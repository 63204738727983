<template>
    <div id="input-text__form">
        <label v-if="label" v-text="label"></label>
        <input v-model.trim="model" @input="onInput(true)" :readonly="!!readonly"
            :class="[(errors && errors.length ? 'error-input' : ''), (append ? 'pr-30' : '')]"
            :placeholder="placeholder || label" :maxlength="this.max ? this.max : ''" id="inputText"
            :type="type ? type : 'text'" :disabled="disabled" class="form-control" autocomplete="off">
        <ul class="error-label" v-if="errors && errors.length">
            <li v-for="error in errors" :key="error" v-text="error"></li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "InputText",
    props: ['label', 'placeholder', 'modelValue', 'errors', 'readonly', 'max', 'id', 'type', 'disabled', 'append'],
    data() {
        return {
            model: this.modelValue,
        }
    },
    watch: {
        modelValue: function (newValue) {
            this.model = newValue;
        }
    },
    methods: {
        onInput() {
            this.$emit('update:modelValue', this.model);
            this.$nextTick(() => {
                this.$emit('input', this.model);
            })
        }
    }
}
</script>

<style scoped>
@import "../../assets//scss/Form/inputText.scss";
</style>
