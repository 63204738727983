import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './locales/i18nHelper'
import CONSTANT from './common/constant'
import { Chart, registerables } from "chart.js"
import vue3GoogleLogin from 'vue3-google-login'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import filters from '@/filters/index';
import appMixin from "@/mixin";

Chart.register(...registerables);
library.add(fas);
const app = createApp(App);
app.mixin({
    methods: appMixin.methods
});
const clickOutside = {
    beforeMount: (el: any, binding: any) => {
        el.clickOutsideEvent = (event: any) => {
            // here I check that click was outside the el and his children
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                binding.value();
            }
        };
        document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: (el: any) => {
        document.removeEventListener("click", el.clickOutsideEvent);
    },
};



app.directive("click-outside", clickOutside)
app.config.globalProperties.$constant = CONSTANT;
app.config.globalProperties.$filters = filters;
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(vue3GoogleLogin, {
    clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID
})
app.use(i18n);
app.use(router);
app.use(store);
app.mount('#app');
