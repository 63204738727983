<template>
    <div id="input-radio__form">
        <div class="row d-flex">
            <div class="col-12 d-flex align-items-center">
                <label class="container-radio ml-2 w-100">{{ this.label }}
                    <input type="radio" :name="name" class="mr-2" :value="value" :checked="isChecked" @change="handleChange">
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InputRadio",
    components: {},
    props: ['label', 'placeholder', 'modelValue', 'errors', 'value', 'name'],
    computed: {
        isChecked() {
        return this.modelValue == this.value;
        }
    },
    methods: {
        handleChange() {
            this.$emit('update:modelValue', this.value);
        }
    }
}
</script>

<style scoped>
@import "../../assets/scss/Form/inputRadio.scss";
</style>
