import { createStore } from 'vuex'
import productStore from './modules/productStore'
import campaignStore from './modules/campaignStore'
import marketingChannels from './modules/marketingChannelStore'
import auth from './modules/authStore'
import userStore from './modules/userStore'
import partners from './modules/partnerStore'
import productLinks from './modules/productLinkStore'
import payment from './modules/paymentStore'
import uploadFile from './modules/uploadStore'
import collaborators from './modules/collaboratorStore'
import exportStore from './modules/exportStore'
import orderStore from './modules/orderStore'
import performanceStore from './modules/performanceStore'
import noticeStore from './modules/noticeStore'

export default createStore({
    state: {
        isLoading: false,
        isShowModalExport: false,
        fileNameExport: null,
        statusClearFilter: false,
    },
    getters: {
        isLoading(state): boolean {
            return state.isLoading;
        },
        isShowModalExport(state): boolean {
            return state.isShowModalExport;
        },
        fileNameExport(state): any {
            return state.fileNameExport;
        },
        statusClearFilter(state): any {
            return state.statusClearFilter;
        },
    },
    mutations: {
        setLoading(state, isLoading) {
            state.isLoading = isLoading
        },
        setShowModalExport(state, isShowModalExport) {
            state.isShowModalExport = isShowModalExport
        },
        setFileNameExport(state, fileNameExport) {
            state.fileNameExport = fileNameExport
        },
        setClearFilter(state, status) {
            state.statusClearFilter = status
        }
    },
    actions: {
        setLoading({ commit }, isLoading) {
            commit('setLoading', isLoading);
        },
        setShowModalExport({ commit }, isShowModalExport) {
            commit('setShowModalExport', isShowModalExport);
        },
        setFileNameExport({ commit }, fileNameExport) {
            commit('setFileNameExport', fileNameExport);
        },
        setClearFilter({ commit }, status) {
            commit('setClearFilter', status);
        },
    },
    modules: {
        auth,
        marketingChannels,
        productStore,
        campaignStore,
        userStore,
        partners,
        productLinks,
        payment,
        uploadFile,
        collaborators,
        exportStore,
        orderStore,
        performanceStore,
        noticeStore
    }
})
