<template>
    <button class="btn btn-secondary mx-3" @click="goBack">
        <span>
            {{ $t(label) }}
        </span>
    </button>
</template>

<script>
export default {
    name: "GoBack",
    props: ['label'],
    methods: {
        goBack() {
            this.$router.go(-1);
        }
    }
}
</script>

<style scoped lang="scss">
</style>
