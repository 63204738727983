<template>
    <div class='modal fade' ref="ModalRemoveCollaborator" id='ModalRemoveCollaborator' aria-labelledby='ModalRemoveCollaborator' aria-hidden='true'
        data-bs-backdrop='false' tabindex="-1">
        <div class='modal-dialog modal-md'>
            <div class='modal-content'>
                <div class='modal-header border-bottom-0 pb-0'>
                    <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                </div>
                <div class='modal-body pt-0'>
                    <div class='row mb-3'>
                        <div class='col-lg-12 col-md-12 col-sm-12 text-center'>
                            <label class="modal-title">{{ $t('confirmRemoveCollaborator') }}</label>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-12 col-md-12 col-sm-12 text-center'>
                            <span>{{ $t('contentConfirmRemoveCollaborator') }}</span>
                        </div>
                    </div>
                    <div class='row mt-3'>
                        <div class='d-flex justify-content-center mt-3'>
                            <button class='btn btn-secondary mx-2' @click="hide">
                                {{ $t('cancel') }}
                            </button>
                            <button class='btn btn-primary mx-2' @click="remove">
                                <span>
                                    {{ $t('confirm') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import toastr from 'toastr';
export default {
    name: "ModalRemoveCollaborator",
    computed: {
        ...mapGetters({
            entry: 'collaborators/getDetail'
        })
    },
    methods: {
        show(){
            window.jQuery(this.$refs.ModalRemoveCollaborator).modal('show');
        },
        hide(){
            this.$store.dispatch('collaborators/setDetail', {});
            window.jQuery(this.$refs.ModalRemoveCollaborator).modal('hide');
        },
        async remove(){
            if(!this.entry?.id){
                return;
            }
            const res = await this.$store.dispatch('collaborators/removeCollaborator', {id: this.entry.id});
            
            if(res?.error){
                toastr.error(res.data);
                return;
            }else{
                toastr.success(this.$t('removedSuccess'));
                this.$store.dispatch('collaborators/setReload', true);
                this.hide();
            }
        }
    }
}
</script>

<style scoped lang="scss">
// @import "../../../assets/scss/Modal/ModalRemovePartner.scss";
</style>
