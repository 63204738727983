import { Module } from 'vuex';
import { Networks } from '@/services/Networks';
import download from '@/services/download';
interface ExportState {}

const state: ExportState = {};

const exportStore: Module<ExportState, any> = {
    namespaced: true,
    state,
    mutations: {},
    actions: {
        async downloadFile({ commit }, fileName) {
            try {
                const res:any = await download.post(Networks.downloadFile.affiliate_service, {'fileName': fileName});
                var _blob = new Blob([res], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(_blob);
                link.setAttribute('download', fileName);
                link.click();
            } catch (error: any) {
                return {};
            }
        },
    },
    getters: {},
};

export default exportStore;
