<template>
    <div id="input-file__form">
        <input @change="fileChanged" type='file' class="d-none" ref="inputUpload" :accept="fileTypes"/>
        <button class="btn btn-primary" @click="clickUpload" v-if="!this.readonly">
            <span>{{ $t('selectFile') }}</span>
        </button>
        <div v-if="this.files && !this.hidden" class="images">
            <template v-if="this.multiple">
                <ul>
                    <li v-for="(file,index) in this.files" :key="index">
                        <span v-if="image">
                            <a :href="file?.url ? file?.url : file" target='_blank'>
                                <img :src="file?.url ? file?.url : file"/>
                            </a>
                            <i class="fa fa-times text-danger" @click="remove(index)" v-if="!this.readonly"></i>
                        </span>
                        <span v-else>
                            <a :href="file?.url ? file?.url : file" target='_blank'>{{ file?.url ? file?.url : file }}</a>
                            <i class="fa fa-times text-danger" @click="remove(index)" v-if="!this.readonly"></i>
                        </span>
                    </li>
                </ul>
            </template>
            <template v-else>
                <ul>
                    <li>
                        <span v-if="image">
                            <div v-if="this.files && this.files?.length">
                                <a :href="this.files?.url ? this.files?.url : this.files" target='_blank' >
                                    <img :src="this.files?.url ? this.files?.url : this.files"/>
                                </a>
                                <i class="fa fa-times text-danger" @click="remove" v-if="!this.readonly"></i>
                            </div>
                        </span>
                        <span v-else>
                            <div v-if="this.files">
                                <a :href="this.files?.url ? this.files?.url : this.files" target='_blank'>{{ this.files?.url ? this.files?.url : this.files }}</a>
                                <i class="fa fa-times text-danger" @click="remove" v-if="!this.readonly"></i>
                            </div>
                        </span>
                        
                    </li>
                </ul>
            </template>
        </div>

        <ul class="error-label" v-if="arrayErrors && arrayErrors.length">
            <li v-for="error in arrayErrors" :key="error" v-text="error"></li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "InputFile",
    emits: ['update:modalValue'],
    props: ['modelValue', 'image', 'multiple', 'errors', 'readonly', 'type', 'hidden'],
    data() {
        return {
            files: this.modelValue ?? [],
            fileTypes: this.type ? this.type : 'image/*',
            arrayErrors: this.errors ? this.errors : [],
        }
    },
    watch: {
        modelValue: function (newValue) {
            this.files = newValue;
        },
        files: function (newFiles) {
            this.$emit('update:modalValue', newFiles);
        },
        errors: function (newErrors) {
            this.arrayErrors = newErrors;
        },
    },
    methods: {
        clickUpload(){
            this.$refs.inputUpload.value = '';
            this.$refs.inputUpload.click();
        },
        async fileChanged(event){
            const file = event.target.files[0];
            if(file && !this.checkFileType(file)){
                this.arrayErrors = [this.$t('invalidFormatFile')];
                return;
            }
            const res = await this.$store.dispatch('uploadFile/upload', file);
            if(this.multiple){
                this.files = [...this.files, res?.data[0]]
                return;
            }
            this.files = res?.data[0];
        },
        remove(index = 0){
            if(this.multiple){
                this.files.splice(index, 1);
                return;
            }
            this.files = '';
        },
        checkFileType(file){
            const allowedTypes = this.fileTypes.split(',').map(type => type.trim());
            const fileType = file.type;
            const isFileAllowed = allowedTypes.some(allowedType => {
                if (allowedType === 'image/*') {
                    return fileType.startsWith('image/');
                }
                return fileType === allowedType || file.name.endsWith(allowedType);
            });
            return isFileAllowed;
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../assets//scss/Form/inputFile.scss";
</style>
