<template>
    <div id="register-page">
        <div class="row justify-content-center">
            <div class="register-page__form">
                <div class="logo">
                    <img src="../../../public/logo.svg" />
                </div>
                <div class="form-title">
                    <h3 class="">{{ $t('forgetPassword') }}</h3>
                    <router-link :to="this.$constant.ROUTER.login" class="text-green text-decoration-none">
                        <label>
                            {{ $t('login') }}
                        </label>
                    </router-link>
                </div>
                <div class="row content p-3">
                    <div class="col-xl-7 col-md-7 col-sm-12">
                        <img src="../../../public/logo.png" class="w-100" />
                    </div>
                    <div class="col-xl-5 col-md-5 col-sm-12">
                        <form @submit="forgetPassword">
                            <div class="form-content">
                                <div class="form-group">
                                    <label>{{ $t('emailAdress') }}</label>
                                    <InputText :placeholder="$t('enterEmail')" v-model="model.email" :errors="this.errors.email ?? []"/>
                                </div>
                                <div class="form-group">
                                    <label>{{ $t('verifyCodeEmail') }}</label>
                                    <div class="send-code__group">
                                        <InputText :placeholder="$t('enterVerifyCode')" v-model="model.verify_code" :errors="this.errors.verify_code ?? []" class="register-form__input input-send-code"/>
                                        <button class="btn btn-success btn-send-code" type="button" @click="sendCode">{{ $t('sendCode') }}</button>
                                    </div>
                                </div>
                                <div class="form-group position-relative">
                                    <label>{{ $t('password') }}</label>
                                    <InputText :placeholder="$t('enterPassword')" v-model="model.password" :append="true" :type="this.showPassword ? 'text' : 'password'" :errors="this.errors.password ?? []"/>
                                    <i :class="this.showPassword ? 'fa fa-eye-slash eye-icon-slash' : 'fa fa-eye eye-icon'"
                                        @click="togglePasswordType('showPassword')"></i>
                                </div>
                                <div class="form-group position-relative">
                                    <label>{{ $t('confirmPassword') }}</label>
                                    <InputText :placeholder="$t('confirmPassword')" v-model="model.confirm_password" :append="true" :type="this.showConfirmPassword ? 'text' : 'password'" :errors="this.errors.confirm_password ?? []"/>
                                    <i :class="this.showConfirmPassword ? 'fa fa-eye-slash eye-icon-slash' : 'fa fa-eye eye-icon'"
                                        @click="togglePasswordType('showConfirmPassword')"></i>
                                </div>
                                <button type="submit" class="btn btn-primary">{{ $t('Đặt lại') }}</button>
                                <div class="divider">
                                    <span>{{ $t('or') }}</span>
                                </div>
                                <LoginGoogle class="btn btn-outline-secondary"/>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import InputText from '@/components/Form/InputText.vue';
import toastr from 'toastr';
import LoginGoogle from '@/components/LoginGoogle.vue';
export default {
    name: 'ForgetPassword',
    components: {InputText, LoginGoogle},
    data() {
        return {
            model: {
                email: '',
                verify_code: '',
                password: '',
                confirm_password: '',
            },
            showPassword: false,
            showConfirmPassword: false,
            errors: {},
            content: null,
        }
    },
    mounted() {
    },
    methods: {
        clearData(){
            this.model = {
                email: '',
                verify_code: '',
                password: '',
                confirm_password: '',
            }
        },
        togglePasswordType(item) {
            this[item] = !this[item]
        },
        async sendCode(){
            this.errors = {};
            let params = {
                "type": "email",
                "email": this.model.email,
                "verify_type": "forget_password"
            }
            const res = await this.$store.dispatch('auth/verify', params);
            if(res?.code === 500 && res?.error){
                if(res?.message){
                    toastr.error(res?.message)
                }else if(res?.messages){
                    this.errors = res?.messages;
                }
                return;
            }else if(res?.code === 200){
                toastr.success(res?.message)
            }
        },
        async forgetPassword(e){
            e.preventDefault();
            this.errors = {};
            const res = await this.$store.dispatch('auth/forgetPassword', this.model);
            if(res?.code === 500 && res?.error){
                this.errors = res?.messages;
                return;
            }
            if(res?.code === 200){
                toastr.success(this.$t('changePasswordSuccess'))
                this.$router.push({ name: this.$constant.ROUTER.loginName});
            }
        }
    }
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/register.scss";
</style>