import moment from 'moment';
import i18n from '@/locales/i18nHelper'

export default { 
    formatStartTimeAndEndTime(startTime: string , endTime: string | null) {  
        
        let time = '';
        if (startTime) {
            time = time + moment(startTime).format('HH:mm  DD/MM/YYYY');
        } 
        if (endTime) {
            time = time + ' - '+ moment(endTime).format('HH:mm  DD/MM/YYYY');
        } else {
            time = time + ' - ' + i18n.global.t("unlimited");
        }
        return time;
    },
    formatPrice(number: string, label = '',) {
        if (number) {
            const locale = 'ja-JP';
            const options = {
                style: 'currency',
                currency: 'JPY',
            };
            const formatter = new Intl.NumberFormat(locale, options);

            return formatter.formatToParts(+number).map(({ type, value }) => {
                switch (type) {
                case 'currency': return `${label}`;
                default: return value;
                }
            }).reduce((string, part) => string + part);
        }
        return 0;
    },
    

    formatStatusCampaign(status: number) {
        if (status == 0) {
            return i18n.global.t("campaignStatusDraft");
        } else if (status == 1) {
            return i18n.global.t("campaignStatusActive");
        } else if (status == 2) {
            return i18n.global.t("campaignStatusClose");
        }
        return '';
    },

    formatStatusCampaignPartner(status: number, startTime: string | Date = '', endTime: string | Date = '') {
        const now = new Date(); // Lấy thời gian hiện tại

        // Kiểm tra nếu startTime là string, chuyển đổi nó thành Date
        if (typeof startTime === 'string' && startTime) {
            startTime = new Date(startTime); // Chuyển đổi startTime thành kiểu Date
        }
        // Kiểm tra nếu endTime là string, chuyển đổi nó thành Date
        if (typeof endTime === 'string' && endTime) {
            endTime = new Date(endTime); // Chuyển đổi endTime thành kiểu Date
        }
    
        switch (status) {
            case 0:
                return i18n.global.t("campaignStatusDraft");
            case 1:
                // Nếu startTime > ngày hiện tại, hiển thị "Sắp diễn ra"
                if (startTime && startTime > now) {
                    return `${i18n.global.t("campaignStatusComingSoon")}`;
                }
                if (endTime && endTime < now) {
                    return i18n.global.t("campaignStatusClose");
                }
                // Nếu chiến dịch đang active và có thời gian start và end
                if (startTime && endTime) {
                    return `${i18n.global.t("campaignStatusActive")}`;
                }
                return i18n.global.t("campaignStatusActive");
            case 2:
                return i18n.global.t("campaignEnd");
            default:
                return '';
        }
    },

    formatRangerRate(objMax: any, objMin: any) {
        if (!objMax || !objMin) {
            return 0 + ' %';
        }
        let rate = objMin.commission_rate_partner;
        if (objMax && objMax.commission_rate_partner > rate) {
            return rate + ' - ' + objMax.commission_rate_partner +' % ';
        } 
        if (!rate){
            return;
        }
        return (rate +' % ');
    },

    formatRangerRateContributor(objMax: any, objMin: any) {
        if (!objMax || !objMin) {
            return 0 + ' %';
        }
        let rate = objMin.commission_rate_contributor;
        if (objMax && objMax.commission_rate_contributor > rate) {
            return rate + ' - ' + objMax.commission_rate_contributor +' % ';
        } 
        if (!rate){
            return 0 + ' %';
        }
        return (rate +' % ');
    },

    formatRangerRateContributorNew (max: any, min: any){
        if (min == null || max == null) {
            return 0 + ' %';
        }
        let rate = min;
        if (max > rate) {
            return rate + ' - ' + max +' % ';
        } 
        if (rate < 0 || rate == null){
            return;
        }
        return (rate +' % ');
    },

    formatStatusProduct(status: number) {
        if (!status) {
            return i18n.global.t("productInActive");
        } else {
            return i18n.global.t("productActive");
        }
    },
    
    formatStatusOpenProduct(startTime: string , endTime: string | null, statusRemove: any) {
        if (statusRemove) {
            return i18n.global.t("stopping");
        } else if (startTime > moment().format('YYYY-MM-DD HH:mm')) {
            return i18n.global.t("campaignStatusComingSoon");
        } else if ((!endTime || endTime > moment().format('YYYY-MM-DD HH:mm')) &&  startTime < moment().format('YYYY-MM-DD HH:mm')) {
            return i18n.global.t("campaignStatusActive");
        } else if (endTime && endTime < moment().format('YYYY-MM-DD HH:mm')) {
            return i18n.global.t("campaignStatusClose");
        }
    },

    compareFormatRangerRate(value1: any, value2: any) {
        if (value1 == value2) {
            return value2 ?? 0;
        } else if (value1 > value2) {
            return (value2 ?? 0) + ' - '+value1; 
        } else {
            return (value1 ?? 0) + ' - '+value2; 
        }
    },

    formatMarketingChannelStatus(status: number) {
        if (!status) {
            return i18n.global.t("inactive");
        }
        return i18n.global.t("active");
    },

    formatDateTime(time: any) {
        if (time) {
            return moment(time).format('HH:mm  DD/MM/YYYY');
        }
        return null;
    },

    formatNegativeNumber(number: any) {
        if (number) {
            return 0 - +number;
        }
        return 0;
    },
}