<template>
    <div id="target-campaign-page">
        <div class='container-fluid p-3'>
            <div class='row'>
                <div class="col-xl-8 col-md-8 col-sm-12">
                    <div class="row">
                        <div class="col-xl-4 col-md-6 col-sm-12 mb-2">
                            <InputText :placeholder="$t('campaignCode')" v-model.trim="campaignCodeLocal"/>
                        </div>
                        <div class="col-xl-4 col-md-6 col-sm-12 mb-2">
                            <InputSelect :placeholder="$t('status')" v-model="statusLocal" :options="$constant.CAMPAIGN.listStatusPartner"/>
                        </div>
                        <div class="col-xl-4 col-md-6 col-sm-12 mb-2">
                            <Daterangepicker :placeholder="$t('fromDateToDate')" v-model="fromDateToDateLocal"/>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-4 col-sm-12">
                    <button @click="searchData()" class="btn btn-primary mx-2">
                        <span>{{ $t('search') }}</span>
                    </button>
                    <button @click="clearSearch()" class="btn btn-secondary mx-2">
                        <span>{{ $t('clearFilter') }}</span>
                    </button>
                </div>
            </div>
            <div class='row mb-3'>
                <div class='col-xl-12 col-md-12 col-sm-12 d-flex justify-content-end'>
                    <!-- <button type='submit' class='btn btn-primary mx-2'>
                        <router-link :to="this.$constant.ROUTER.createTargetCampaign" class="text-white">
                            <i class="fa fa-plus mx-2"></i>
                            <span class="mx-2">
                                {{ $t('createNewCampaign') }}
                            </span>
                        </router-link>
                    </button> -->
                </div>
            </div>
            <div class='row'>
                <div class='col-lg-12'>
                    <div class="card">
                        <h5 class='card-title mb-2'>{{ $t('sum') }} {{ numberFormat(entries?.length) }}</h5>
                        <div class="table-responsive">
                            <table class="table">
                                <thead class="thead-light border">
                                    <tr>
                                        <th scope="col">{{ $t('campaignName') }}</th>
                                        <th scope="col">{{ $t('status') }}</th>
                                        <th scope="col">{{ $t('time') }}</th>
                                        <th scope="col">{{ $t('product') }}</th>
                                        <th scope="col">{{ $t('percentCommission') }}</th>
                                        <th scope="col" class="text-center">{{ $t('action') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="customtable">
                                    <template v-for="(entry, index) in entries" :key="index">
                                        <tr>
                                            <td>
                                                <p>{{ entry.campaign?.name }}</p>
                                                <p>
                                                    {{ $t('code') }}: {{ entry.campaign?.code }}
                                                </p>
                                            </td>
                                            <td>
                                                <span v-if="entry?.target_campaign_partner?.status" class="label-secondary">
                                                    {{ $t('campaignEnd') }}
                                                </span>
                                                <span v-else :class="getClassForTargetCampaignStatus($filters.formatStatusCampaignPartner(entry.campaign?.status, entry?.target_campaign_partner?.start_time || entry?.start_time, entry?.target_campaign_partner?.end_time || entry?.end_time))">
                                                    {{ $filters.formatStatusCampaignPartner(entry.campaign?.status, entry?.target_campaign_partner?.start_time || entry?.start_time, entry?.target_campaign_partner?.end_time || entry?.end_time) }}
                                                </span>
                                            </td>
                                            <td>{{ $filters.formatStartTimeAndEndTime(( entry?.start_time || entry?.campaign?.start_time ),
                                                (entry?.end_time ||  entry?.campaign?.end_time)) }}</td>
                                           
                                            <td class="info-products">
                                                <div class="image d-flex align-items-center"
                                                    v-if="entry?.campaign?.products && entry?.campaign?.products.length > 2">
                                                    <template v-for="(product, index) in entry?.campaign?.products"
                                                        :key="index">
                                                        <img v-if="index <= 1"
                                                            :src="product.main_image ?? image_default" />
                                                    </template>
                                                    <div class="count-product">+{{ entry?.campaign?.products.length -
                                                        +2 }}</div>
                                                </div>
                                                <div v-else class="image">
                                                    <img v-for="product in entry?.campaign?.products"
                                                        :key="product.id" :src="product.main_image ?? image_default" />
                                                </div>
                                            </td>
                                            <td class="nowrap">{{ $filters.formatRangerRateContributorNew(entry.max_commission_rate || entry?.campaign?.max_rate_target_campaign_product?.commission_rate_partner,
                                                entry.min_commission_rate || entry?.campaign?.min_rate_target_campaign_product?.commission_rate_partner) }}</td>
                                            <td class="color_link nowrap cursor-pointer text-center">
                                                <span class="icon_action" :titleCustom="$t('seeCampaign')" @click="handleDetail(entry)">
                                                    <img :src="icon_view_performance"/>
                                                </span>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                            <div id="noData" v-if="entries?.length == 0">
                                <span>{{ $t('noData') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Paginate :value="paginate" :record="record" />
        </div>
        <Loading :loading="isLoading" />
    </div>
</template>

<script>
import { numberFormat, getClassForTargetCampaignStatus } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import Paginate from '@/components/Paginate.vue';
import InputText from '@/components/Form/InputText.vue';
import InputSelect from '@/components/Form/InputSelect.vue';
import Daterangepicker from '@/components/Daterangepicker.vue';
import image_default from '../../../public/images/default-image.jpg';
import { mapGetters } from 'vuex';
import i18n from '@/locales/i18nHelper'
import icon_view_performance from '../../../public/images/icon_view_performance.png'

import store from "@/store";

export default {
    name: 'TargetCampaignContributor',
    setup() {
        return { numberFormat, getClassForTargetCampaignStatus };
    },
    components: {
        Loading,
        InputText,
        Paginate,
        InputSelect,
        Daterangepicker
    },
    created() {
        this.getCampaignsTarget();
    },
    data() {
        return {
            isLoading: false,
            image_default: image_default,
            record: this.$constant.PAGE.record,
            paginate: {
                totalRecord: 0,
                currentPage: 1,
                lastPage: 0
            },

            pID: null,
            entries: [],
            campaignCodeLocal: null,
            campaignCode: null,
            statusLocal: null,
            status: null,
            fromDateToDateLocal: null,
            fromDateToDate: null,
            campaign_id: null,
            campaignName: null,
            endTimePlan: null,
            icon_view_performance: icon_view_performance
        }
    },
    methods: {
        async getCampaignsTarget() {
            const res = await store.dispatch('campaignStore/getCampaignsTargetContributor', {
                currentPage: this.paginate.currentPage,
                record: this.record,
                status: this.status,
                campaignCode: this.campaignCode,
                fromDateToDate: this.fromDateToDate,
            });
            if (res && res.data) {
                this.entries = res.data;
                this.paginate.currentPage = res.current_page;
                this.paginate.lastPage = res.last_page;
                this.paginate.totalRecord = res.total;
            }
        },
        handleDetail(entry) {
            const campaignId = entry.campaign_id || entry?.campaign?.id;
            this.$router.push({ name: this.$constant.ROUTER.detailCollaboratorTargetCampaignName, params: { id: campaignId } });
        },
        removeDuplicateContributors(contributors) {
            const uniqueContributors = contributors.reduce((acc, contributor) => {
                const existingContributor = acc.find(item => item.user_id === contributor.user_id);
                if (!existingContributor) {
                    acc.push(contributor);
                }
                return acc;
            }, []);
            return uniqueContributors;
        },
        searchData() {
            this.campaignCode = this.campaignCodeLocal;
            this.status = this.statusLocal;
            this.fromDateToDate = this.fromDateToDateLocal;
            this.getCampaignsTarget();
        },
        clearSearch() {
            this.campaignCode = null;
            this.campaignCodeLocal = null;
            this.status = null;
            this.statusLocal = null;
            this.paginate.currentPage = 1;
            this.fromDateToDate = null;
            this.fromDateToDateLocal = null;
            this.getCampaignsTarget();
        },
    },
    watch: {
        'paginate.currentPage': function() {
            this.getCampaignsTarget();
        },
    }
};
</script>

<style lang="scss" scoped>
#target-campaign-page {
    .table-responsive {
        .customtable {
            .info-products {
                .image img {
                    // height: 60px !important;
                    width: 50px;
                    transform: scale(1);
                    margin: 1px;
                }

                p {
                    margin-bottom: 0px;
                }
            }

            .count-product {
                width: 40px;
                height: 40px;
                border: 1px solid #dee2e6;
                border-radius: 5px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #dee2e6;
            }
        }
    }
}
</style>