<template>
    <div id="open-campaign-page">
        <div class='container-fluid p-3'>
            <div class='row mb-3'>
                <div class='form-group row'>
                    <div class="col-xl-8 col-md-8 col-sm-12">
                        <div class="row">
                            <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                                <InputText :placeholder="$t('enterSkuId')" v-model="skuIdLocal" />
                            </div>
                            <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                                <InputText :placeholder="$t('enterProductName')" v-model="productNameLocal" />
                            </div>

                            <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                                <InputSelect :placeholder="$t('selectCategory')" v-model="categoryIdLocal"
                                    :options="categories" />
                            </div>
                            <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                                <InputSelect :placeholder="$t('selectBrand')" v-model="brandIdLocal" :options="brands" />
                            </div>
                        </div>
                    </div>
                    <div class='col-xl-4 col-md-4 col-sm-12'>
                        <button @click="onSearchData()" type='submit' class='btn btn-primary mx-1'>
                            <span class="mx-1">
                                {{ $t('search') }}
                            </span>
                        </button>
                        <button @click="clearSearch()" type='submit' class='btn btn-secondary mx-1'>
                            <span class="mx-1">
                                {{ $t('clearFilter') }}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div class='col-lg-12'>
                    <div class="d-flex justify-content-between align-items-center mb-2">
                        <h5 class='card-title'>
                            {{ $t('selected') }} : {{ numberFormat(this.product_codes_multiple?.length) + '/' +
                            numberFormat(this.paginate.totalRecord) }}
                        </h5>
                        <div>
                            <button v-if="this.product_codes_multiple?.length > 1"
                                @click="updateRateQuick()" type='submit' class='btn btn-secondary mx-1 btn-custom'>
                                <span class="mx-1">
                                    {{ $t('updateRateQuick') }}
                                </span>
                            </button>
                            <button type='submit' class='btn btn-outline-secondary' @click="exportData()">
                                <span class="mx-2">
                                    {{ $t('exportData') }}
                                </span>
                            </button>
                        </div>
                    </div>
                    <div class="card">
                        <div class="table-responsive">
                            <table class="table">
                                <thead class="thead-light border">
                                    <tr>
                                        <th>
                                            <input @click=onCheckAll(!statusCheckAll) :checked="statusCheckAll"
                                                type="checkbox" />
                                        </th>
                                        <th scope="col">{{ $t('product') }}</th>
                                        <th scope="col">{{ $t('NPPCommissionRate') }}</th>
                                        <th scope="col">{{ $t('collaboratorCommissionRate') }}</th>
                                        <th scope="col">{{ $t('promotionPeriod') }}</th>
                                        <th scope="col">{{ $t('action') }}</th>
                                        <th scope="col">{{ $t('Link') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="customtable">
                                    <template v-for="product in products"  :key="product.product_code">
                                        <tr>
                                            <th>
                                                <input v-model="productsActive" :value="product"
                                                @change="toggleProductCode($event, product.product_code)"
                                                    :disabled="productsOrigin.find((origin) => origin.id == product.id)"
                                                    v-bind:id="product.id" type="checkbox" />
                                                    <!-- <input type="checkbox" v-model="product_codes_multiple"
                                                    :disabled="productsOrigin.find((origin) => origin.id == productActive.id)"
                                                    :value="product.product_code"  /> -->
                                            </th>
                                            <td>
                                                <div class="info-products d-flex"
                                                    @click="openProductPage(product?.product.slug)">
                                                    <div class="image">
                                                        <img v-if="product.product.main_image"
                                                            :src="product.product.main_image" />
                                                        <img v-else :src="image_default" />
                                                    </div>
                                                    <div class="product mx-1">
                                                        <p class="text-primary">{{ product.product.name }}</p>
                                                        <p>
                                                            {{ $t('skuId') }}: {{
                                                            product?.product?.product_variant_max_price?.sku
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-center">
                                                <span>
                                                    {{
                                                    numberFormat(product.commission_rate_partner) || 0
                                                    }}%
                                                </span>
                                            </td>
                                            <td class="">
                                                <span class="mx-2 text-primary" role='button'
                                                    v-if="!product?.open_campaign_contributor"
                                                    @click="showModalSetCommission(product, 'create')">
                                                    {{ $t('setCommission') }}
                                                </span>
                                                <span v-else>
                                                    {{
                                                    product?.open_campaign_contributor?.commission_rate_contributor
                                                    }}%
                                                    <i class="far fa-edit"
                                                        @click="showModalSetCommission(product, 'update')"></i>
                                                </span>
                                            </td>

                                            <td>
                                                <span>{{
                                                    $filters.formatStartTimeAndEndTime(product.start_time,
                                                    product.end_time)
                                                    }}
                                                </span>
                                            </td>
                                            <td>
                                                <span class="mx-2 text-primary" role="button" @click="showPerformanceDetail(product.product_code, product.product.name)">
                                                    <span class="icon_action" :titleCustom="$t('seePerformance')"><img :src="icon_view_performance"/></span>
                                                </span>
                                            </td>
                                            <td>
                                                <div class="btn-group nav-item dropdown">
                                                    <button type="button" class="btn btn-primary dropdown-toggle"
                                                        data-bs-toggle="dropdown" aria-expanded="false">
                                                        Link
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <template
                                                            v-for="(channel, indexChannel) in this.listMarketingChannels"
                                                            :key="indexChannel">
                                                            <li
                                                                @click="renderAndCopyLink(product, channel, 'open')">
                                                                <a class="dropdown-item" href="#">{{
                                                                    channel.name }}</a>
                                                            </li>
                                                        </template>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                            <div id="noData" v-if="products?.length == 0">
                                <span>{{ $t('noData') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Paginate v-if="products?.length > 0" :value="paginate" :record="this.record" @pageChange="pageChange" />
        </div>
    </div>
    <ModalSetCommission ref="ModalSetCommission" />
    <ModalSetMultipleContributor ref="ModalSetMultipleContributor" />
</template>

<script>
import { numberFormat, formatDateCTV,  linkWebPharma, generateAffiliateLink } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import Paginate from '@/components/Paginate.vue';
import InputText from '@/components/Form/InputText.vue';
import InputSelect from '@/components/Form/InputSelect.vue';
import ModalSetCommission from '@/components/Modal/OpenCampaignPartner/SetCommission.vue';
import ModalSetMultipleContributor from '@/components/Modal/OpenCampaign/ModalSetMultipleContributor.vue'
import toastr from 'toastr';
import { mapGetters } from 'vuex';
import icon_view_performance from '../../../public/images/icon_view_performance.png'

export default {
    name: 'OpenCampaignPartner',
    setup() {
        return { numberFormat, formatDateCTV };
    },
    components: {
        Loading,
        InputText,
        Paginate,
        InputSelect,
        ModalSetCommission,
        ModalSetMultipleContributor
    },
    data() {
        return {
            isLoading: false,
            record: this.$constant.PAGE.record,
            filters: {
                sku: null,
                name: null,

                page: 1,
            },
            paginate: {
                totalRecord: 1,
                currentPage: 1,
                lastPage: 1
            },
            products: [],
            searchData: {
                skuId: null,
                productName: null,
                categoryId: null,
                brandId: null,
            },
            categories: [],
            brands: [],
            skuIdLocal: null,
            productNameLocal: null,
            categoryIdLocal: null,
            brandIdLocal: null,
            product_codes_multiple: [],
            productsActive: [],
            productsOrigin: [],
            listMarketingChannels: [],
            icon_view_performance: icon_view_performance
        }
    },
    mounted() {
        // this.loadData();
        this.getListCategory();
        this.getListBrands();
        this.getListProductActiveOpenCampaign();
        this.getChannels();
    },
    computed: {
        ...mapGetters({
            load_data_index: 'productLinks/load_data_index',
            // listMarketingChannels: 'marketingChannels/listMarketingChannels',
            userInfo: 'auth/userInfo'
        }),
    },
    watch: {
        'load_data_index': function () {
            this.product_codes_multiple = [];
            this.getListProductActiveOpenCampaign();
            this.$store.dispatch('productLinks/loadDataIndex', false);
        },
        'paginate.currentPage': function () {
            this.getListProductActiveOpenCampaign();
        },
        productsActive: {
            handler: function(newv, ol) {  
                this.checkAddProduct();            
            },
            deep: true
        },
    },
    methods: {
        showPerformanceDetail(productCode, productName) {  
            const originalEncodedProductCode = productCode;
            const endCodeProductName = encodeURIComponent(productName);
            const targetPath = `${this.$constant.ROUTER.performanceDetailProductOpenPartner}?product_code=${originalEncodedProductCode}&product_name=${endCodeProductName}`;
            window.location.href = targetPath;
        },
        updateRateQuick() {
            this.$refs.ModalSetMultipleContributor.show([...this.product_codes_multiple], null, null);
        },
        // async loadData() {
        //     const res = await this.$store.dispatch('marketingChannels/fetchMarketingChannels');
        // },
        toggleProductCode(event, productCode) {
            if (event.target.checked) {
                // Add product code if checked
                if (!this.product_codes_multiple.includes(productCode)) {
                    this.product_codes_multiple.push(productCode);
                }
            } else {
                // Remove product code if unchecked
                this.product_codes_multiple = this.product_codes_multiple.filter(code => code !== productCode);
            }
        },
        async getListProductActiveOpenCampaign() {
            const res = await this.$store.dispatch('productStore/getListProductActiveOpenCampaign', {
                currentPage: this.paginate.currentPage,
                record: this.record,
                skuId: this.searchData.skuId,
                productName: this.searchData.productName,
                categoryId: this.searchData.categoryId,
                brandId: this.searchData.brandId,
            });

            if (!res) {
                return;
            }
            this.products = res.data;
            this.totalProductActive = res.total;
            this.paginate.currentPage = res.current_page;
            this.paginate.lastPage = res.last_page;
            this.paginate.totalRecord = res.total;

            this.checkAddProduct();
        },
        clearSearch() {
            this.skuIdLocal = null;
            this.productNameLocal = null;
            this.categoryIdLocal = null;
            this.brandIdLocal = null;
            this.typeAcc = [this.$constant.PAGE.typeSearchPartner, this.$constant.PAGE.typeSearchCollaborator];
            this.paginate.currentPage = 1;
            this.searchData.skuId = null;
            this.searchData.productName = null;
            this.searchData.categoryId = null;
            this.searchData.brandId = null;
            this.product_codes_multiple = [];
            this.statusCheckAll = false;
            this.productsActive = [];
            this.getListProductActiveOpenCampaign();
        },
        onSearchData() {            
            this.searchData.skuId = this.skuIdLocal;
            this.searchData.productName = this.productNameLocal;
            this.searchData.categoryId = this.categoryIdLocal;
            this.searchData.brandId = this.brandIdLocal;   
            this.typeAcc = [this.$constant.PAGE.typeSearchPartner, this.$constant.PAGE.typeSearchCollaborator];   
            this.paginate.currentPage = 1;                     
            this.getListProductActiveOpenCampaign();
        },

        showModalSetCommission(data, action) {
            this.$refs.ModalSetCommission.show(data, action);
        },
        checkAddProduct() {    
            if (!this.products.length) {
                this.statusCheckAll = false;
                return;
            }    
            for (let index = 0; index < this.products.length; index++) {
                const product = this.products[index];                
                let indexPar = this.productsActive.findIndex(productActive => 
                    productActive.id == product.id                    
                );                
                if (indexPar === -1) {
                    this.statusCheckAll = false;
                    return;
                }
            }        
            this.statusCheckAll = true;
        },

        renderAndCopyLink(productActive, channel, type) {
            const link = generateAffiliateLink(productActive?.product?.slug, this.userInfo?.id, channel.id, 1);
            navigator.clipboard.writeText(link).then(() => {
                // this.save(url, channel, type);
                toastr.success('Đã copy link giới thiệu của <br>' + '<b>' + productActive?.product?.name + '</b>');
            }).catch(err => {
                console.error('Could not copy link: ', err);
                toastr.error('Không thể sao chép liên kết');
            });
        },
        async save(url, channel, type) {
            const params = {
                url: url,
                marketing_channel_id: channel.id, 
                type: type
            };
            const res = await this.$store.dispatch('productLinks/createProductLinkChannel', params);
        },
        async getListCategory() {
            const res = await this.$store.dispatch('productStore/getListCategoryFilter');
            this.categories = res;
        },
        async getListBrands() {
            const res = await this.$store.dispatch('productStore/getListBrandsFilter');
            this.brands = res;
        },
        async exportData(){
            const res = await this.$store.dispatch('campaignStore/exportOpenCampaignContributor', {
                skuId: this.filters.sku,
                productName: this.filters.name,
                categoryId: this.filters.categoryIdLocal,
                brandId: this.filters.brandIdLocal,
            });
            if(res){
                this.$store.dispatch('setShowModalExport', true)
            }
        },
        openProductPage(slug) {
            linkWebPharma(slug);
        },

        onCheckAll(statusCheckAll) {
            this.statusCheckAll = statusCheckAll;

            if (statusCheckAll) {
                // Khi chọn tất cả
                this.checkAllPage();

                let productsCheck = [];

                // Duyệt qua tất cả sản phẩm trong trang hiện tại và lưu product_code
                for (let index = 0; index < this.products.length; index++) {
                    const product = this.products[index];
                    productsCheck.push(product.product_code);
                }

                // Nếu không bị disabled, thêm product_code vào product_codes_multiple
                if (!this.disabledAll) {
                    this.product_codes_multiple = [
                        ...new Set([...this.product_codes_multiple, ...productsCheck])
                    ]; // Đảm bảo không có trùng lặp
                }

                this.statusCheckAll = true;
            } else {
                this.checkPartPage();
                let currentPageProductCodes = this.products.map(product => product.product_code);
                this.product_codes_multiple = this.product_codes_multiple.filter(code =>
                    !currentPageProductCodes.includes(code)
                );

                this.statusCheckAll = false;
            }
        },
        checkAllPage() {
            let productsCheck = [];
            if (!this.productsActive.length) {
                this.productsActive = [...this.products];
            } else {
                let productsActiveCurrent = [...this.productsActive]; 

                for (let index = 0; index < this.products.length; index++) {
                    const product = this.products[index]; 

                    let indexProduct = productsActiveCurrent.findIndex(partnerActive => partnerActive.id == product.id);

                    if (indexProduct === -1) {
                        productsActiveCurrent.push(this.products[index]);
                    }

                    productsCheck.push(product.product_code);
                }
                this.productsActive = [...productsActiveCurrent];
                this.product_codes_multiple = [
                    ...new Set([...this.product_codes_multiple, ...productsCheck])
                ];
            }
        },
        checkPartPage() {
            let productsActiveCurrent = [...this.productsActive];

            // Khởi tạo currentPageProductCodes từ các sản phẩm hiện tại
            let currentPageProductCodes = this.products.map(product => product.product_code);

            for (let index = 0; index < this.products.length; index++) {
                const partner = this.products[index];

                let indexPrd = productsActiveCurrent.findIndex(partnerActive =>
                    partnerActive.id == partner.id
                );
                let indexPrdOrigin = this.productsOrigin.findIndex(origin =>
                    origin.id == partner.id
                );

                // Nếu sản phẩm đang active nhưng không có trong productsOrigin, xóa nó
                if (indexPrd !== -1 && indexPrdOrigin === -1) {
                    productsActiveCurrent.splice(indexPrd, 1);
                }
            }

            this.productsActive = [...productsActiveCurrent];

            // Lọc các mã sản phẩm hiện tại
            this.product_codes_multiple = this.product_codes_multiple.filter(code =>
                !currentPageProductCodes.includes(code)
            );
        },

  
        async addQuickProduct() {
            const res = await store.dispatch('campaignStore/addProductOpenCampaign', {
                commission_rate_partner: this.commission_rate_quick,
                commission_rate_contributor: this.commission_rate_quick,
                product_codes: this.product_codes_multiple,
                start_time: moment().format('YYYY-MM-DD HH:mm:ss'),
                end_time: null,
            });
            if (!res || res.status === 500) {
                toastr.error('error server');
            } else if (res.status === 422) {
                let errors = res.response;
                Object.keys(errors).forEach(function(key) {
                    toastr.error(errors[key][0]);
                })
            } else if (res.status === 200) {
                toastr.success(res.result.message);
                this.clearDataAddQuick();
            }
        },
        clearDataAddQuick() {
            this.commission_rate_quick = '';
            this.product_codes_multiple = [];
            this.paginate.currentPage = 1;
            this.getDataCurrentTab(); 
        },
        async getChannels() {
            const res = await this.$store.dispatch('marketingChannels/getListMarketingChannels');            
            if (res) {
                this.listMarketingChannels = res;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/openCampaign.scss";
</style>