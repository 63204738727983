<template>
    <div id="daterangepicker">
        <div class="position-relative">
            <label v-if="label" v-text="label"></label>
            <input ref="input" :placeholder="placeholder" :class="[(errors && errors.length ? 'error-input' : '')]"
                autocomplete="off" class="form-control input-date-picker">
            <i class="far fa-calendar fa-md icon-calendar" aria-hidden="true" @click="showCalendar()">
            </i>
            <ul class="error-label" v-if="errors && errors.length">
                <li v-for="error in errors" :key="error" v-text="error"></li>
            </ul>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import {DATE_LOCALES} from '../common/constant';

function parseModelValue(modelValue, dDate) {
    const tmp = modelValue ? modelValue.split('_') : [];
    const startDate = tmp[0] ? moment(tmp[0]) : moment(dDate);
    const endDate = tmp[1] ? moment(tmp[1]) : moment();
    return [
        startDate, endDate
    ];
}
export default {
    name: "Daterangepicker",
    props: ['modelValue', 'placeholder', 'label', 'errors', 'defaultDate'],
    watch: {
        modelValue: function () {            
            if (!this.modelValue) {
                this.$refs.input.value = '';
            } else {
                const dDate = moment().format('YYYY-MM-DD');
                const [startDate, endDate] = parseModelValue(this.modelValue, dDate);
                // if (startDate.format('YYYY-MM-DD') === dDate && endDate.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
                //     this.$refs.input.value = this.$t('all');
                // }
                // else {
                //     this.$refs.input.value = startDate.format('DD/MM/YYYY') + ' -- ' + endDate.format('DD/MM/YYYY');
                // }
                this.$refs.input.value = startDate.format('DD/MM/YYYY') + ' -- ' + endDate.format('DD/MM/YYYY');                
            }
            this.getData();
        },
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            const format = 'DD/MM/YYYY';
            const input = this.$refs.input;
            const dDate = moment().format('YYYY-MM-DD');
            const ranges = {
                'Hôm nay': [moment(), moment()],
                'Hôm qua': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                '3 ngày trước': [moment().subtract(2, 'days'), moment()],
                '7 ngày trước': [moment().subtract(6, 'days'), moment()],
                '30 ngày trước': [moment().subtract(29, 'days'), moment()],
                'Tháng này': [moment().startOf('month'), moment().endOf('month')],
                'Tất cả': [moment(dDate), moment()]
            };

            const [startDate, endDate] = parseModelValue(this.modelValue, dDate);
            const $input = window.jQuery(input);
            $input.daterangepicker({
                autoUpdateInput: false,
                startDate: startDate,
                endDate: endDate,
                locale: DATE_LOCALES,
                autoApply: true,
                // ranges: ranges
            });
            const changeHandler = (ev, picker) => {
                const start = $input.data('daterangepicker').startDate;
                const end = $input.data('daterangepicker').endDate;
                // if (start.format('YYYY-MM-DD') === dDate && end.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
                //     input.value = this.$t('all');
                // }
                // else {
                //     input.value = start.format(format) + ' -- ' + end.format(format);
                // }
                input.value = start.format(format) + ' -- ' + end.format(format);

                const modelValue = start.format('YYYY-MM-DD') + '_' + end.format('YYYY-MM-DD');
                this.$emit('update:modelValue', modelValue);
                this.$nextTick(() => {
                    this.$emit('input', modelValue);
                    this.$emit('changed', modelValue);
                });
            };

            $input.on('apply.daterangepicker', changeHandler);
            $input.on('change', changeHandler);

            if (this.modelValue) {
                input.value = startDate.format(format) + ' -- ' + endDate.format(format);
            }
        },
        showCalendar() {            
            window.jQuery(this.$refs.input).focus()
        }
    }
}
</script>

<style scoped>
@import "../assets/scss/daterangepicker.scss";
</style>
