<template>
    <div id="login-page">
        <div class="row justify-content-center" style="background-color: #f5f5f5;">
            <div class="col-lg-12 login-page__form">
                <div class='row content my-3 p-3'>
                    <div class="form-title">
                        <h3 class="">{{ $t('completeInformation') }}</h3>
                    </div>
                    <div class="row mb-3">
                        <div class="col-xl-6 col-md-12 col-sm-12">
                            <div class="form-group row">
                                <label class="col-sm-3 control-label col-form-label">
                                    {{ $t('collaboratorName') }}:<span class="text-danger">*</span>
                                </label>
                                <div class="col-sm-9">
                                    <InputText :placeholder="$t('enterCollaboratorName')" v-model="entry.name"
                                        :errors="errors?.name ?? []" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-12 col-sm-12">
                            <div class="form-group row">
                                <label class="col-sm-3 control-label col-form-label">
                                    {{ $t('birthday') }}:<span class="text-danger">*</span>
                                </label>
                                <div class="col-sm-9">
                                    <InputDate :placeholder="$t('enterBirthdayContributor')" v-model="entry.birthday" :max="today" :errors="errors?.birthday ?? []"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-xl-6 col-md-12 col-sm-12">
                            <div class="form-group row">
                                <label class="col-sm-3 control-label col-form-label">
                                    {{ $t('email') }}:<span class="text-danger">*</span>
                                </label>
                                <div class="col-sm-9">
                                    <InputText :placeholder="$t('enterCollaboratorEmail')" v-model="entry.email"
                                        :errors="errors?.email ?? []" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-12 col-sm-12">
                            <div class="form-group row">
                                <label class="col-sm-3 control-label col-form-label">
                                    {{ $t('phone') }}:<span class="text-danger">*</span>
                                </label>
                                <div class="col-sm-9">
                                    <InputText :placeholder="$t('enterCollaboratorPhone')" v-model="entry.phone"
                                        :errors="errors?.phone ?? []" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-xl-6 col-md-12 col-sm-12">
                            <div class="form-group row">
                                <label class="col-sm-3 control-label col-form-label">
                                    {{ $t('taxCode') }}:<span class="text-danger">*</span>
                                </label>
                                <div class="col-sm-9">
                                    <InputText :placeholder="$t('enterCollaboratorTaxCode')" v-model="entry.tax_code"
                                        :errors="errors?.tax_code ?? []" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-12 col-sm-12">
                            <div class="form-group row">
                                <label class="col-sm-3 control-label col-form-label">
                                    {{ $t('address') }}:
                                </label>
                                <div class="col-sm-9">
                                    <InputText :placeholder="$t('enterCollaboratorAddress')" v-model="entry.address"
                                        :errors="errors?.address ?? []" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-xl-6 col-md-12 col-sm-12">
                            <div class="form-group row">
                                <label class="col-sm-3 control-label col-form-label">
                                    {{ $t('identification') }}:<span class="text-danger">*</span>
                                </label>
                                <div class="col-sm-9">
                                    <InputText :placeholder="$t('enterIdentificationContributor')"
                                        v-model="entry.identification_code"
                                        :errors="errors?.identification_code ?? []" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-xl-6 col-md-12 col-sm-12">
                            <div class="form-group row">
                                <label class="col-sm-3 control-label col-form-label">
                                    {{ $t('identificationFrontImage') }}:<span class="text-danger">*</span>
                                </label>
                                <div class="col-sm-9">
                                    <InputFile :image="true" :modelValue="entry?.identification_card_front"
                                        @update:modalValue="changeIdentificationCardFront" :errors="errors?.identification_card_front ?? []"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-12 col-sm-12">
                            <div class="form-group row">
                                <label class="col-sm-3 control-label col-form-label">
                                    {{ $t('identificationBackImage') }}:<span class="text-danger">*</span>
                                </label>
                                <div class="col-sm-9">
                                    <InputFile :image="true" :modelValue="entry?.identification_card_back"
                                        @update:modalValue="changeIdentificationCardBack" :errors="errors?.identification_card_back ?? []"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-xl-12 col-md-12 col-sm-12">
                            <label class="title">
                                {{ $t('paymentInfor') }}
                            </label>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-xl-6 col-md-12 col-sm-12">
                            <div class="form-group row">
                                <label class="col-sm-3 control-label col-form-label">
                                    {{ $t('bank') }}:<span class="text-danger">*</span>
                                </label>
                                <div class="col-sm-9">
                                    <InputText :placeholder="$t('enterBankInfor')" v-model="entry.bank_name"
                                        :errors="errors?.bank_name ?? []" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-12 col-sm-12">
                            <div class="form-group row">
                                <label class="col-sm-3 control-label col-form-label">
                                    {{ $t('branch') }}:<span class="text-danger">*</span>
                                </label>
                                <div class="col-sm-9">
                                    <InputText :placeholder="$t('enterBranchName')" v-model="entry.bank_branch"
                                        :errors="errors?.bank_branch ?? []" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-xl-6 col-md-12 col-sm-12">
                            <div class="form-group row">
                                <label class="col-sm-3 control-label col-form-label">
                                    {{ $t('accountNumber') }}:<span class="text-danger">*</span>
                                </label>
                                <div class="col-sm-9">
                                    <InputText :placeholder="$t('enterAccountNumber')"
                                        v-model="entry.bank_account_number"
                                        :errors="errors?.bank_account_number ?? []" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-md-12 col-sm-12">
                            <div class="form-group row">
                                <label class="col-sm-3 control-label col-form-label">
                                    {{ $t('accountOwner') }}:<span class="text-danger">*</span>
                                </label>
                                <div class="col-sm-9">
                                    <InputText :placeholder="$t('enterAccountOwner')" v-model="entry.bank_owner_name"
                                        :errors="errors?.bank_owner_name ?? []" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-xl-12 col-md-12 col-sm-12 d-flex justify-content-center">
                            <button class="btn btn-primary mx-3" @click="save()">
                                <i class="fa fa-save mr-2"></i>
                                <span class="mx-2">
                                    {{ $t('complete') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { clone } from '@/utils/utils';
import { mapGetters } from 'vuex';
import toastr from "toastr";
import InputText from '@/components/Form/InputText.vue';
import InputFile from '@/components/Form/InputFile.vue';
import moment from 'moment';
import InputDate from '@/components/Form/InputDate.vue'
export default {
    name: 'CompleteInformation',
    components: { InputText, InputFile, InputDate },
    data() {
        return {
            entry: {},
            errors: {},
            today: moment().subtract(1, 'days').format('YYYY-MM-DD')
        }
    },
    computed: {
        ...mapGetters({
            userInfo: 'auth/userInfo'
        })
    },
    mounted() {
        this.loadUserInfo();
    },
    methods: {
        async loadUserInfo(){
            await this.$store.dispatch('auth/fetchUserInfo');
            const data = this.userInfo && Object.keys(this.userInfo).length != 0 ? this.userInfo : {
                name: '',
                email: '',
                phone: '',
                tax_code: '',
                address: '',
                bank_name: '',
                bank_branch: '',
                bank_account_number: '',
                bank_owner_name: '',
                identification_card_front: null,
                identification_card_back: null,
                identification_code: '',
                birthday: ''
            };
            if(data.birthday){
                data.birthday = moment(data.birthday, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
            }
            this.entry = clone(data);
        },
        async save(){
            let data = JSON.parse(JSON.stringify(this.entry));
            data.birthday = data.birthday ? moment(data.birthday, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss') : null;
            data.complete_information = true;
            const res = await this.$store.dispatch('collaborators/updateCollaborator', data);
            if(res?.error){
                if(res?.messages){
                    this.errors = res?.messages ?? {};
                }else{
                    toastr.error(res.data);
                }
                return;
            }else{
                this.$store.dispatch('auth/setUserActive', true);
                location.replace(this.$constant.ROUTER.dashboard);
            }
        },
        changeIdentificationCardFront(value){
            this.entry.identification_card_front = value;
        },
        changeIdentificationCardBack(value){
            this.entry.identification_card_back = value;
        }
    }
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/login.scss";
</style>